const pageTypes = {
  SET_LOADING: "SET_LOADING",
  RESET_LOADING: "RESET_LOADING",

  FETCH_WHY_TRASH_WARRIOR: "FETCH_WHY_TRASH_WARRIOR",
  FETCH_WHY_TRASH_WARRIOR_ERROR: "FETCH_WHY_TRASH_WARRIOR_ERROR",

  FETCH_FAQ: "FETCH_FAQ",
  FETCH_FAQ_ERROR: "FETCH_FAQ_ERROR",

  FETCH_HELP: "FETCH_HELP",
  FETCH_HELP_ERROR: "FETCH_HELP_ERROR",

  FETCH_HOW_IT_WORKS: "FETCH_HOW_IT_WORKS",
  FETCH_HOW_IT_WORKS_ERROR: "FETCH_HOW_IT_WORKS_ERROR",

  FETCH_TERMS: "FETCH_TERMS",
  FETCH_TERMS_ERROR: "FETCH_TERMS_ERROR",

  FETCH_CORPORATE_CLIENT: "FETCH_CORPORATE_CLIENT",
  FETCH_CORPORATE_CLIENT_ERROR: "FETCH_CORPORATE_CLIENT_ERROR",

  FETCH_TESTIMONIALS: "FETCH_TESTIMONIALS",
  FETCH_TESTIMONIALS_ERROR: "FETCH_TESTIMONIALS_ERROR",

  FETCH_CASE_STUDIES: "FETCH_CASE_STUDIES",
  FETCH_CASE_STUDIES_ERROR: "FETCH_CASE_STUDIES_ERROR",

  FETCH_CORPORATE_SERVICE_AGREEMENT: "FETCH_CORPORATE_SERVICE_AGREEMENT",
  FETCH_CORPORATE_SERVICE_AGREEMENT_ERROR: "FETCH_CORPORATE_SERVICE_AGREEMENT_ERROR",

  FETCH_PREFERRED_PARTNER_AGREEMENT: "FETCH_PREFERRED_PARTNER_AGREEMENT",
  FETCH_PREFERRED_PARTNER_AGREEMENT_ERROR: "FETCH_PREFERRED_PARTNER_AGREEMENT_ERROR",

  FETCH_COVID19: "FETCH_COVID19",
  FETCH_COVID19_ERROR: "FETCH_COVID19_ERROR",

  FETCH_PROVIDER_POLICY: "FETCH_PROVIDER_POLICY",
  FETCH_PROVIDER_POLICY_ERROR: "FETCH_PROVIDER_POLICY_ERROR",

  FETCH_COMPANY_NEWS: "FETCH_COMPANY_NEWS",
  FETCH_COMPANY_NEWS_ERROR: "FETCH_COMPANY_NEWS_ERROR",

  FETCH_PRICING: "FETCH_PRICING",
  FETCH_PRICING_ERROR: "FETCH_PRICING_ERROR",

  FETCH_CASE_STUDY: "FETCH_CASE_STUDY",
  FETCH_CASE_STUDY_ERROR: "FETCH_CASE_STUDY_ERROR",

  FETCH_CLEANING_REPORT: "FETCH_CLEANING_REPORT",
  FETCH_CLEANING_REPORT_ERROR: "FETCH_CLEANING_REPORT_ERROR",

  FETCH_PROVIDER_AGREEMENT_V2: "FETCH_PROVIDER_AGREEMENT_V2",
  FETCH_PROVIDER_AGREEMENT_V2_ERROR: "FETCH_PROVIDER_AGREEMENT_V2_ERROR",

  FETCH_PROVIDER_RESOURCE: "FETCH_PROVIDER_RESOURCE",
  FETCH_PROVIDER_RESOURCE_ERROR: "FETCH_PROVIDER_RESOURCE_ERROR",

  FETCH_TIERED_SERVICE_PRICING: "FETCH_TIERED_SERVICE_PRICING",
  FETCH_TIERED_SERVICE_PRICING_ERROR: "FETCH_TIERED_SERVICE_PRICING_ERROR",

  FETCH_AMAZON_CASE_STUDY: "FETCH_AMAZON_CASE_STUDY",
  FETCH_AMAZON_CASE_STUDY_ERROR: "FETCH_AMAZON_CASE_STUDY_ERROR",

  FETCH_SARES_REGIS: "FETCH_SARES_REGIS",
  FETCH_SARES_REGIS_ERROR: "FETCH_SARES_REGIS_ERROR",
};

export default pageTypes;
