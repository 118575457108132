const subscriptionTypes = {
  SET_SUBSCRIPTION_LOADING: "SET_SUBSCRIPTION_LOADING",
  RESET_SUBSCRIPTION_LOADING: "RESET_SUBSCRIPTION_LOADING",

  FETCH_BOOKINGS_SUBSCRIPTIONS: "FETCH_BOOKINGS_SUBSCRIPTIONS",
  RESET_BOOKINGS_SUBSCRIPTIONS: "RESET_BOOKINGS_SUBSCRIPTIONS",

  SET_BUNDLE_LOADING: "SET_BUNDLE_LOADING",
  RESET_BUNDLE_LOADING: "RESET_BUNDLE_LOADING",

  FETCH_BOOKINGS_BUNDLE: "FETCH_BOOKINGS_BUNDLE",
  RESET_BOOKINGS_BUNDLE: "RESET_BOOKINGS_BUNDLE",
};

export default subscriptionTypes;
