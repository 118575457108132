import { useEffect, useRef } from "react";
import { firestore } from "@config/firebaseConfig";

const useFirestoreListener = (collection, { onAdd, onUpdate, onDelete }) => {
  const docAdded = useRef(false);

  useEffect(() => {
    // listen to doc changes
    const handleSubscriptionChanges = (doc) => {
      const data = doc.data();

      if (docAdded.current && data) {
        const { record, status } = data;
        switch (status) {
          case "new":
            return onAdd(record);
          case "updated":
            return onUpdate(record);
          case "deleted":
            return onDelete(record);
          default:
            break;
        }
      }

      docAdded.current = true;
    };

    const query = firestore.collection("subscriptions").doc(collection);

    // Create the DB listener
    const unsubscribe = query.onSnapshot(handleSubscriptionChanges, (err) => {
      console.log("Error in useFirestoreListener:", err); // eslint-disable-line
    });

    return () => {
      unsubscribe();
    };
  }, []);
};

export default useFirestoreListener;
