import { firebase } from "@config/firebaseConfig";
import { isProduction } from "@constants/environments";
import { genCustomUID } from "../shared/utils/generateUID";
import { getCoreKeyword, getFullKeyword } from "../shared/hooks/useDynamicParameters";

export const segmentPage = () => {
  if (isProduction) {
    analytics.page();
  }
};

export const segmentIdentify = (traits, uidOverride = undefined) => {
  if (!isProduction) {
    return;
  }
  const uid = uidOverride ?? firebase.auth().currentUser?.uid;
  const idTraits = traits ?? {};
  if (uid) {
    analytics.identify(uid, { ...idTraits });
  } else {
    analytics.identify({ ...idTraits });
  }
};

/* pass in the Segment identifier (name) and fields */
export const segmentTrack = (name, payload, track = true) => {
  if (!track) return;

  let conversionId = sessionStorage.getItem("tw-conversion-id");
  let sessionTrack = sessionStorage.getItem("tw-session-id");
  if (!sessionTrack) {
    sessionTrack = genCustomUID();
    sessionStorage.setItem("tw-session-id", sessionTrack);
  }

  const gclid = sessionStorage.getItem("gclid-track");
  const identified = sessionStorage.getItem("segment-identified");
  const version = isProduction ? "react-panda" : "react-dev";
  const uid = firebase.auth().currentUser?.uid ?? "unregistered";
  let fullPayload = {
    React_id: uid,
    version: version,
    gclid: gclid ?? "",
    reactSessionId: sessionTrack,
    href: window.location.href,
    conversion_id: conversionId ?? "",
    keywords: getFullKeyword() ?? "",
    coreKeyword: getCoreKeyword() ?? "",
  };
  if (payload) {
    fullPayload = {
      ...fullPayload,
      ...payload,
    };
  }

  if (!isProduction) {
    // eslint-disable-next-line
    console.log(`Segment: ${name}`, fullPayload);
    return;
  }

  if (isProduction) {
    if (identified !== "true" && name === "Service Selected") {
      segmentIdentify({ gclid: gclid });
      sessionStorage.setItem("segment-identified", "true");
    }
  }

  // analytics?.track(name, fullPayload);

  if (isProduction) {
    analytics.track(name, fullPayload);

    // sanitize phone number values in the fullPayload to remove the +1
    const cleanPayload = Object.keys(fullPayload).reduce((acc, key) => {
      if (key?.toLowerCase()?.includes("phone")) {
        acc[key] = fullPayload[key]?.replace("+1", "");
      } else {
        acc[key] = fullPayload[key];
      }
      return acc;
    }, {});

    window.dataLayer.push({
      event: name?.split(" ")?.join("_")?.toLowerCase(),
      ...cleanPayload,
    });
  }
};

let timerID;
// push to segment with delay
export const pushToSegment = (event, data) => {
  clearTimeout(timerID);
  timerID = setTimeout(() => {
    segmentTrack(event, data);
  }, 1000);
};
