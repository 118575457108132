export enum AttributeActions {
  SET_ATTRIBUTES = "SET_ATTRIBUTES",
  ADD_ATTRIBUTE = "ADD_ATTRIBUTE",
  DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE",
  UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE",
  ADD_ATTRIBUTE_GROUP = "ADD_ATTRIBUTE_GROUP",
  DELETE_ATTRIBUTE_GROUP = "DELETE_ATTRIBUTE_GROUP",
  UPDATE_ATTRIBUTE_GROUP = "UPDATE_ATTRIBUTE_GROUP",
  SET_OPTIONS = "SET_OPTIONS",
};
