import invoiceTypes from "@store/constants/invoiceTypes";
import { addItem, updateItem } from "../utils";

const INITIAL_STATE = {
  invoices: [],
  selectedInvoice: null,
  isLoading: false,
};

const invoicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case invoiceTypes.FETCH_INVOICES:
      return {
        ...state,
        invoices: [...action.payload],
      };
    case invoiceTypes.ADD_INVOICE:
      return {
        ...state,
        invoices: addItem(state.invoices, action.payload),
      };
    case invoiceTypes.UPDATE_INVOICE: {
      return {
        ...state,
        invoices: updateItem(state.invoices, action.payload),
      };
    }
    case invoiceTypes.SET_SELECTED_INVOICE:
      return {
        ...state,
        selectedInvoice: action.payload,
      };
    case invoiceTypes.RESET_SELECTED_INVOICE:
      return {
        ...state,
        selectedInvoice: null,
      };
    case invoiceTypes.SET_INVOICES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case invoiceTypes.RESET_INVOICES_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default invoicesReducer;
