import pageConstants from "@store/constants/pageTypes";

const INITIAL_STATE = {
  isLoading: false,
  whyTrashWarrior: null,
  faq: null,
  help: null,
  howItWorks: null,
  terms: null,
  corporateClient: null,
  corporateServiceAgreement: null,
  preferredPartnerAgreement: null,
  testimonials: null,
  caseStudies: null,
  covid19: null,
  providerPolicy: null,
  companyNews: null,
  pricing: null,
  caseStudy: null,
  cleaningReport: null,
  providerAgreementV2: null,
  providerResource: null,
  tieredServicePricing: null,
  amazonCaseStudy: null,
  saresRegis: null,
};

const pageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pageConstants.SET_LOADING:
      return { ...state, isLoading: true };
    case pageConstants.RESET_LOADING:
      return { ...state, isLoading: false };
    case pageConstants.FETCH_WHY_TRASH_WARRIOR:
      return { ...state, whyTrashWarrior: action.payload };
    case pageConstants.FETCH_FAQ:
      return { ...state, faq: action.payload };
    case pageConstants.FETCH_HELP:
      return { ...state, help: action.payload };
    case pageConstants.FETCH_HOW_IT_WORKS:
      return { ...state, howItWorks: action.payload };
    case pageConstants.FETCH_TERMS:
      return { ...state, terms: action.payload };
    case pageConstants.FETCH_CORPORATE_CLIENT:
      return { ...state, corporateClient: action.payload };
    case pageConstants.FETCH_CORPORATE_SERVICE_AGREEMENT:
      return { ...state, corporateServiceAgreement: action.payload };
    case pageConstants.FETCH_PREFERRED_PARTNER_AGREEMENT:
      return { ...state, preferredPartnerAgreement: action.payload };
    case pageConstants.FETCH_TESTIMONIALS:
      return { ...state, testimonials: action.payload };
    case pageConstants.FETCH_CASE_STUDIES:
      return { ...state, caseStudies: action.payload };
    case pageConstants.FETCH_COVID19:
      return { ...state, covid19: action.payload };
    case pageConstants.FETCH_COMPANY_NEWS:
      return { ...state, companyNews: action.payload };
    case pageConstants.FETCH_PROVIDER_POLICY:
      return { ...state, providerPolicy: action.payload };
    case pageConstants.FETCH_PRICING:
      return { ...state, pricing: action.payload };
    case pageConstants.FETCH_CASE_STUDY:
      return { ...state, caseStudy: action.payload };
    case pageConstants.FETCH_CLEANING_REPORT:
      return { ...state, cleaningReport: action.payload };
    case pageConstants.FETCH_PROVIDER_AGREEMENT_V2:
      return { ...state, providerAgreementV2: action.payload };
    case pageConstants.FETCH_PROVIDER_RESOURCE:
      return { ...state, providerResource: action.payload };
    case pageConstants.FETCH_TIERED_SERVICE_PRICING:
      return { ...state, tieredServicePricing: action.payload };
    case pageConstants.FETCH_AMAZON_CASE_STUDY:
      return { ...state, amazonCaseStudy: action.payload };
    case pageConstants.FETCH_SARES_REGIS:
      return { ...state, saresRegis: action.payload };
    default:
      return state;
  }
};

export default pageReducer;
