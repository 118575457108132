import { portalBookingFieldPrivileges, portalUserFieldPrivileges, privileges } from "./privileges";

export enum RoleType {
  SUPER_ADMIN = "superadmin",
  ADMIN = "admin",
  ADMINSALESHYBRID = "adminsaleshybrid",
  SALES = "sales",
  SALES_ADMIN = "salesadmin",
  SALES_EXEC = "salesexec",
  CSM = "csm",
  PROVIDER = "provider",
  TEMP_PROVIDER = "temp-provider",
  CUSTOMER = "customer",
  GUEST_CUSTOMER = "guestcustomer",
  DEVELOPER = "developer",
  QA = "qa",
  PORTAL_GUEST = "portal-guest",
}

export const allBookingFieldPriviledges = Object.values(portalBookingFieldPrivileges).map((x) => x.name);

export const allUserFieldPriviledges = Object.values(portalUserFieldPrivileges).map((x) => x.name);

const bookingFieldPriviledges = [
  privileges.EDIT_BOOKING_DESCRIPTION_FIELD.name,
  privileges.EDIT_BOOKING_STAIRS_FIELD.name,
  privileges.EDIT_BOOKING_DISMANTLING_FIELD.name,
  privileges.EDIT_BOOKING_DATE_FIELD.name,
  privileges.EDIT_BOOKING_TIME_FIELD.name,
  privileges.EDIT_BOOKING_PICKUP_TYPE_FIELD.name,
  privileges.EDIT_BOOKING_VEHICLE_FIELD.name,
  privileges.EDIT_BOOKING_CUSTOMER_FIELD.name,
  privileges.EDIT_BOOKING_COMPANY_FIELD.name,
  privileges.EDIT_BOOKING_CLOSED_BY_FIELD.name,
  privileges.EDIT_BOOKING_PROVIDER_FIELD.name,
  privileges.EDIT_BOOKING_SERVICE_LOCATION_FIELD.name,
  privileges.EDIT_BOOKING_ADDITIONAL_ADDRESS_FIELD.name,
  privileges.EDIT_BOOKING_DROP_OFF_FIELD.name,
  privileges.EDIT_BOOKING_CUSTOMER_PRICING_FIELD.name,
  privileges.EDIT_BOOKING_GCLID_INTERCOMM_FIELD.name,
  privileges.EDIT_BOOKING_TASK_IMAGE_FIELD.name,
  privileges.ADD_INVOICE.name,
];

const userFieldPriviledges = [
  privileges.EDIT_USER_AVATAR_FIELD.name,
  privileges.EDIT_USER_FIRST_NAME_FIELD.name,
  privileges.EDIT_USER_LAST_NAME_FIELD.name,
  privileges.EDIT_USER_EMAIL_FIELD.name,
  privileges.EDIT_USER_PHONE_FIELD.name,
  privileges.EDIT_USER_PASSWORD_FIELD.name,
  privileges.EDIT_USER_BUSINESS_FIELD.name,
  privileges.EDIT_USER_STRIPE_FIELD.name,
  privileges.EDIT_USER_PAYMENT_METHOD_FIELD.name,
  privileges.EDIT_USER_NOTES_FIELD.name,
  privileges.EDIT_BG_CLEARANCE_FIELD.name,
  privileges.EDIT_USER_BILLING_EMAIL_FIELD.name,
];

const baseSalesPriviledges = [
  privileges.PORTAL_ACCESS.name,
  // mimic user by admin
  privileges.SHOW_MIMIC_LOGIN_AS_ENTRY_CUSTOMER.name,
  privileges.SHOW_MIMIC_LOGIN_AS_ENTRY_PROVIDER.name,
  // portal bookings
  privileges.SHOW_PORTAL_BOOKINGS_TAB.name,
  privileges.CREATE_PORTAL_BOOKING.name,
  privileges.VIEW_PORTAL_BOOKING.name,
  privileges.EDIT_PORTAL_BOOKING.name,
  privileges.DO_IT_AGAIN_BOOKING.name,
  // portal customers
  privileges.SHOW_PORTAL_CUSTOMERS_TAB.name,
  privileges.VIEW_PORTAL_CUSTOMER.name,
  // portal providers
  privileges.SHOW_PORTAL_PROVIDERS_TAB.name,
  privileges.VIEW_PORTAL_PROVIDER.name,
  // portal recurring bookings
  privileges.SHOW_PORTAL_RECURRING_BOOKINGS_TAB.name,
  privileges.VIEW_PORTAL_RECURRING_BOOKING.name,
  // portal provider quotes
  privileges.SHOW_PORTAL_PROVIDER_QUOTES_TAB.name,
  privileges.CREATE_PORTAL_PROVIDER_QUOTE.name,
  privileges.VIEW_PORTAL_PROVIDER_QUOTE.name,
  // portal coupons
  privileges.SHOW_PORTAL_COUPONS_TAB.name,
  privileges.CREATE_PORTAL_COUPON.name,
  privileges.VIEW_PORTAL_COUPON.name,
  // portal vip customers
  privileges.SHOW_PORTAL_VIP_CUSTOMERS_TAB.name,
  privileges.CREATE_PORTAL_VIP_CUSTOMER.name,
  privileges.VIEW_PORTAL_VIP_CUSTOMER.name,
  // portal invoices
  privileges.SHOW_PORTAL_INVOICES_TAB.name,
  privileges.VIEW_PORTAL_INVOICE.name,
  // portal dump sites
  privileges.SHOW_PORTAL_DUMP_SITES_TAB.name,
  privileges.CREATE_PORTAL_DUMP_SITE.name,
  privileges.VIEW_PORTAL_DUMP_SITE.name,
  // portal customer quotes / bids
  privileges.SHOW_PORTAL_CUSTOMER_QUOTES_TAB.name,
  privileges.CREATE_PORTAL_CUSTOMER_QUOTE.name,
  privileges.VIEW_PORTAL_CUSTOMER_QUOTE.name,
  privileges.EDIT_PORTAL_CUSTOMER_QUOTE.name,
  privileges.EDIT_BOOKING_BID_STATUS_FIELD.name,
  //account settings
  privileges.VIEW_ACCOUNT_PROFILE.name,
  //filters
  privileges.FILTER_BY_CLOSED_BY.name,
  // sales dashboard
  privileges.SHOW_PORTAL_SALES_DASHBOARD_TAB.name,
  privileges.VIEW_PORTAL_SALES_DASHBOARD.name,
  // portal business accounts
  privileges.SHOW_PORTAL_BUSINESS_ACCOUNTS_TAB.name,
  privileges.CREATE_PORTAL_BUSINESS_ACCOUNT.name,
  privileges.EDIT_PORTAL_BUSINESS_ACCOUNT.name,
  privileges.VIEW_PORTAL_BUSINESS_ACCOUNT.name,
  // portal user edit
  privileges.EDIT_USER_NOTES_FIELD.name,
  privileges.EDIT_ACCOUNT_MANAGER_FIELD.name,
  // portal view commission
  privileges.SHOW_PORTAL_SALES_COMMISSIONS.name,
  privileges.SEND_BOOKING_UPDATE_ALERT.name,
  // booking fields
  ...bookingFieldPriviledges,
  privileges.EDIT_BOOKING_PROVIDER_PRICING_FIELD.name,
  privileges.EDIT_BOOKING_INVOICE_NOTES_FIELD.name,
  // sourcing fields
  privileges.VIEW_SOURCING.name,
  // subscriptions
  privileges.SHOW_PORTAL_SUBSCRIPTIONS_TAB.name,
];

const adminPriviledges = [
  privileges.PORTAL_ACCESS.name,
  // mimic user by admin
  privileges.SHOW_MIMIC_LOGIN_AS_ENTRY_CUSTOMER.name,
  privileges.SHOW_MIMIC_LOGIN_AS_ENTRY_PROVIDER.name,
  privileges.MIMIC_LOGIN_USER_EDIT_PROFILE.name,
  // portal settings
  privileges.VIEW_PORTAL_SETTINGS.name,
  privileges.EDIT_PORTAL_SETTINGS.name,
  privileges.VIEW_PORTAL_DOWNLOAD_CSV.name,
  // portal bookings
  // privileges.DO_IT_AGAIN_BOOKING.name,
  privileges.SHOW_PORTAL_BOOKINGS_TAB.name,
  privileges.CREATE_PORTAL_BOOKING.name,
  privileges.EDIT_PORTAL_BOOKING.name,
  privileges.DELETE_PORTAL_BOOKING.name,
  privileges.VIEW_PORTAL_BOOKING.name,
  privileges.VIEW_PORTAL_BOOKING_RAW_DATA.name,
  privileges.SHOW_INVOICE_EMAIL_CHECK.name,
  // portal customers
  privileges.SHOW_PORTAL_CUSTOMERS_TAB.name,
  privileges.EDIT_PORTAL_CUSTOMER.name,
  privileges.VIEW_PORTAL_CUSTOMER.name,
  // portal providers
  privileges.SHOW_PORTAL_PROVIDERS_TAB.name,
  privileges.EDIT_PORTAL_PROVIDER.name,
  privileges.VIEW_PORTAL_PROVIDER.name,
  privileges.VERIFY_WARRIOR.name,
  // portal recurring bookings
  privileges.SHOW_PORTAL_RECURRING_BOOKINGS_TAB.name,
  privileges.CREATE_PORTAL_RECURRING_BOOKING.name,
  privileges.EDIT_PORTAL_RECURRING_BOOKING.name,
  privileges.VIEW_PORTAL_RECURRING_BOOKING.name,
  // portal provider quotes
  privileges.SHOW_PORTAL_PROVIDER_QUOTES_TAB.name,
  privileges.CREATE_PORTAL_PROVIDER_QUOTE.name,
  privileges.EDIT_PORTAL_PROVIDER_QUOTE.name,
  privileges.VIEW_PORTAL_PROVIDER_QUOTE.name,
  // portal coupons
  privileges.SHOW_PORTAL_COUPONS_TAB.name,
  privileges.CREATE_PORTAL_COUPON.name,
  privileges.EDIT_PORTAL_COUPON.name,
  privileges.VIEW_PORTAL_COUPON.name,
  // portal vip customers
  privileges.SHOW_PORTAL_VIP_CUSTOMERS_TAB.name,
  privileges.CREATE_PORTAL_VIP_CUSTOMER.name,
  privileges.EDIT_PORTAL_VIP_CUSTOMER.name,
  privileges.VIEW_PORTAL_VIP_CUSTOMER.name,
  // portal invoices
  privileges.SHOW_PORTAL_INVOICES_TAB.name,
  privileges.EDIT_PORTAL_INVOICE.name,
  privileges.VIEW_PORTAL_INVOICE.name,
  // portal dump sites
  privileges.SHOW_PORTAL_DUMP_SITES_TAB.name,
  privileges.CREATE_PORTAL_DUMP_SITE.name,
  privileges.EDIT_PORTAL_DUMP_SITE.name,
  privileges.VIEW_PORTAL_DUMP_SITE.name,
  // portal customer quotes
  privileges.SHOW_PORTAL_CUSTOMER_QUOTES_TAB.name,
  privileges.CREATE_PORTAL_CUSTOMER_QUOTE.name,
  privileges.EDIT_PORTAL_CUSTOMER_QUOTE.name,
  privileges.VIEW_PORTAL_CUSTOMER_QUOTE.name,
  // portal business accounts
  privileges.SHOW_PORTAL_BUSINESS_ACCOUNT_TAB.name,
  privileges.CREATE_PORTAL_BUSINESS_ACCOUNT.name,
  privileges.EDIT_PORTAL_BUSINESS_ACCOUNT.name,
  privileges.VIEW_PORTAL_BUSINESS_ACCOUNT.name,
  // portal service locations
  privileges.SHOW_PORTAL_SERVICE_LOCATIONS_TAB.name,
  // account settings
  privileges.VIEW_ACCOUNT_PROFILE.name,
  //filters
  privileges.FILTER_BY_CLOSED_BY.name,
  // sales dashboard
  privileges.SHOW_PORTAL_SALES_DASHBOARD_TAB.name,
  privileges.VIEW_PORTAL_SALES_DASHBOARD.name,
  // alerting and notifications
  privileges.SEND_BOOKING_UPDATE_ALERT.name,
  // invoices
  privileges.ADD_INVOICE.name,
  privileges.VIEW_SOURCING.name,
  // subscription
  privileges.SHOW_PORTAL_SUBSCRIPTIONS_TAB.name,
  // payment recon
  privileges.VIEW_PAYMENT_DATA.name,
  // View Warrior complete page
  privileges.VIEW_GET_TASKS_PAGE.name,
];

const csmPriviledges = [
  ...baseSalesPriviledges,
  ...userFieldPriviledges,
  // payment recon
  privileges.VIEW_PAYMENT_DATA.name,
  privileges.SHOW_RETENTION_SHEET.name,
  privileges.EDIT_PORTAL_INVOICE.name,
  privileges.VIEW_PORTAL_INVOICE.name,
  privileges.CREATE_GENERIC_INVOICE.name,
  privileges.CREATE_COMBINED_INVOICE.name,
];

const qaPriviledges = [
  privileges.PORTAL_ACCESS.name,
  // portal bookings
  privileges.SHOW_PORTAL_BOOKINGS_TAB.name,
  // portal customers
  privileges.SHOW_PORTAL_CUSTOMERS_TAB.name,
  privileges.VIEW_PORTAL_CUSTOMER.name,
  // portal providers
  privileges.SHOW_PORTAL_PROVIDERS_TAB.name,
  privileges.VIEW_PORTAL_PROVIDER.name,
  // portal dump sites
  privileges.SHOW_PORTAL_DUMP_SITES_TAB.name,
  privileges.CREATE_PORTAL_DUMP_SITE.name,
  privileges.EDIT_PORTAL_DUMP_SITE.name,
  privileges.VIEW_PORTAL_DUMP_SITE.name,
  // portal service locations
  privileges.SHOW_PORTAL_SERVICE_LOCATIONS_TAB.name,
  // invoices
  privileges.VIEW_SOURCING.name,
  // sales dashboard
  privileges.SHOW_PORTAL_SALES_DASHBOARD_TAB.name,
  privileges.VIEW_PORTAL_SALES_DASHBOARD.name,
];

export const roles = {
  developer: {
    name: "DEVELOPER",
    label: "Developer",
    value: "developer",
    description: "Has Permissions over Everything.",
    icon: "developer",
    homepage: "/",
    privileges: Object.values(privileges).map((x) => x.name),
    type: RoleType.DEVELOPER,
  },
  superadmin: {
    name: "SUPER_ADMIN",
    label: "Super Admin",
    value: "superadmin",
    description: "Has Permissions over Everything.",
    icon: "superadmin",
    homepage: "/portal",
    privileges: Object.values(privileges).map((x) => x.name),
    type: RoleType.SUPER_ADMIN,
  },
  admin: {
    name: "ADMIN",
    label: "Admin",
    value: "admin",
    description: "Has Admin-Level Permissions.",
    icon: "admin",
    homepage: "/portal",
    type: RoleType.ADMIN,
    privileges: [...adminPriviledges, ...allBookingFieldPriviledges, ...allUserFieldPriviledges],
  },
  adminsaleshybrid: {
    name: "ADMIN SALES HYBRID",
    label: "Admin Sales Hybrid",
    value: "adminsaleshybrid",
    description: "Has Admin-Level Permissions and Sales Priveledges.",
    icon: "admin",
    homepage: "/portal",
    type: RoleType.ADMINSALESHYBRID,
    privileges: [
      ...adminPriviledges,
      ...baseSalesPriviledges,
      ...allBookingFieldPriviledges,
      ...allUserFieldPriviledges,
      privileges.EDIT_PORTAL_INVOICE.name,
      privileges.VIEW_PORTAL_INVOICE.name,
      privileges.CREATE_GENERIC_INVOICE.name,
      privileges.CREATE_COMBINED_INVOICE.name,
    ],
  },
  qa: {
    name: "QA",
    label: "QA",
    value: "qa",
    description: "Has QA-Level Permissions.",
    icon: "admin",
    homepage: "/portal",
    type: RoleType.ADMIN,
    privileges: [...qaPriviledges],
  },
  sales: {
    name: "SALES",
    label: "Sales",
    value: "sales",
    description: "Has Sales-Level Permissions.",
    icon: "sales",
    homepage: "/portal",
    type: RoleType.SALES,
    privileges: [...baseSalesPriviledges, ...userFieldPriviledges],
  },
  salesexec: {
    name: "SALES_EXEC",
    label: "Sales Exec",
    value: "salesexec",
    description: "Has Sales-Exec-Level Permissions.",
    icon: "sales",
    homepage: "/portal",
    type: RoleType.SALES_EXEC,
    privileges: [...baseSalesPriviledges, ...userFieldPriviledges],
  },
  salesadmin: {
    name: "SALES_ADMIN",
    label: "Sales Admin",
    value: "salesadmin",
    description: "Has Sales-Level Admin Permissions.",
    icon: "sales",
    homepage: "/portal",
    type: RoleType.SALES_ADMIN,
    privileges: [
      ...baseSalesPriviledges,
      ...userFieldPriviledges,
      privileges.SET_SALES_GOALS.name,
      privileges.VIEW_PORTAL_SALES_COMMISSIONS_ALL.name,
    ],
  },
  csm: {
    name: "CUSTOMER SUCCESS MANAGER",
    label: "CSM",
    value: "csm",
    description: "Has CSM-Level Permissions.",
    icon: "sales",
    homepage: "/portal",
    type: RoleType.CSM,
    privileges: csmPriviledges,
  },
  provider: {
    name: "PROVIDER",
    label: "Provider",
    value: "provider",
    description: "Has Provider-Level Permissions.",
    icon: "provider",
    homepage: "/get_tasks",
    type: RoleType.PROVIDER,
    privileges: [
      privileges.VIEW_PROVIDER_BOOKING_PAGE.name,
      privileges.VIEW_GET_TASKS_PAGE.name,
      privileges.VIEW_ACCOUNT_PROFILE.name,
      privileges.EDIT_ACCOUNT_PROFILE.name,
      privileges.VIEW_ACCOUNT_SETTINGS.name,
      privileges.EDIT_ACCOUNT_SETTINGS.name,
    ],
  },
  ["temp-provider"]: {
    name: "TEMP PROVIDER",
    label: "Temp Provider",
    value: "temp-provider",
    description: "Has Provider-Level Permissions.",
    icon: "provider",
    homepage: "/",
    type: RoleType.TEMP_PROVIDER,
    privileges: [
      privileges.VIEW_ACCOUNT_PROFILE.name,
    ],
  },
  customer: {
    name: "CUSTOMER",
    label: "Customer",
    value: "customer",
    description: "Has Customer-Level Permissions.",
    icon: "customer",
    homepage: "/bookings",
    type: RoleType.CUSTOMER,
    privileges: [
      privileges.CREATE_BOOKING.name,
      privileges.VIEW_MY_BOOKINGS_PAGE.name,
      privileges.VIEW_CUSTOMER_BOOKING_PAGE.name,
      privileges.VIEW_ACCOUNT_PROFILE.name,
      privileges.EDIT_ACCOUNT_PROFILE.name,
      privileges.VIEW_ACCOUNT_SETTINGS.name,
      privileges.EDIT_ACCOUNT_SETTINGS.name,
      privileges.VIEW_COMPANY_SETTINGS.name,
      privileges.EDIT_COMPANY_SETTINGS.name,
      privileges.VIEW_INTEGRATIONS_PAGE.name,
    ],
  },
  business: {
    name: "BUSINESS",
    label: "Business",
    value: "business",
    description: "Has Business-Level Permissions.",
    icon: "business",
    homepage: "/bookings",
    privileges: [
      privileges.CREATE_BOOKING.name,
      privileges.VIEW_MY_BOOKINGS_PAGE.name,
      privileges.VIEW_CUSTOMER_BOOKING_PAGE.name,
      privileges.VIEW_ACCOUNT_PROFILE.name,
      privileges.EDIT_ACCOUNT_PROFILE.name,
      privileges.VIEW_ACCOUNT_SETTINGS.name,
      privileges.EDIT_ACCOUNT_SETTINGS.name,
      privileges.VIEW_COMPANY_SETTINGS.name,
      privileges.EDIT_COMPANY_SETTINGS.name,
      privileges.VIEW_INTEGRATIONS_PAGE.name,
    ],
  },
  guestcustomer: {
    name: "GUEST_CUSTOMER",
    label: "Guest Customer",
    value: "guest-customer",
    description: "Has Least Permissions.",
    icon: "customer",
    homepage: "/",
    type: RoleType.GUEST_CUSTOMER,
    privileges: [privileges.CREATE_BOOKING.name, privileges.VIEW_CUSTOMER_BOOKING_PAGE.name],
  },
  ["portal-guest"]: {
    name: "PORTAL_GUEST",
    label: "Guest Portal",
    value: "portal-guest",
    description: "Has only 2 permissions right now.",
    icon: "customer",
    homepage: "/portal-guest/sales",
    type: RoleType.PORTAL_GUEST,
    privileges: [privileges.GUEST_PORTAL_ACCESS.name, privileges.SHOW_PORTAL_SALES_DASHBOARD_TAB.name, privileges.VIEW_PORTAL_SALES_DASHBOARD.name, privileges.EXPORT_SALES_DASHBOARD.name],
  },
};

export const rolesArray = Object.keys(roles);

export const portalUserRoles = [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
  RoleType.ADMINSALESHYBRID,
  RoleType.SALES,
  RoleType.SALES_ADMIN,
  RoleType.SALES_EXEC,
  RoleType.CSM,
  RoleType.PORTAL_GUEST,
];
