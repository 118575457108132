const authTypes = {
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_ERROR: "SIGN_OUT_ERROR",

  SET_ADMIN_ENTRY: "SET_ADMIN_ENTRY",
  RESET_ADMIN_ENTRY: "RESET_ADMIN_ENTRY",

  SET_FB_PROFILE: "@@reactReduxFirebase/SET_PROFILE",
  RESET_FB_PROFILE: "@@reactReduxFirebase/AUTH_EMPTY_CHANGE",

  SET_USERS: "SET_USERS",
  ADD_USER: "ADD_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",

  SET_CUSTOMERS: "SET_CUSTOMERS",
  SET_PROVIDERS: "SET_PROVIDERS",

  SET_WARRIOR: "SET_WARRIOR",
  SET_UPDATED_USER: "SET_UPDATED_USER",

  SET_USERS_LOADING: "SET_USERS_LOADING",
  RESET_USERS_LOADING: "RESET_USERS_LOADING",

  MIGRATION_PWD_SET: "MIGRATION_PWD_SET",
  CLEAR_MIGRATION_PWD: "CLEAR_MIGRATION_PWD",

  SET_BONUS_PAYOUTS: "SET_BONUS_PAYOUTS",
};

export default authTypes;
