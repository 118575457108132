export const emissionsByMaterial: any = {
  cardboard: {
    buried: 0.033,
  },
  "bulky-furniture": {
    buried: 0.038,
  },
  "solid-waste": {
    buried: 0.110,
  },
  "e-waste": {
    buried: 0.044,
  },
  "hazardous-waste": {
    buried: 0.110,
  },
  wood: {
    buried: 0.033,
  },
  plastic: {
    buried: 0.265,
  },
  metal: {
    buried: 0.044,
  },
  glass: {
    buried: 0.033,
  },
  textile: {
    buried: 0.066,
  },
  compost: {
    buried: 0.066,
  },
  food: {
    buried: 0.066,
  },
  "construction-material": {
    buried: 0.132,
  },
  construction: {
    buried: 0.132,
  },
  ceramics: {
    buried: 0.088,
  },
  concrete: {
    buried: 0.110,
  },
  electronics: {
    buried: 0.044,
  },
  "organic-waste": {
    buried: 0.066,
  },
  paper: {
    buried: 0.033,
  },
  rubber: {
    buried: 0.110,
  },
  other: {
    buried: 0.110,
  },
};

export const CO2_OFFSET_AMOUNT_PER_TON = 2300; // $23 per ton of CO2 offset

export const AVERAGE_DROPOFF_DISTANCE_MILES = 40;

export const ESTIMATED_DROPOFF_EMISSIONS_PER_MILE = 4.321; // truck emissions => 1.96 kg per mile => 4.321 lbs per mile
