import abTestConstants from "@store/constants/abTestTypes";

const INITIAL_STATE = {
  tests: [],
  test: null,
  liveInstance: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case abTestConstants.FETCH_AB_TESTS:
      return {
        ...state,
        tests: action.payload,
      };
    case abTestConstants.FETCH_AB_TEST:
      return {
        ...state,
        test: action.payload,
      };
    case abTestConstants.CREATE_AB_TEST_INSTANCE:
      return {
        ...state,
        test: {
          ...state.test,
          instances: [action.payload, ...state.test.instances],
        },
      };
    case abTestConstants.UPDATE_AB_TEST_INSTANCE:
      return {
        ...state,
        test: {
          ...state.test,
          instances: state.test.instances.map((i) =>
            i.id === action.payload.id
              ? {
                  ...i,
                  ...action.payload.form,
                }
              : i
          ),
        },
      };
    case abTestConstants.SET_AB_TEST_LIVE_INSTANCE:
      return {
        ...state,
        liveInstance: {
          ...state.liveInstance,
          ...action.payload,
        },
      };
    case abTestConstants.SET_AB_TEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case abTestConstants.RESET_AB_TEST_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
