import { AdminActions } from "../constants/admin";

const INITIAL_STATE = {
  isLoading: false,
  users: [],
  teams: [],
  notification_settings: [],
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AdminActions.LOADING_ADMIN_STUFF:
      return {
        ...state,
        isLoading: true,
      };
    case AdminActions.SET_ADMIN_USERS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case AdminActions.UPDATE_ADMIN_USER:
      return {
        ...state,
        isLoading: false,
        users: state.users.map((user: any) => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          return user;
        }),
      };
    case AdminActions.RESET_ADMIN_USERS:
      return {
        ...state,
        isLoading: false,
        users: [],
      };
    case AdminActions.SET_ADMIN_TEAMS:
      return {
        ...state,
        isLoading: false,
        teams: action.payload,
      };
    case AdminActions.RESET_ADMIN_TEAMS:
      return {
        ...state,
        isLoading: false,
        teams: [],
      };
    case AdminActions.ADD_NEW_TEAM:
      return {
        ...state,
        isLoading: false,
        teams: [action.payload, ...state.teams],
      };
    case AdminActions.UPDATE_TEAM:
      return {
        ...state,
        isLoading: false,
        teams: state.teams.map((team: any) => {
          if (team.id === action.payload.id) {
            return action.payload;
          }
          return team;
        }),
      };
    case AdminActions.DELETE_TEAM:
      return {
        ...state,
        isLoading: false,
        teams: state.teams.filter((team: any) => {
          return team.id !== action.payload;
        }),
      };
    case AdminActions.SET_ADMIN_NOTIFICATION_SETTINGS:
      return {
        ...state,
        isLoading: false,
        notification_settings: action.payload,
      };
    case AdminActions.RESET_ADMIN_NOTIFICATION_SETTINGS:
      return {
        ...state,
        isLoading: false,
        notification_settings: [],
      };
    case AdminActions.ADD_NEW_NOTIFICATION_SETTING:
      return {
        ...state,
        isLoading: false,
        notification_settings: [action.payload, ...state.notification_settings],
      };
    case AdminActions.UPDATE_NOTIFICATION_SETTING:
      return {
        ...state,
        isLoading: false,
        notification_settings: state.notification_settings.map((notification_setting: any) => {
          if (notification_setting.id === action.payload.id) {
            return action.payload;
          }
          return notification_setting;
        }),
      };
    case AdminActions.DELETE_NOTIFICATION_SETTING:
      return {
        ...state,
        isLoading: false,
        notification_settings: state.notification_settings.filter((notification_setting: any) => {
          return notification_setting.id !== action.payload;
        }),
      };
    default:
      return state;
  }
};
