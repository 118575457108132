const salesGoalsTypes = {
  SET_SALES_LOADING: "SET_SALES_LOADING",
  RESET_SALES_LOADING: "RESET_SALES_LOADING",

  SET_GOAL_EDITS: "SET_GOAL_EDITS",
  RESET_GOAL_EDITS: "RESET_GOAL_EDITS",

  SAVE_GOAL_EDITS: "SAVE_GOAL_EDITS",
  SAVE_GOAL_EDITS_ERROR: "SAVE_GOAL_EDITS_ERROR",

  FETCH_SALES_GOALS: "FETCH_SALES_GOALS",
  FETCH_SALES_GOALS_ERROR: "FETCH_SALES_GOALS_ERROR",

  UPDATE_ACCRUAL: "UPDATE_ACCRUAL",

  EDITS_MADE: "EDITS_MADE",
  CLEAR_EDITS_MADE: "CLEAR_EDITS_MADE",

  FETCH_PIPELINE_METRICS: "FETCH_PIPELINE_METRICS",
  FETCH_PIPELINE_METRICS_ERROR: "FETCH_PIPELINE_METRICS_ERROR",
};

export default salesGoalsTypes;
