/* eslint-disable no-console */
import locationConstants from "@store/constants/locationPricingTypes";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import useFirestoreListener from "@shared/hooks/useFirestoreListener";
import { useAxiosPost, useAxiosGet } from "../../axios";

export const setActivePricing = (activeLocation) => (dispatch) => {
  dispatch({ type: locationConstants.SET_ACTIVE_PRICING, activeLocation });
};

export const setLocationOpts = (opts) => (dispatch) => {
  dispatch({ type: locationConstants.SET_LOCATION_OPTS, payload: opts });
};

export const setLocationError = (error) => (dispatch) => {
  dispatch({ type: locationConstants.SET_LOCATION_ERROR, payload: error });
};

export const setUrlAddressField = (address) => (dispatch) => {
  dispatch({ type: locationConstants.SET_URL_ADDRESS_FIELD, payload: address });
};

export const resetActivePricing = () => (dispatch) => {
  dispatch({ type: locationConstants.RESET_ACTIVE_PRICING });
};

export const addLocationState = (location) => (dispatch) => {
  dispatch({ type: locationConstants.ADD_PRICING_LOCATION, payload: location });
};

export const updateLocationState = (location) => (dispatch) => {
  dispatch({ type: locationConstants.UPDATE_PRICING_LOCATION, payload: location });
};

export const deleteLocationState = (location) => (dispatch) => {
  dispatch({ type: locationConstants.DELETE_PRICING_LOCATION, payload: location });
};

export const createPricing = (form) => async (dispatch, getState) => {
  try {
    form.createdBy = getState().firebase.profile.uid;
    dispatch({ type: locationConstants.SET_PRICING_LOADING });
    await useAxiosPost("/locations/create", form);
    toastr.success("Created !");
  } catch (error) {
    console.log("Error: ", error);
  } finally {
    dispatch({ type: locationConstants.RESET_PRICING_LOADING });
  }
};

export const updatePricing = (form) => async (_, getState) => {
  try {
    form.updatedBy = getState().firebase?.profile?.email ?? "";
    await useAxiosPost("/locations/update", form);
    toastr.success("Updated !");
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const deletePricing = (id) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/locations/delete/${id}`);
    if (!response?.data) {
      return;
    }
    dispatch({
      type: locationConstants.DELETE_PRICING_LOCATION,
      payload: { id },
    });
    toastr.success("Done", "Location Pricing deleted");
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    toastr.error("Error", "Location Pricing could not be deleted");
  }
};

export const loadServiceAreas = () => async (dispatch) => {
  try {
    dispatch({ type: locationConstants.SET_PRICING_LOADING });
    const response = await useAxiosGet(`/locations/service-areas`);
    dispatch({ type: locationConstants.FETCH_PRICING_LOCATIONS, locations: response.data });
  } catch (error) {
    console.log("Error: ", error);
    dispatch({
      type: locationConstants.FETCH_PRICING_ERROR,
      payload: error.response,
    });
  } finally {
    dispatch({ type: locationConstants.RESET_PRICING_LOADING });
  }
};
export const loadServiceAreasClient = () => async (dispatch) => {
  try {
    dispatch({ type: locationConstants.SET_PRICING_LOADING });
    const response = await useAxiosGet(`/locations/service-areas-client`);
    dispatch({ type: locationConstants.FETCH_PRICING_LOCATIONS, locations: response.data });
  } catch (error) {
    console.log("Error: ", error);
    dispatch({
      type: locationConstants.FETCH_PRICING_ERROR,
      payload: error.response,
    });
  } finally {
    dispatch({ type: locationConstants.RESET_PRICING_LOADING });
  }
};

export const getTextSearchPlaces = async (query, location, radius) => {
  try {
    const response = await useAxiosPost(`locations/get-text-search-places`, { query, location, radius });
    if (response?.data?.length) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPlaceDetails = async (place_id) => {
  try {
    const response = await useAxiosGet(`locations/get-place-details/${place_id}`);
    if (Object.keys(response?.data).length) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// hooks

export const useSubscribeToLocations = () => {
  const dispatch = useDispatch();
  useFirestoreListener("location_pricing", {
    onAdd: (newDoc) => {
      dispatch(addLocationState(newDoc));
    },
    onUpdate: (updatedDoc) => {
      dispatch(updateLocationState(updatedDoc));
    },
    onDelete: (deletedDoc) => {
      dispatch(deleteLocationState(deletedDoc));
    },
  });
};
