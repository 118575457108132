export enum CacheActions {
  WRITE_BOOKING_TO_CACHE = "WRITE_BOOKING_TO_CACHE",
  WRITE_USER_TO_CACHE = "WRITE_USER_TO_CACHE",
  WRITE_SUBSCRIPTION_TO_CACHE = "WRITE_SUBSCRIPTION_TO_CACHE",
  WRITE_BUNDLE_TO_CACHE = "WRITE_BUNDLE_TO_CACHE",
  WRITE_INVOICE_TO_CACHE = "WRITE_INVOICE_TO_CACHE",

  WRITE_BOOKING_TO_CACHE_BULK = "WRITE_BOOKING_TO_CACHE_BULK",
  WRITE_USER_TO_CACHE_BULK = "WRITE_USER_TO_CACHE_BULK",
  WRITE_SUBSCRIPTION_TO_CACHE_BULK = "WRITE_SUBSCRIPTION_TO_CACHE_BULK",
  WRITE_BUNDLE_TO_CACHE_BULK = "WRITE_BUNDLE_TO_CACHE_BULK",
  WRITE_INVOICE_TO_CACHE_BULK = "WRITE_INVOICE_TO_CACHE_BULK",

  CLEAR_CACHE = "CLEAR_CACHE",
}