import trackerConstants from "@store/constants/trackersTypes";

const INITIAL_STATE = {
  gclId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case trackerConstants.SET_GCLID:
      return {
        ...state,
        gclId: action.value,
      };
    case trackerConstants.RESET_GCLID:
      return INITIAL_STATE;
    default:
      return state;
  }
};
