import penaltyPointsTypes from "../constants/penaltyPointsTypes";

const INITIAL_STATE = {
  penaltyHistory: [],
};

const penaltyPointsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case penaltyPointsTypes.SET_PENALTY_HISTORY:
      return {
        ...state,
        penaltyHistory: action.payload ?? [],
      };
    default:
      return state;
  }
};

export default penaltyPointsReducer;
