import { FLOW_VARIANT } from "../../views/DisposalPage/constants";

const INITIAL_STATE = {
  formDraftId: null,
  formId: null,
  formValues: null,
  step: 1,
  flowVariant: FLOW_VARIANT.V1,
  prediction: null,
  inserted: false,
  loaded: false,
  faqId: null,
  updater: {
    id: null,
    loading: false,
    fetched: false,
    payload: null,
  },
};

const formDraftReducer = (state = INITIAL_STATE, action: { type: string, payload: any }) => {
  switch (action.type) {
    case "SET_FORM_DRAFT":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_FORM_DRAFT":
      return {
        ...INITIAL_STATE,
      };
    case "INSERT_FORM_DRAFT":
      return {
        ...state,
        inserted: true,
      };
    case "SET_UPDATER_REQUEST":
      return {
        ...state,
        updater: action.payload,
      };
    default:
      return state;
  }
};

export default formDraftReducer;
