import { extractNumber } from "@helpers/booking";
import { Frequency, Input, Step, SubscriptionFrequency, Task, Vehicle } from "./enums";
import { Payment } from "../../../constants/payments";
import dayjs from "dayjs";
import { Pickup } from "../../../constants/dumpsters";
import { defaultFrequencyRepeatMode } from "../../../constants/recurring";

export const desktopSteps = [Step.Initial, Step.DateAndTime, Step.TaskDescription, Step.Address, Step.Payment];
export const mobileSteps = [Step.Initial, Step.DateAndTime, Step.TaskDescription, Step.Address, Step.ReviewOrder, Step.Payment];
export const removalTasks = [Task.JunkRemoval, Task.CardboardRemoval];
export const removalQuoteTasks = [Task.JunkRemoval, Task.CardboardRemoval, Task.PowerWashing];
export const rentalTasks = [Task.DumpsterRental];

export const defaultValues = {
  [Input.Task]: Task.JunkRemoval,
  [Input.BusinessClient]: false,
  [Input.Frequency]: Frequency.Once,
  [Input.SubscriptionFrequency]: null,
  [Input.Pickup]: Pickup.NextBooking,
  [Input.Vehicle]: Vehicle.PickupTruck,
  [Input.Junk]: [],
  [Input.Dumpster]: [],
  [Input.TermsOfService]: false,
  [Input.UnderstandPricing]: false,
  [Input.PaymentMethod]: Payment.Card,
};

export const defaultBidBuffer = 48;
export const defaultNonMarketBuffer = 120;

export const getBookingDefaults = (booking, profile) => ({
  [Input.Date]: booking?.isLockedDate ? { value: dayjs(booking?.date) } : {},
  [Input.Time]: booking?.isLockedDate ? { value: booking?.time } : {},
  [Input.Pickup]: { value: booking?.pickup ?? defaultValues[Input.Pickup] },
  [Input.PickupDate]: booking?.isLockedDate ? { value: booking?.["pickup-date"] } : {},
  [Input.PickupTime]: booking?.isLockedDate ? { value: booking?.["pickup-time"] } : {},
  [Input.Frequency]: { value: booking?.frequency ?? defaultValues[Input.Frequency] },
  [Input.FrequencyPattern]: { value: booking?.frequencyPattern ?? [] },
  [Input.FrequencyRepeat]: { value: booking?.frequencyRepeat ?? 1 },
  [Input.FrequencyRepeatMode]: { value: booking?.frequencyRepeatMode ?? defaultFrequencyRepeatMode },
  [Input.SubscriptionFrequency]: { value: booking?.isAutoSelectBundleOn ? SubscriptionFrequency.Bundle : defaultValues[Input.SubscriptionFrequency] },
  [Input.Vehicle]: { value: booking?.vehicle ?? defaultValues[Input.Vehicle] },
  [Input.Junk]: { value: booking?.products ?? defaultValues[Input.Junk] },
  [Input.Dumpster]: { value: booking?.services ?? defaultValues[Input.Dumpster] },
  [Input.Description]: { value: booking?.description ?? "" },
  [Input.Stairs]: { value: booking?.stairs ?? 0 },
  [Input.Dismantling]: { value: booking?.dismantling ?? 0 },
  [Input.PickupAddress]: { value: booking?.address ?? {} },
  [Input.PickupAddressAdditional]: { value: booking?.address_aditional ?? "" },
  [Input.TermsOfService]: { value: booking?.tos_checked ?? defaultValues[Input.TermsOfService] },
  [Input.UnderstandPricing]: { value: booking?.understand_pricing_checked ?? defaultValues[Input.UnderstandPricing] },
  [Input.PaymentMethod]: { value: booking?.payment_method ?? defaultValues[Input.PaymentMethod] },
  [Input.PaymentFirstName]: { value: booking?.first_name ?? (profile?.fullName?.split(" ")[0] || "") },
  [Input.PaymentLastName]: { value: booking?.last_name ?? (profile?.fullName?.split(" ")[1] || "") },
  [Input.PaymentPhone]: { value: booking?.phone_number ?? (extractNumber(profile?.phoneNumber) || "") },
  [Input.PaymentEmail]: { value: booking?.email ?? (profile?.email || "") },
  [Input.TaskImages]: { value: booking?.taskImages?.length ? booking.taskImages : [] },
  [Input.CompanyName]: {
    value: booking?.businessClientDetails?.name ?? booking?.companyName ?? (profile?.companyName || profile?.company?.name || ""),
  },
  [Input.BusinessClient]: {
    value: booking?.[Input.BusinessClient] || booking?.businessClient || !!profile?.businessId,
    validation: { isValid: true },
  },
});

export const dumpsterDescription = `Please list the major items you plan to put in the dumpster. For example: "Please drop-off dumpster on left side of driveway. We plan to place general house garbage and a bicycle".`;
