/* eslint-disable */

import { KeywordActions } from "../constants/keywordTypes";
import { addItem, deleteItem, updateItem } from "../utils";


const INITIAL_STATE = {
  keywords: [],
  keyword: null,
  landing: null,
  loaded: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case KeywordActions.SET_KEYWORDS:
      return {
        ...state,
        keywords: action.payload,
        loaded: true,
      };
    case KeywordActions.SET_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };
    case KeywordActions.ADD_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
        keywords: addItem(state.keywords, action.payload),
      };
    case KeywordActions.UPDATE_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
        keywords: updateItem(state.keywords, action.payload),
      };
    case KeywordActions.DELETE_KEYWORD: {
      return {
        ...state,
        keyword: null,
        keywords: deleteItem(state.keywords, action.payload),
      };
    }
    case KeywordActions.SET_LANDING_KEYWORD:
      return {
        ...state,
        landing: action.payload,
        loaded: true,
      };
    default:
      return state;
  }
};
