import vipConstants from "@store/constants/vipTypes";

const INITIAL_STATE = {
  VIPList: [],
  activeVIP: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case vipConstants.FETCH_VIP_LIST:
      return {
        ...state,
        VIPList: action.payload,
      };
    case vipConstants.CREATE_VIP:
      return {
        ...state,
        newVIP: { ...action.payload },
      };
    case vipConstants.CREATE_VIP_ERROR:
      return {
        ...state,
        newVIP: { ...action.payload },
      };
    case vipConstants.SET_ACTIVE_QUOTE:
      return {
        ...state,
        activeVIP: { ...action.payload },
      };
    case vipConstants.SET_VIP_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case vipConstants.RESET_VIP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
