import authConstants from "@store/constants/authTypes";
import { getUserFieldName, addItem, updateItem, deleteItem, mergeItem } from "../utils";
import { roles } from "../../constants/roles";

const INITIAL_STATE = {
  errorMessage: null,
  isAdmin: false,
  users: [],
  warrior: null,
  customers: [],
  providers: [],
  isLoading: false,
  migratePwdReset: false,
  privileges: roles.guestcustomer.privileges,
  bonuses: [],
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authConstants.SET_FB_PROFILE: {
      return {
        ...state,
        privileges: action.profile?.userType ? roles[action.profile.userType].privileges : roles.guestcustomer.privileges,
      };
    }
    case authConstants.RESET_FB_PROFILE: {
      return {
        ...state,
        privileges: roles.guestcustomer.privileges,
      };
    }
    case authConstants.SIGN_OUT_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE, //resetting to initial state
      };
    case authConstants.SIGN_OUT_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case authConstants.SET_ADMIN_ENTRY: {
      return {
        ...state,
        isAdmin: true,
      };
    }
    case authConstants.RESET_ADMIN_ENTRY: {
      return {
        ...state,
        isAdmin: false,
      };
    }
    case authConstants.SET_USERS:
      return {
        ...state,
        users: mergeItem(state.users, action.payload),
      };
    case authConstants.SET_CUSTOMERS:
      return {
        ...state,
        customers: mergeItem(state.customers, action.payload),
      };
    case authConstants.SET_PROVIDERS:
      return {
        ...state,
        providers: mergeItem(state.providers, action.payload),
      };
    case authConstants.ADD_USER: {
      const fieldName = getUserFieldName(action.payload.userType);
      return {
        ...state,
        [fieldName]: addItem(state[fieldName], action.payload),
      };
    }
    case authConstants.UPDATE_USER: {
      const fieldName = getUserFieldName(action.payload.userType);
      return {
        ...state,
        [fieldName]: updateItem(state[fieldName], action.payload),
      };
    }
    case authConstants.DELETE_USER: {
      const fieldName = getUserFieldName(action.payload.userType);
      return {
        ...state,
        [fieldName]: deleteItem(state[fieldName], action.payload),
      };
    }
    case authConstants.SET_WARRIOR:
      return {
        ...state,
        warrior: action.payload,
      };
    case authConstants.SET_USERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case authConstants.RESET_USERS_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case authConstants.MIGRATION_PWD_SET:
      return {
        ...state,
        migratePwdReset: true,
        email: action.email,
      };
    case authConstants.CLEAR_MIGRATION_PWD:
      return {
        ...state,
        migratePwdReset: false,
        email: null,
      };
    case authConstants.SET_UPDATED_USER:
      return {
        ...state,
        updatedUser: action.payload,
      };
    case authConstants.SET_BONUS_PAYOUTS:
      return {
        ...state,
        bonuses: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
