import pricingConstants from "@store/constants/pricingTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  isLoading: false,
  items: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pricingConstants.SET_PRICING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case pricingConstants.RESET_PRICING_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case pricingConstants.FETCH_ITEM_PRICING:
      return {
        ...state,
        items: action.payload,
      };
    case pricingConstants.FETCH_ITEM_PRICING_ERROR:
      return {
        ...state,
      };
    case pricingConstants.ADD_ITEM_PRICING:
      return {
        ...state,
        items: addItem(state.items, action.payload),
      };
    case pricingConstants.UPDATE_ITEM_PRICING:
      return {
        ...state,
        items: updateItem(state.items, action.payload),
      };
    case pricingConstants.DELETE_ITEM_PRICING:
      return {
        ...state,
        items: deleteItem(state.items, action.payload),
      };
    default:
      return state;
  }
};
