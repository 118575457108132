/* eslint-disable no-console*/
import { useAxiosPost } from "../../axios";
import { segmentTrack } from "../../helpers/segment";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const checkFeatureFlag = async (flag, data, value, bypassLocal = false) => {
  try {
    const flagToCheck = `ld-flag-${flag}`;
    if (flagToCheck in window.sessionStorage && !bypassLocal) {
      const isUndefined = window.sessionStorage[flagToCheck] === "undefined";
      if (!isUndefined) {
        const flagValue = window.sessionStorage[flagToCheck];
        if (isJsonString(flagValue)) {
          return JSON.parse(window.sessionStorage[flagToCheck]);
        } else {
          return flagValue;
        }
      }
    }
    const response = await useAxiosPost("/feature_flags/check-flag", { flag, data, value });
    // if response from backend is undefined, use default value
    const retVal = response?.data?.isValid ?? value;
    segmentTrack("Feature Flag Check", { flag, value: retVal });
    window.sessionStorage.setItem(flagToCheck, JSON.stringify(retVal));
    return retVal;
  } catch (error) {
    console.error("Error checkFeatureFlag", error);
    return value;
  }
};

export const closeLDFlags = async () => {
  try {
    await useAxiosPost("/feature_flags/close-flags");
    return;
  } catch (error) {
    console.error("Error closeLDFlags", error);
  }
};
