const invoiceTypes = {
  ADD_INVOICE: "ADD_INVOICE",
  UPDATE_INVOICE: "UPDATE_INVOICE",

  FETCH_INVOICES: "FETCH_INVOICES",

  SET_SELECTED_INVOICE: "SET_SELECTED_INVOICE",
  RESET_SELECTED_INVOICE: "RESET_SELECTED_INVOICE",
  SET_INVOICES_LOADING: "SET_INVOICES_LOADING",
  RESET_INVOICES_LOADING: "RESET_INVOICES_LOADING",
};

export default invoiceTypes;
