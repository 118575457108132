/* eslint-disable */
import { INDEXED_DB_NAME } from "../../constants/indexedDB";
import { AttributeActions } from "../constants/attributes";
import { addItem, deleteItem, updateItem } from "../utils";

const INITIAL_STATE = {
  attributes: [],
  groups: [],
  loaded: false,
  datasets: {},
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AttributeActions.SET_ATTRIBUTES:
      return {
        ...state,
        attributes: action.payload.attributes,
        groups: action.payload.groups,
        loaded: true,
      };
    case AttributeActions.ADD_ATTRIBUTE:
      return {
        ...state,
        attributes: addItem(state.attributes, action.payload),
      };
    case AttributeActions.UPDATE_ATTRIBUTE:
      return {
        ...state,
        attributes: updateItem(state.attributes, action.payload),
      };
    case AttributeActions.DELETE_ATTRIBUTE:
      return {
        ...state,
        attributes: deleteItem(state.attributes, action.payload),
      };
    case AttributeActions.ADD_ATTRIBUTE_GROUP:
      return {
        ...state,
        groups: addItem(state.groups, action.payload),
      };
    case AttributeActions.UPDATE_ATTRIBUTE_GROUP:
      return {
        ...state,
        groups: updateItem(state.groups, action.payload),
      };
    case AttributeActions.DELETE_ATTRIBUTE_GROUP:
      return {
        ...state,
        groups: deleteItem(state.groups, action.payload),
      };
    case AttributeActions.SET_OPTIONS:
      const optionGroups: any = {};
      const dbName: any = INDEXED_DB_NAME;
      const db: any = window[dbName];
      const groups = Object.keys(action.payload);
      const recordValues: any = groups?.reduce((acc: any, curr) => {
        action.payload[curr]?.map((option: any) => {
          acc.push(({ ...option, key: curr, id: option.value }));
        });
        return acc;
      }, []);
      db("options", async (store: any) => {
        await store.upsertAll(recordValues);
        const dataRecords = await store.getAll();
        groups?.map((group: string) => {
          const groupOptions = dataRecords.filter((record: any) => record.key === group);
          optionGroups[group] = groupOptions;
        })
      })
      return {
        ...state,
        datasets: optionGroups,
      };
    default:
      return state;
  }
};
