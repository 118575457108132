import { INDEXED_DB_NAME } from "@constants/indexedDB";

export function initializeIndexedDB(dbName = INDEXED_DB_NAME) {
  if (!window.indexedDB) {
    console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available."); //eslint-disable-line
    return;
  }

  const logErrorMessage = (e) => console.error(`IndexedDB error: ${e.target.error}`); // eslint-disable-line

  function getStore(storeName, callback = () => {}, searchableFields = []) {
    if (!storeName) {
      return;
    }

    let db; // initialize db

    const store = {
      db,
      count,
      clear,
      add,
      addAll,
      searchKey,
      search,
      searchAll,
      get,
      getAll,
      getKeys,
      getValues,
      upsert,
      upsertAll,
      update,
      updateAll,
      remove,
      removeAll,
    }; // store methods

    const request = indexedDB.open(dbName);
    request.onerror = logErrorMessage;
    request.onsuccess = function (e) {
      db = e.target.result;
      if (db.objectStoreNames.contains(storeName)) {
        callback(store);
        return;
      }
      const version = parseInt(db.version);
      db.close();

      const secondRequest = indexedDB.open(dbName, version + 1);
      secondRequest.onerror = logErrorMessage;
      secondRequest.onupgradeneeded = function (e) {
        db = e.target.result;
        const document = db.createObjectStore(storeName, { keyPath: "id" });
        // searchable fields
        searchableFields.forEach((fieldName) => document.createIndex(fieldName, fieldName));
      };
      secondRequest.onsuccess = function (e) {
        callback(store);
        e.target.result.close();
        return;
      };
    };

    function getObjectStore(mode = "readwrite") {
      const transaction = db.transaction([storeName], mode);
      return transaction.objectStore(storeName);
    }

    async function count() {
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestCount = objectStore.count();
        requestCount.onerror = logErrorMessage;
        requestCount.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function clear() {
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestClear = objectStore.clear();
        requestClear.onerror = logErrorMessage;
        requestClear.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function add(record = {}) {
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestAdd = objectStore.add(record);
        requestAdd.onerror = logErrorMessage;
        requestAdd.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function addAll(records = []) {
      return new Promise((resolve) => {
        const transaction = db.transaction([storeName], "readwrite");
        const objectStore = transaction.objectStore(storeName);
        records.forEach((record) => objectStore.add(record));
        transaction.onerror = logErrorMessage;
        transaction.oncomplete = () => resolve(true);
      });
    }

    /**
     * searchKey - search only key of document.
     * search - search for the document.
     * searchAll - search all documents matching [key, value]
     * @param {key} fieldName ["createdAt" | "updatedAt"] field*.
     * @param {value} date value of ["createdAt" | "updatedAt"] field
     */

    async function searchKey(key, value) {
      if (!key) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore().index(key);
        const requestSearchKey = objectStore.getKey(value);
        requestSearchKey.onerror = logErrorMessage;
        requestSearchKey.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function search(key, value) {
      if (!key) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore().index(key);
        const requestSearch = objectStore.get(value);
        requestSearch.onerror = logErrorMessage;
        requestSearch.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function searchAll(key, value) {
      if (!key) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore().index(key);
        const requestSearchAll = objectStore.getAll(value);
        requestSearchAll.onerror = logErrorMessage;
        requestSearchAll.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function get(id) {
      if (!id) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestGet = objectStore.get(id);
        requestGet.onerror = logErrorMessage;
        requestGet.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function getAll() {
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestGetAll = objectStore.getAll();
        requestGetAll.onerror = logErrorMessage;
        requestGetAll.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function getKeys() {
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestGetKeys = objectStore.getAllKeys();
        requestGetKeys.onerror = logErrorMessage;
        requestGetKeys.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function getValues(keyName) {
      if (!keyName) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestGetValues = objectStore.openCursor();
        const result = [];
        requestGetValues.onerror = logErrorMessage;
        requestGetValues.onsuccess = function (e) {
          const cursor = e.target.result;
          if (cursor) {
            result.push(cursor.value[keyName]);
            cursor.continue();
          } else {
            return resolve(result);
          }
        };
      });
    }

    async function upsert(record = {}) {
      if (!record.id) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const requestUpsert = objectStore.put(record);
        requestUpsert.onerror = logErrorMessage;
        requestUpsert.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function upsertAll(records = []) {
      return new Promise((resolve) => {
        const transaction = db.transaction([storeName], "readwrite");
        records.forEach(async (record) => await upsert(record));
        transaction.onerror = logErrorMessage;
        transaction.oncomplete = () => resolve(true);
      });
    }

    async function update(record = {}) {
      if (!record.id) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const document = (async () => await get(record.id))();
        if (!document) {
          return;
        }
        const requestUpdate = objectStore.put({ ...document, ...record });
        requestUpdate.onerror = logErrorMessage;
        requestUpdate.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function updateAll(records = []) {
      return new Promise((resolve) => {
        const transaction = db.transaction([storeName], "readwrite");
        records.forEach(async (record) => await update(record));
        transaction.onerror = logErrorMessage;
        transaction.oncomplete = () => resolve(true);
      });
    }

    async function remove(id) {
      if (!id) {
        return;
      }
      return new Promise((resolve) => {
        const objectStore = getObjectStore();
        const document = (async () => await get(id))();
        if (!document) {
          return;
        }
        const request = objectStore.delete(id);
        request.onerror = logErrorMessage;
        request.onsuccess = (e) => resolve(e.target.result);
      });
    }

    async function removeAll(records = []) {
      return new Promise((resolve) => {
        const transaction = db.transaction([storeName], "readwrite");
        records.forEach(async (record) => await remove(record));
        transaction.onerror = logErrorMessage;
        transaction.oncomplete = () => resolve(true);
      });
    }
  }

  window[dbName] = getStore;

  return getStore;
}
