export default function lookup(object, path) {
  try {
    const findValue = (pathName) => pathName.split(".").reduce((error, key) => error?.[key], object);

    if (["[", "]"].every((sq) => path.includes(sq))) {
      const actualPath = path.replaceAll(/[\[\]']+/g, ""); // eslint-disable-line
      const trimmed = actualPath.replace(/\.$/, "");
      return findValue(trimmed);
    }

    return findValue(path);
  } catch (ex) {
    console.log("Pathname is not valid", ex); // eslint-disable-line no-console
  }
}
