import subscriptionConstants from "../constants/subscriptionTypes";

const INITIAL_STATE = {
  subscriptionLoading: false,
  bundleLoading: false,

  subscriptions: {},
  bundle: {}
};

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case subscriptionConstants.SET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        subscriptionLoading: true
      };
    case subscriptionConstants.RESET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        subscriptionLoading: false
      };
    case subscriptionConstants.FETCH_BOOKINGS_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload
      };
    case subscriptionConstants.RESET_BOOKINGS_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: {}
      };

    case subscriptionConstants.SET_BUNDLE_LOADING:
      return {
        ...state,
        bundleLoading: true
      };
    case subscriptionConstants.RESET_BUNDLE_LOADING:
      return {
        ...state,
        bundleLoading: false
      };
    case subscriptionConstants.FETCH_BOOKINGS_BUNDLE:
      return {
        ...state,
        bundle: action.payload
      };
    case subscriptionConstants.RESET_BOOKINGS_BUNDLE:
      return {
        ...state,
        bundle: {}
      };
    default:
      return state;
  }
};
