import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/caveat";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./App";
import rootReducer from "@store/reducers";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider, useSelector } from "react-redux";
import reduxThunk from "redux-thunk";
import { getFirebase, ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import { firebase } from "@config/firebaseConfig";
import ReduxToastr from "react-redux-toastr";
import { isProduction } from "@constants/environments";
import { initializeIndexedDB } from "@helpers/indexedDB";
import Loader from "@shared/components/Loader";
import { configureAxios } from "./axios";
import { loadAllScripts } from "./scripts";
import Page404 from "./shared/components/Page404";
import { envNames } from "./constants/environments";
import { getTitle } from "./seo";
import { FocusStyleManager } from "@blueprintjs/core";

getTitle();
configureAxios();
initializeIndexedDB();
loadAllScripts();

FocusStyleManager.onlyShowFocusOnTabs();

const composeEnhancers = (!isProduction && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(reduxThunk.withExtraArgument({ getFirebase }))));

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

const rrfProps = {
  firebase: firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Loader loaderType="spinner" transition />;

  return children;
};

const shouldTriggerSentry = [envNames.staging, envNames.production].includes(process.env.REACT_APP_ENV);

if (shouldTriggerSentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<Page404 />}>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ReduxToastr timeOut={4000} newestOnTop preventDuplicates position="bottom-center" transitionIn="fadeIn" transitionOut="fadeOut" />
        <AuthIsLoaded>
          <App />
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
