import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { segmentTrack } from "@helpers/segment";
import spinner from "@assets/spinner.svg";
import threedots from "@assets/three-dots.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: ({ height = "100vh" }) => height,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  rootTransition: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    animation: "$fadein 0.25s ease-in",
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 100,
    },
  },
  bannerImage: {
    backgroundImage: ({ url }) => `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "68px",
    height: "95px",
    [theme.breakpoints.up("md")]: {
      width: "150px",
      height: "107px",
    },
  },
  messageBlock: {
    marginTop: "30px",
    fontSize: 22,
    textAlign: "center",
    lineHeight: "2rem",
    width: "80%",
    margin: "0 auto",
  },
}));

function Loader({ message, loaderType, transition, height }) {
  const getLoader = () => {
    switch (loaderType) {
      case "spinner":
        return spinner;
      case "boxes":
        return spinner;
      case "splash":
        return spinner;
      case "threedots":
        return threedots;
      default:
        return spinner;
    }
  };

  const classes = useStyles({ url: getLoader(), height });

  useEffect(() => {
    const timerID = setTimeout(() => segmentTrack("Loading Stuck", { page: location.href }), 10 * 1000);
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  return (
    <div className={transition ? classes.rootTransition : classes.root}>
      <div className={classes.bannerImage} />
      {Boolean(message) && <div className={classes.messageBlock}>{message}</div>}
    </div>
  );
}

Loader.defaultProps = {
  message: "",
  loaderType: "spinner",
  height: "100vh",
};

Loader.propTypes = {
  message: PropTypes.string,
  loaderType: PropTypes.string,
  transition: PropTypes.bool,
};

export default Loader;
