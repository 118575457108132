import dayjs from "dayjs";
import salesGoalsConstants from "../constants/salesGoalsTypes";
import _cloneDeep from "lodash/cloneDeep";

const INITIAL_STATE = {
  isLoading: false,
  activeAccrual: dayjs().format("YYYYMM"),
  activeGoals: [],
  activeGoalsOriginal: [],
  pipelineMetrics: {},
  edited: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case salesGoalsConstants.SET_SALES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case salesGoalsConstants.RESET_SALES_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case salesGoalsConstants.SAVE_GOAL_EDITS:
      return {
        ...state,
        activeGoals: _cloneDeep(action.payload),
        activeGoalsOriginal: _cloneDeep(action.payload),
      };
    case salesGoalsConstants.SAVE_GOAL_EDITS_ERROR:
      return {
        ...state,
        activeGoals: [],
        error: action.payload,
      };

    case salesGoalsConstants.SET_GOAL_EDITS:
      return {
        ...state,
        activeGoals: _cloneDeep(action.payload),
      };
    case salesGoalsConstants.RESET_GOAL_EDITS:
      return {
        ...state,
        activeGoals: _cloneDeep(state.activeGoalsOriginal),
      };

    case salesGoalsConstants.FETCH_SALES_GOALS:
      return {
        ...state,
        activeGoals: _cloneDeep(action.payload),
        activeGoalsOriginal: _cloneDeep(action.payload),
      };
    case salesGoalsConstants.FETCH_SALES_GOALS_ERROR:
      return {
        ...state,
        activeGoals: [],
        error: action.payload,
      };

    case salesGoalsConstants.UPDATE_ACCRUAL:
      return {
        ...state,
        activeAccrual: action.payload,
      };
    case salesGoalsConstants.EDITS_MADE:
      return {
        ...state,
        edited: true,
      };
    case salesGoalsConstants.CLEAR_EDITS_MADE:
      return {
        ...state,
        edited: false,
      };
    case salesGoalsConstants.FETCH_PIPELINE_METRICS:
      return {
        ...state,
        pipelineMetrics: action.payload,
      };
    case salesGoalsConstants.FETCH_PIPELINE_METRICS_ERROR:
      return {
        ...state,
        pipelineMetrics: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
