import { createTheme } from "@material-ui/core";

const theme = createTheme({
  typography: {
    fontFamily: "Avenir, Nunito Sans, Open Sans, Helvetica, sans-serif",
    button: {
      textTransform: "none",
    },
    h1: {
      color: "#459F65",
    },
    h2: {
      color: "#000",
    },
    h3: {
      fontWeight: 800,
      color: "#000",
    },
    h4: {
      color: "#000",
    },
    subtitle1: {
      fontWeight: 600,
      marginBottom: "16px",
    },
    subtitle2: {
      color: "rgba(0, 0, 0, .54)",
    },
  },
  palette: {
    primary: {
      main: "#1E3B47",
      hover: "#0C232D",
      contrastText: "#fff",
      outline: "#E0E0E0",
    },
    secondary: {
      main: "#fff",
      contrastText: "#43a047",
    },
    background: {
      default: "#fff",
      hover: "#e3e3e3",
    },
    error: {
      main: "#AD0000",
    },
    success: {
      main: "#4caf50",
    },
    warning: {
      main: "#e89f31",
    },
    info: {
      main: "#2196f3",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "10px",
        height: "56px",
        fontWeight: 800,
        fontSize: "1rem",
      },
      // text: {
      //   textDecoration: "underline",
      // },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiPaper: {
      elevation0: {
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
      },
      outlined: {
        borderRadius: "10px",
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiPaper: {
      elevation: 0,
      style: {},
    },
  },
});

export default theme;
