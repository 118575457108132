import { fetchSubscriptionItem } from "../store/actions/subscriptions";
import { TaskType } from "../views/BookingPage/helpers/types";

export const groupSubscriptions = async (subscriptions: any) => {
  const group: any = {};
  for (let i = 0; i < subscriptions.length; i++) {
    const sub = subscriptions[i];
    if (sub.metadata.fbSubscriptionItem) {
      const item = await fetchSubscriptionItem(sub.metadata.fbSubscriptionItem);
      if (item && sub.metadata.key) {
        group[sub.metadata.key as TaskType] = {
          id: sub.id,
          status: sub.status,
          key: sub.metadata.key,
          subscription: sub,
          ...item
        };
      }
    }
  }
  return group;
};

export const extractIdsFromPA = (pa: any[]) => {
  const bookingIds = new Set();
  const subscriptionIds = new Set();
  const invoiceIds = new Set();
  const mappings: any = {};

  pa.forEach((item) => {
    const { metadata } = item;
    if (metadata?.bookingIds?.length) {
      metadata.bookingIds.forEach((id: string) => bookingIds.add(id));
    }
    if (metadata.subscriptionId) subscriptionIds.add(metadata.subscriptionId);
    if (metadata.invoiceId) invoiceIds.add(metadata.invoiceId);

    // build an object that holds a mapping that maps each subscription to its bookings and invoices
    // also maps each invoice to its bookings and subscription
    if (metadata.subscriptionId) {
      if (!mappings[metadata.subscriptionId]) {
        mappings[metadata.subscriptionId] = {
          bookingIds: new Set(),
          invoiceIds: new Set()
        };
      }
      if (metadata.bookingIds?.length) {
        metadata.bookingIds.forEach((id: string) => mappings[metadata.subscriptionId].bookingIds.add(id));
      }
      if (metadata.invoiceId) mappings[metadata.subscriptionId].invoiceIds.add(metadata.invoiceId);
    }
    if (metadata.invoiceId) {
      if (!mappings[metadata.invoiceId]) {
        mappings[metadata.invoiceId] = {
          bookingIds: new Set(),
          subscriptionId: metadata.subscriptionId
        };
      }
      if (metadata.bookingIds?.length) {
        metadata.bookingIds.forEach((id: string) => mappings[metadata.invoiceId].bookingIds.add(id));
      }
    }
  });

  // convert all sets to arrays in mappings
  Object.keys(mappings).forEach((key) => {
    if (mappings[key].bookingIds) mappings[key].bookingIds = Array.from(mappings[key].bookingIds);
    if (mappings[key].invoiceIds) mappings[key].invoiceIds = Array.from(mappings[key].invoiceIds);
  });

  return {
    bookingIds: Array.from(bookingIds),
    invoiceIds: Array.from(invoiceIds),
    subscriptionIds: Array.from(subscriptionIds),
    mappings
  };
};

export const extractBundleBookingIdsFromPA = (pa: any[]) => {
  const bookingIds = new Set();
  const mappings: any = {};

  pa.forEach((item) => {
    bookingIds.add(item.id);

    // build an object that holds a mapping that maps each bundle to its bookings.
    if (item.bundleId) {
      if (!mappings[item.bundleId]) {
        mappings[item.bundleId] = new Set();
      }
      mappings[item.bundleId].add(item.id);
    }
  });

  // convert all sets to arrays in mappings
  Object.keys(mappings).forEach((key) => {
    if (mappings[key]) mappings[key] = Array.from(mappings[key]);
  });

  return {
    bookingIds: Array.from(bookingIds),
    mappings
  };
};
