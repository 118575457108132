import loaderConstants from "@store/constants/loadingTypes";

const INITIAL_STATE = {
  isLoading: false,
  message: "",
  isQuoteLoading: false,
  loaderType: "spinner",
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case loaderConstants.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        message: action.message,
        loaderType: action.loaderType,
      };
    case loaderConstants.SET_QUOTE_LOADING:
      return {
        ...state,
        isQuoteLoading: action.isLoading,
        message: action.message,
      };
    case loaderConstants.RESET_LOADING:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default authReducer;
