import locationConstants from "@store/constants/locationPricingTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  isLoading: true,
  locations: [],
  errorMessage: null,
  urlAddressField: null,
  dynamicLoaded: false,
  opts: {},
  activeLocation: {
    priceMultipliers: 100,
    allMultipliers: {
      ["junk-removal"]: 100,
      ["cardboard-removal"]: 100,
      ["dumpster-rental"]: 100,
    },
  },
};

const locationPricingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case locationConstants.SET_PRICING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case locationConstants.SET_LOCATION_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case locationConstants.RESET_PRICING_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case locationConstants.FETCH_PRICING_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        isLoading: false,
      };
    case locationConstants.FETCH_PRICING_ERROR:
      return {
        ...state,
      };
    case locationConstants.ADD_PRICING_LOCATION:
      return {
        ...state,
        locations: addItem(state.locations, action.payload),
      };
    case locationConstants.UPDATE_PRICING_LOCATION:
      return {
        ...state,
        locations: updateItem(state.locations, action.payload),
      };
    case locationConstants.DELETE_PRICING_LOCATION:
      return {
        ...state,
        locations: deleteItem(state.locations, action.payload),
      };
    case locationConstants.SET_ACTIVE_PRICING: {
      return {
        ...state,
        activeLocation: action.activeLocation,
      };
    }
    case locationConstants.SET_URL_ADDRESS_FIELD: {
      return {
        ...state,
        urlAddressField: action.payload,
      };
    }
    case locationConstants.RESET_ACTIVE_PRICING: {
      return {
        ...state,
        activeLocation: INITIAL_STATE.activeLocation,
        opts: INITIAL_STATE.ops,
        errorMessage: null,
      };
    }
    case locationConstants.SET_LOCATION_OPTS: {
      return {
        ...state,
        opts: action.payload,
        dynamicLoaded: true,
      };
    }
    default:
      return state;
  }
};

export default locationPricingReducer;
