/* eslint-disable */
import reviewsConstants from "@store/constants/reviewTypes";

const INITIAL_STATE = {
  reviews: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case reviewsConstants.SET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    default:
      return state;
  }
};
