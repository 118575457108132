import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useAxiosPost } from "../../axios";
import { urlTaskMapping } from "../../constants/booking";
import { segmentTrack } from "../../helpers/segment";
import { resetAddress, setAddress, setDumpsterBasePricing, setDumpsterMarketPricing } from "../../store/actions/address";
import { resetActivePricing, setActivePricing, setLocationOpts, setUrlAddressField } from "../../store/actions/locations";
import { defaultValues } from "../../views/BookingPage/helpers/constants";
import { Input } from "../../views/BookingPage/helpers/enums";
import locationConstants from "../../store/constants/locationPricingTypes";
import tempDataConstants from "../../store/constants/tempDataTypes";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { coreKeywords, keywordHeadingsAndPitches } from "../../views/DisposalPage/constants";
import { KeywordActions } from "../../store/constants/keywordTypes";
import { useHistory } from "react-router-dom";


export const useDynamicParameters = (dispatch: any) => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:768px)");
  const [locationId] = useQueryParam("location", StringParam);
  const [market] = useQueryParam("market", StringParam);
  const [dynamic] = useQueryParam("dynamic", StringParam);
  const [placeId] = useQueryParam("place", StringParam);
  const [keyword] = useQueryParam("keyword", StringParam);
  const regexPath: any = location.pathname.match(/(cardboard_removal|junk_removal|dumpster_rental)/g);
  const mapping: any = urlTaskMapping;
  const urlTask = mapping[regexPath];
  const defaultTask = urlTask ?? defaultValues[Input.Task];
  const dynamicException = market?.includes("St_") ? market.replace("St_", "St._") : market;
  const dynamicAddress = dynamicException
    ?.trim()
    .replaceAll(/[^a-zA-Z. ]/g, " ")
    .trim();

  const trackDynamic = () => {
    if (!locationId && !market && !dynamic && !placeId) return;
    segmentTrack("Dynamic Location Insert", {
      isMobile,
      market,
      locationId,
      dynamic,
      dynamicException,
      dynamicAddress,
      defaultTask,
      placeId,
    });
  };

  const fetchMatchingLocation = async () => {
    if (!dynamicAddress && !locationId && !placeId) return;

    let payload = {
      task: defaultTask,
      market: dynamicAddress ?? "",
      locationId: locationId ?? "",
    };

    if (placeId) {
      const place = await geocodeByPlaceId(placeId);
      const address = place?.[0]?.formatted_address;
      payload.market = address;
    }

    const response = await useAxiosPost("/geotarget/dynamic-insert", payload);
    const data = response?.data;
    if (!data?.valid) {
      segmentTrack("Location Not Served", {
        location: "",
        dynamicA: dynamicAddress ?? "",
        locationId: locationId ?? "",
        url: location.pathname + location.search,
        from: "Dynamic Insert",
        isRedirect: !!placeId,
      });
      dispatch(resetAddress());
      dispatch(resetActivePricing());
      dispatch({
        type: locationConstants.SET_LOCATION_ERROR,
        payload: "We don't currently serve this location",
      });
      dispatch({ type: tempDataConstants.RESET_DUMPSTER_MARKET_PRICING });
      dispatch({ type: tempDataConstants.RESET_DUMPSTER_BASE_PRICING });
      dispatch(setUrlAddressField(data?.address));
    } else {
      segmentTrack("Location Served", {
        location: data.address?.location ?? "",
        dynamicA: dynamicAddress ?? "",
        locationId: locationId ?? "",
        url: location.pathname + location.search,
        from: "Dynamic Insert",
        ...(data.opts ?? {}),
        isRedirect: !!placeId,
      });
      if (data.opts?.isNonMarket) {
        segmentTrack("Location Non Market Applied", {
          location: data.address?.location ?? "",
          dynamicA: dynamicAddress ?? "",
          locationId: locationId ?? "",
          url: location.pathname + location.search,
          from: "Dynamic Insert",
          ...(data.opts ?? {}),
          isRedirect: !!placeId,
        });
      } else {
        segmentTrack("Location Market Applied", {
          location: data.address?.location ?? "",
          dynamicA: dynamicAddress ?? "",
          locationId: locationId ?? "",
          url: location.pathname + location.search,
          from: "Dynamic Insert",
          ...(data.opts ?? {}),
          isRedirect: !!placeId,
        });
      }
      const dumpsterPrices = data?.dumpsterPrices;
      const dumpsterBasePrices = data?.dumpsterBasePrices;
      dispatch(setDumpsterMarketPricing(dumpsterPrices));
      dispatch(setDumpsterBasePricing(dumpsterBasePrices));
      dispatch(setAddress(data?.address));
      dispatch(setLocationOpts(data?.opts));
      dispatch(setActivePricing(data?.pricing));
      dispatch(setUrlAddressField(data?.address));
    }
  };

  const checkKeywordMatch = async (keywordOverride?: string) => {
    const response = await useAxiosPost(`/keywords/view`, { keyword: keywordOverride ?? keyword, path: location.pathname.split("/")?.[1]?.toLowerCase()?.trim() ?? "/" });

    if (!response?.data?.keyword) {
      // will bne deprecated
      const coreKeyword = coreKeywords.find((kw) => {
        if (kw.includes("+")) {
          const allMatches = kw.split("+").every((k) => keyword?.toLowerCase()?.includes(k));
          return allMatches;
        }
        return keyword?.toLowerCase()?.includes(kw);
      });
      if (!coreKeyword) {
        dispatch({
          type: KeywordActions.SET_LANDING_KEYWORD,
          payload: null,
        });
        return;
      };
      sessionStorage.setItem("tw-keyword-core", coreKeyword);

      const coreStructure = keywordHeadingsAndPitches[coreKeyword];

      if (coreStructure) {
        dispatch({
          type: KeywordActions.SET_LANDING_KEYWORD,
          payload: {
            ...coreStructure,
            original: keyword,
            key: coreKeyword,
          }
        });
      }
    } else {
      sessionStorage.setItem("tw-keyword-core", response?.data?.keyword?.key);
      dispatch({
        type: KeywordActions.SET_LANDING_KEYWORD,
        payload: response?.data?.keyword,
      });
    }
  };

  // add a history listener to check for changes in the keyword
  useEffect(() => {
    return history.listen(() => {
      const urlKeyword = new URLSearchParams(history.location.search).get("keyword");
      if (urlKeyword && urlKeyword !== keyword) {
        checkKeywordMatch(urlKeyword);
      };
    });
  }, []);

  useEffect(() => {
    trackDynamic();
    fetchMatchingLocation();
  }, []);

  useEffect(() => {
    if (!keyword) {
      dispatch({
        type: KeywordActions.SET_LANDING_KEYWORD,
        payload: null,
      });
      return;
    };
    sessionStorage.setItem("tw-keyword-full", keyword);
    // check for a matching keyword
    checkKeywordMatch();
  }, []);
};

export const getFullKeyword = () => {
  const currentKeyword = sessionStorage.getItem("tw-keyword-full");
  return currentKeyword ?? null;
};

export const getCoreKeyword = () => {
  const currentKeyword = sessionStorage.getItem("tw-keyword-core");
  return currentKeyword ?? null;
};
