import axios from "axios";
import { getAppCheckToken } from "./helpers/appCheck";

const handleSuccessResponse = (response) => {
  return response;
};

const handleErrorResponse = (error) => {
  return Promise.reject(error);
};

const registerResponseIntercept = () => {
  axios.interceptors.response.use(handleSuccessResponse, (error) => handleErrorResponse(error));
};

export const getAxiosAppCheckHeaders = async () => {
  const appCheckToken = await getAppCheckToken();
  return appCheckToken ? { "X-Firebase-AppCheck": appCheckToken } : null;
};

export const useAxiosGet = async (uri, customHeaders) => {
  let headers = await getAxiosAppCheckHeaders();
  const defaultHeaders = axios.defaults.headers;
  if (customHeaders) {
    headers = {
      ...defaultHeaders,
      ...customHeaders,
      ...headers,
    };
  }
  try {
    const response = await axios.get(uri, { headers: headers });
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const useAxiosPost = async (uri, form, customHeaders) => {
  let headers = await getAxiosAppCheckHeaders();
  const defaultHeaders = axios.defaults.headers;
  if (customHeaders) {
    headers = {
      ...defaultHeaders,
      ...customHeaders,
      ...headers,
    };
  }
  try {
    const response = await axios.post(uri, form, { headers: headers });
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const configureAxios = async () => {
  axios.defaults.baseURL = "/api";
  axios.defaults.headers = {
    Accept: "applicaion/json",
    "Content-Type": "application/json",
  };
  registerResponseIntercept();
};

// Applies the logged in users email and uid to axios requests
export const configureAxiosAuth = (uid) => {
  if (!uid) {
    axios.defaults.headers = {
      Accept: "applicaion/json",
      "Content-Type": "application/json",
    };
  } else {
    axios.defaults.headers = {
      ...axios.defaults.headers,
      "X-UID": uid,
    };
  }
};
