import couponTypes from "@store/constants/couponTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  coupons: [],
  activeCoupon: null,
  selectedCoupon: null,
  isLoading: false,
};

const couponReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case couponTypes.FETCH_COUPONS:
      return {
        ...state,
        coupons: [...action.payload],
      };
    case couponTypes.CREATE_COUPON:
      return {
        ...state,
        newCoupon: { ...action.payload },
      };
    case couponTypes.CREATE_COUPON_ERROR:
      return {
        ...state,
        newCoupon: { ...action.payload },
      };
    case couponTypes.SET_ACTIVE_COUPON:
      return {
        ...state,
        activeCoupon: { ...action.payload },
      };
    case couponTypes.ADD_COUPON:
      return {
        ...state,
        coupons: addItem(state.coupons, action.payload),
      };
    case couponTypes.UPDATE_COUPON: {
      return {
        ...state,
        coupons: updateItem(state.coupons, action.payload),
      };
    }
    case couponTypes.DELETE_COUPON:
      return {
        ...state,
        coupons: deleteItem(state.coupons, action.payload),
      };
    case couponTypes.SET_SELECTED_COUPON:
      return {
        ...state,
        selectedCoupon: action.payload,
      };
    case couponTypes.RESET_SELECTED_COUPON:
      return {
        ...state,
        selectedCoupon: null,
      };
    case couponTypes.SET_COUPONS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case couponTypes.RESET_COUPONS_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default couponReducer;
