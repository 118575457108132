/* eslint-disable */
const INITIAL_STATE = {
  thread: [],
  faqId: null,
  open: false,
};

const faqReducer = (state = INITIAL_STATE, action: { type: string, payload: any }) => {
  switch (action.type) {
    case "SET_FAQ":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_FAQ":
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default faqReducer;
