const getTasksAnalyticsTypes = {
  SET_GET_TASKS_ANALYTICS: "SET_GET_TASKS_ANALYTICS",
  RESET_GET_TASKS_ANALYTICS: "RESET_GET_TASKS_ANALYTICS",
  SET_TOTAL_TASKS: "SET_TOTAL_TASKS",
  SET_INITIAL_VISIBILITY: "SET_INITIAL_VISIBILITY",
  IS_MOBILE_VIEW: "IS_MOBILE_VIEW",
  VIEWED_TASKS: "VIEWED_TASKS",
  TIME_ON_PAGE: "TIME_ON_PAGE",
};

export { getTasksAnalyticsTypes };
