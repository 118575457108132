import * as yup from "yup";
import dayjs from "../constants/dayjs";
import { DaysOfWeek, defaultFrequencyRepeatMode, frequencyRepeatModes, MonthsOfQuarter, WeeksOfMonth } from "../constants/recurring";
import { adminRecurringFrequency } from "../views/BookingPage/helpers/enums";
import { isValidPhoneNumber } from "react-phone-number-input";
import { formatPhoneNumber } from "../helpers/booking";

const WEEK_IN_A_MONTH = 4.34524; // average weeks in a month

export const legacyArrayToObject = (value: any) => {
  if (!value) return [];

  // if value is array of numbers, convert to array of objects - legacy support
  if (value && Array.isArray(value) && typeof value[0] === "number") {
    value = [{ days: value }];
  }

  return value;
};

export const recurringFormDefaults = (booking: any) => ({
  fullName: booking?.first_name?.trim() + " " + booking?.last_name?.trim(),
  email: booking?.email ?? "",
  phone_number: formatPhoneNumber(booking?.phone_number) ?? "",
  address: booking?.address ?? {},
  first_name: booking?.first_name ?? "",
  last_name: booking?.last_name ?? "",
  customerPrice: booking?.total ? booking.total / 100 : "",
  payment_method: booking?.payment_method || "ACH",
  payment_method_id: booking?.payment_method_id || booking?.payment_method || "ACH",
  warrior_id: booking?.warrior_id ?? "",
  internalNotes: booking?.internalNotes ?? "",
  payout: booking?.payout ? booking.payout / 100 : "",
  date: dayjs(booking.date) || dayjs().hour(0).minute(0),
  time: booking?.time,
  frequency: booking?.frequency,
  frequencyRepeat: booking?.frequencyRepeat ?? 1,
  frequencyPattern: legacyArrayToObject(booking?.frequencyPattern),
  frequencyRepeatMode: booking?.frequencyRepeatMode ?? defaultFrequencyRepeatMode,
  vehicle: booking?.vehicle,
  includeInvoice: booking?.includeInvoice ?? false,
  assignedDumpsiteId: booking?.assignedDumpsiteId,
  businessClientDetails: booking?.businessClientDetails ?? null,
  businessClient: booking?.businessClient ?? false,
  customer_id: booking?.customer_id,
  companyName: booking?.companyName,
  closedBy: booking?.businessClientDetails?.closedBy ?? "",
  sales_type: booking?.sales_type ?? "",
  accountManager: booking?.accountManager ?? "",
  description: booking?.description ?? "",
});

export const recurringFormSchema = yup.object().shape({
  accountManager: yup.string().optional(),
  email: yup.string().email().required("Email is required!"),
  phone_number: yup
    .string()
    .required("Phone Number is required")
    .test("phone-validation", "Invalid format!", (value) => {
      if (!value) return false;
      return isValidPhoneNumber(value);
    }),
  address: yup.object().shape({
    location: yup.string().required("Invalid Location !"),
    coordinates: yup.object().required("Cannot find coordinates"),
    placeId: yup.string().required("Invalid Location"),
    zipCode: yup.string().optional(),
    city: yup.string().optional(),
  }),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  customerPrice: yup.number().required("Cutomer price is required"),
  payment_method: yup.string().optional(),
  payment_method_id: yup.string().required(),
  warrior_id: yup.string().optional(),
  internalNotes: yup.string().optional(),
  description: yup.string().optional(),
  payout: yup.number().required("Warrior payout is required"),
  date: yup.date().required(),
  time: yup.object().required(),
  frequency: yup.string().required(),
  frequencyPattern: yup.array().of(yup.object().shape({
    days: yup.array().of(yup.number()).optional(),
    week: yup.number().optional(),
    month: yup.number().optional(),
  })).required(),
  frequencyRepeat: yup.number().optional(),
  frequencyRepeatMode: yup.string().optional(),
  vehicle: yup.string().optional(),
  includeInvoice: yup.boolean().required(),
  assignedDumpsiteId: yup.string().optional(),

  customer_id: yup.string().optional().nullable(),
  businessClient: yup.bool().required(),
  businessClientDetails: yup
    .object()
    .when("businessClient", {
      is: (businessClient: any) => businessClient,
      then: yup.object().shape({
        name: yup.string().required("Company Name is required"),
        id: yup.string().optional(),
        closedBy: yup.string().required(),
      }),
    })
    .nullable(),
  closedBy: yup.string().when("businessClient", {
    is: (businessClient: any) => businessClient,
    then: yup.string().required("Closed by is required"),
  }),
});

const getWeekLabel = (week: number) => DaysOfWeek[week]?.label || "";

const getWeekNumberText = (week: number) => {
  // convert WeeksOfMonth as value: label lookup
  const weekText = WeeksOfMonth.find((w) => w.value === week)?.label;
  return weekText || "";
};

const getMonthNumberText = (month: number) => {
  // convert MonthsOfQuarter as value: label lookup
  const monthText = MonthsOfQuarter.find((m) => m.value === month)?.label;
  return monthText || "";
};

const translateWeeklyRecurrence = ({ frequencyPattern = [], frequencyRepeat = 1 }: any) => {
  let days = [];

  // frequencyPattern = [{ days: [0, 1, 2, 3, 4, 5, 6] }]
  //weekly only has frequencyRepeatMode on_days => Sun, Mon,..., Sat => 0 to 6

  const patternDays = frequencyPattern[0]?.days || [];

  for (const day of patternDays) {
    if (day >= 0 && day < 7) {
      days.push(getWeekLabel(day));
    }
  }

  const daysText = days.length > 1 ? `${days.slice(0, -1).join(", ")} and ${days[days.length - 1]}` : days.join("");
  const frequencyText = frequencyRepeat > 1 ? `${frequencyRepeat} weeks` : "week";

  const retStr = `Repeats every ${frequencyText}${days.length ? " on " + daysText : ""}.`;
  return retStr;
};

const translateMonthlyRecurrence = ({ frequencyPattern = [], frequencyRepeat = 1, frequencyRepeatMode = defaultFrequencyRepeatMode }: any) => {
  let days = [];

  // if number is 0 or above 31 ignore, but allow -1
  if (frequencyRepeatMode === frequencyRepeatModes.on_dates.value) {
    // frequencyPattern = [{ days: [1, 2 ... 31, -1] }]

    const patternDays = frequencyPattern[0]?.days || [];
    const filteredPattern = patternDays.filter((day: number) => day === -1 || (day > 0 && day < 32));
    days = filteredPattern.map((day: number) => {
      if (day === -1) return "last day of the month";
      const suffix = day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";
      return `${day}${suffix}`;
    });
  }

  if (frequencyRepeatMode === frequencyRepeatModes.on_days.value) {
    // frequencyPattern = [{ week: 1, days: [0, 5, 6] }, { week: 2, days: [3] }, ...]
    const patternWeeks = frequencyPattern || [];
    patternWeeks.forEach((pattern: any) => {
      const { week, days: weekDays = [] } = pattern;
      if (!week) return;
      const weekText = getWeekNumberText(week);
      // only allow days 0 to 6 for weekDays
      const weekDaysText = weekDays.filter((day: number) => day > -1 && day < 7).map((day: number) => getWeekLabel(day));
      const weekDaysTextFormatted = weekDaysText.length > 1 ? `${weekDaysText.slice(0, -1).join(", ")} and ${weekDaysText[weekDaysText.length - 1]}` : weekDaysText.join("");
      days.push(`${weekText} ${weekDaysTextFormatted}`);
    });
  }

  const daysText = days.length > 1 ? `${days.slice(0, -1).join(", ")} and ${days[days.length - 1]}` : days.join("");
  const frequencyText = frequencyRepeat > 1 ? `${frequencyRepeat} months` : "month";

  const retStr = `Repeats every ${frequencyText}${days.length ? " on " + daysText : ""}.`;
  return retStr;
};

const translateQuarterlyRecurrence = ({ frequencyPattern = [], frequencyRepeat = 1, frequencyRepeatMode = defaultFrequencyRepeatMode }: any) => {
  let days: any[] = [];

  if (frequencyRepeatMode === frequencyRepeatModes.on_dates.value) {
    return `Repeats every ${frequencyRepeat} quarters on the same day.`;
  }

  if (frequencyRepeatMode === frequencyRepeatModes.on_days.value) {
    // frequencyPattern = [{ month: 1, week: 1, days: [0, 5, 6] }, { month:2, week: 2, days: [3] }, ...]
    frequencyPattern.forEach((quarterData: any) => {
      const { month, week, days: weekDays = [] } = quarterData;
      if (!month || !week) return;
      const monthText = getMonthNumberText(month);
      const weekText = getWeekNumberText(week);
      // only allow days 0 to 6 for weekDays
      const weekDaysText = weekDays.filter((day: number) => day > -1 && day < 7).map((day: number) => getWeekLabel(day));
      const weekDaysTextFormatted = weekDaysText.length > 1 ? `${weekDaysText.slice(0, -1).join(", ")} and ${weekDaysText[weekDaysText.length - 1]}` : weekDaysText.join("");
      days.push(`${monthText} month - ${weekText} ${weekDaysTextFormatted}`);
    });
  }

  const frequencyText = frequencyRepeat > 1 ? `${frequencyRepeat} quarters` : "quarter";
  const daysText = days.length > 1 ? `${days.slice(0, -1).join(", ")} and ${days[days.length - 1]}` : days.join("");

  const retStr = `Repeats every ${frequencyText}${days.length ? " on " + daysText : ""}.`;
  return retStr;
};

export const translateRecurrence = ({ frequency, frequencyPattern = [], frequencyRepeat = 1, frequencyRepeatMode = defaultFrequencyRepeatMode }: any) => {
  // leagcy support for frequencyPattern
  if (Array.isArray(frequencyPattern) && typeof frequencyPattern[0] === "number") {
    frequencyPattern = frequencyPattern.map((days) => ({ days }));
  }

  // sort by month, week, day
  const sortedPattern = frequencyPattern.sort((a: any, b: any) => {
    if (a.month !== b.month) {
      return a.month - b.month;
    } else if (a.week !== b.week) {
      if (a.week === -1) return 1;
      return a.week - b.week;
    } else {
      return a.days?.[0] - b.days?.[0];
    }
  });

  sortedPattern.forEach((obj: any) => {
    obj?.days?.sort((a: any, b: any) => a - b);
    if (obj?.days?.includes(-1)) {
      const index = obj?.days?.indexOf(-1);
      obj?.days?.splice(index, 1);
      obj?.days?.push(-1);
    }
  });

  const ignored = [
    adminRecurringFrequency.Once,
  ];

  if (ignored.includes(frequency)) return "";

  // structure of frequencyPattern => [{ days: [1, 2, 3] ,week: 1, month: 1 }, { days: [1, 2, 3] }, ...]
  let translatedRecurrence: any;
  switch (frequency) {
    case adminRecurringFrequency.Weekly:
      translatedRecurrence = translateWeeklyRecurrence;
      break;
    case adminRecurringFrequency.Monthly:
      translatedRecurrence = translateMonthlyRecurrence;
      break;
    case adminRecurringFrequency.Quarterly:
      translatedRecurrence = translateQuarterlyRecurrence;
      break;
    case adminRecurringFrequency.Biweekly:
      return `Repeats every 2 weeks.`;
    case adminRecurringFrequency.Bimonthly:
      return `Repeats every 2 months.`;
    case adminRecurringFrequency.SemiAnnually:
      return `Repeats every 6 months.`;
    default:
      return `Not supported for ${frequency} yet.`;
  }

  return translatedRecurrence({ frequencyPattern: sortedPattern, frequencyRepeat, frequencyRepeatMode });
};

const copyDateWithTime = (baseDate: any, targetDate: any) => {
  return targetDate.hour(baseDate.hour())
    .minute(baseDate.minute())
    .second(baseDate.second())
    .millisecond(baseDate.millisecond());
};

const calculateNthWeekdayOfMonth = (startOfMonth: any, week: number, day: number) => {
  let firstDayOfWeekInMonth = startOfMonth.day(day);

  // Adjust if the day is before the start of the month
  if (firstDayOfWeekInMonth.isBefore(startOfMonth, 'day')) {
    firstDayOfWeekInMonth = firstDayOfWeekInMonth.add(7, 'days');
  }

  return firstDayOfWeekInMonth.add((week - 1) * 7, 'days');
};

const calculateLastWeekdayOfMonth = (startOfMonth: any, day: number) => {
  const lastDayOfMonth = startOfMonth.endOf('month');
  const dayDiff = (lastDayOfMonth.day() - day + 7) % 7;
  return lastDayOfMonth.subtract(dayDiff, 'days');
};

const findNextWeeklyDates = (args: any) => {
  const dates: any[] = [];
  let { n, date, frequencyPattern, frequencyRepeat } = args;
  let newDate: any = date;
  const days = frequencyPattern?.[0]?.days || [];
  let loopCount = 0;

  while (dates.length < n) {
    loopCount++;
    if (loopCount > 100) {
      // eslint-disable-next-line no-console
      console.error("Exceeded loop count for weekly dates");
      break;
    }

    // if frequencyPattern is empty, add frequencyRepeat weeks
    if (!frequencyPattern.length || !days.length) {
      if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
        dates.push(copyDateWithTime(date, newDate));
      }
    }

    for (const day of days) {
      let weekDate = newDate.day(day);
      if (!weekDate.isSame(date, 'day') && weekDate.isAfter(date)) {
        dates.push(copyDateWithTime(date, weekDate));
      }
    }

    newDate = newDate.add(frequencyRepeat, 'week');
    dates.sort((a, b) => a - b);
  }

  dates.sort((a, b) => a - b);
  return dates;
};

const findNextMonthlyDates = (args: any) => {
  const dates: any[] = [];
  let { n, date, frequencyPattern, frequencyRepeat, frequencyRepeatMode } = args;
  let newDate: any = date;
  let loopCount = 0;

  while (dates.length < n) {
    loopCount++;
    if (loopCount > 100) {
      // eslint-disable-next-line no-console
      console.error("Exceeded loop count for monthly dates");
      break;
    }

    if (!frequencyPattern.length) {
      if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
        dates.push(copyDateWithTime(date, newDate));
      }
    }

    for (const pattern of frequencyPattern) {
      const { week, days = [] } = pattern;

      if (!days.length) {
        if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
          dates.push(copyDateWithTime(date, newDate));
          break;
        }
      }

      if (frequencyRepeatMode === frequencyRepeatModes.on_dates.value) {
        for (const day of days) {
          if (day === -1) {
            // check if last day of the current month is after the current date, if not, add frequencyRepeat months
            let nextDate = newDate.endOf('month');

            if (!nextDate.isSame(date, 'day') && nextDate.isAfter(date)) {
              dates.push(copyDateWithTime(date, nextDate));
            }
          }
          if (day >= 1 && day <= 31) {
            // check if the day is after the current date, if not, add frequencyRepeat months
            let nextDate = newDate;
            const daysInMonth = nextDate.daysInMonth();
            const pushToEOM = day > daysInMonth;

            // if the dates like 29, 30, 31 are valid for the current month, if not just take end of the month
            if (pushToEOM) {
              nextDate = nextDate.endOf("month");
            } else {
              nextDate = newDate.date(day);
            }

            // check if the day is after the current date, if not, add frequencyRepeat months
            if (!nextDate.isSame(date, 'day') && nextDate.isAfter(date)) {
              dates.push(copyDateWithTime(date, nextDate));
            }
          }

          dates.sort((a, b) => a - b);
        }
      }

      // find next dates based on week number and exact days, week number is 1 to 4, -1 for last week, days[] is 0 to 6
      if (frequencyRepeatMode === frequencyRepeatModes.on_days.value) {
        const startOfMonth = newDate.startOf('month');

        // if week in undefined, add frequencyRepeat months
        if (!week && !newDate.isSame(date, 'day') && newDate.isAfter(date)) {
          dates.push(copyDateWithTime(date, newDate));
          break;
        }

        // filter out days < 0 and > 6 - invalid for on_days
        const validDays = days.filter((day: number) => day >= 0 && day <= 6);
        if (!validDays.length && !newDate.isSame(date, 'day') && newDate.isAfter(date)) {
          dates.push(copyDateWithTime(date, newDate));
          break;
        }

        for (const day of validDays) {
          let targetDate;
          if (week === -1) {
            targetDate = calculateLastWeekdayOfMonth(startOfMonth, day);
          } else {
            targetDate = calculateNthWeekdayOfMonth(startOfMonth, week, day);
          }

          if (targetDate.isAfter(date) && !targetDate.isSame(date, 'day')) {
            dates.push(copyDateWithTime(date, targetDate));
          }

          dates.sort((a, b) => a - b);
        }
      }

      dates.sort((a, b) => a - b);
    };

    newDate = newDate.add(frequencyRepeat, 'month');
    dates.sort((a, b) => a - b);
  }

  // sort the dates and return the first one after the current date
  dates.sort((a, b) => a - b);
  return dates;
};

const findNextQuarterlyDates = (args: any) => {
  const dates: any[] = [];
  let { n, date, frequencyPattern, frequencyRepeat } = args;
  let newDate: any = date;
  let loopCount = 0;

  while (dates.length < n) {
    loopCount++;
    if (loopCount > 100) {
      // eslint-disable-next-line no-console
      console.error("Exceeded loop count for quarterly dates");
      break;
    }

    const patternLength = frequencyPattern.length;
    if (!patternLength) {
      if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
        dates.push(copyDateWithTime(date, newDate));
      }
    }

    const hasOneValidPattern = frequencyPattern?.some((pattern: any) => {
      return pattern.month && pattern.week && pattern.days?.length;
    });

    const validPatternIndices = frequencyPattern?.map((pattern: any, index: number) => {
      return pattern.month && pattern.week && pattern.days?.length ? index : -1;
    });

    for (const [index, pattern] of frequencyPattern.entries()) {
      const { month: monthInQuarter, week, days = [] } = pattern;

      // if no valid pattern, push and break;
      if (!hasOneValidPattern) {
        if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
          dates.push(copyDateWithTime(date, newDate));
        }
        break;
      }

      // if this is an invalid pattern and has at least one valid pattern, skip
      if (validPatternIndices[index] === -1 && hasOneValidPattern) continue;

      // if this is an invalid pattern and has no valid pattern, push and break
      if (validPatternIndices[index] === -1 && !hasOneValidPattern) {
        if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
          dates.push(copyDateWithTime(date, newDate));
        }
        break;
      }

      // Calculate the quarter of the given date and adjust to the specific month in the quarter
      let currentQuarter = Math.floor((newDate.month() / 3));
      let targetMonth = (currentQuarter * 3) + (monthInQuarter - 1);

      // Adjust the year and month if targetMonth exceeds the current year
      let year = newDate.year();
      if (targetMonth >= 12) {
        targetMonth -= 12;
        year += 1;
      }

      // Calculate the start date of the target month
      let startOfMonth = newDate.month(targetMonth).year(year).startOf('month');

      for (const day of days) {
        let targetDate;
        if (week === -1) {
          targetDate = calculateLastWeekdayOfMonth(startOfMonth, day);
        } else {
          targetDate = calculateNthWeekdayOfMonth(startOfMonth, week, day);
        }
        if (targetDate.isAfter(date) && !targetDate.isSame(date, 'day')) {
          dates.push(copyDateWithTime(date, targetDate));
        }
      };
    };

    newDate = newDate.add(frequencyRepeat * 3, 'month');
    dates.sort((a, b) => a - b);
  }

  // sort the dates and return the first one after the current date
  dates.sort((a, b) => a - b);
  return dates;
};

const findNextBiweeklyDates = (args: any) => {
  const dates: any[] = [];
  const { n, date, frequencyRepeat } = args;
  let newDate: any = date;
  let loopCount = 0;

  while (dates.length < n) {
    loopCount++;
    if (loopCount > 100) {
      // eslint-disable-next-line no-console
      console.error("Exceeded loop count for biweekly dates");
      break;
    }

    if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
      dates.push(copyDateWithTime(date, newDate));
    }
    newDate = newDate.add(frequencyRepeat * 2, 'week');
  }

  dates.sort((a, b) => a - b);
  return dates;
};

const findNextBimonthlyDates = (args: any) => {
  const dates: any[] = [];
  const { n, date, frequencyRepeat } = args;
  let newDate: any = date;
  let loopCount = 0;

  while (dates.length < n) {
    loopCount++;
    if (loopCount > 100) {
      // eslint-disable-next-line no-console
      console.error("Exceeded loop count for bimonthly dates");
      break;
    }

    if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
      dates.push(copyDateWithTime(date, newDate));
    }
    newDate = newDate.add(frequencyRepeat * 2, 'month');
  }

  dates.sort((a, b) => a - b);
  return dates;
};

const findNextSemiAnnuallyDates = (args: any) => {
  const dates: any[] = [];
  const { n, date, frequencyRepeat } = args;
  let newDate: any = date;
  let loopCount = 0;

  while (dates.length < n) {
    loopCount++;
    if (loopCount > 100) {
      // eslint-disable-next-line no-console
      console.error("Exceeded loop count for semi-annually dates");
      break;
    }

    if (!newDate.isSame(date, 'day') && newDate.isAfter(date)) {
      dates.push(copyDateWithTime(date, newDate));
    }
    newDate = newDate.add(frequencyRepeat * 6, 'month');
  }

  dates.sort((a, b) => a - b);
  return dates;
};

type FrequencyPattern = {
  month?: number;
  week?: number;
  days: number[];
};

type GetNextNDatesArgs = {
  n?: number;
  date: any;
  frequency: string;
  frequencyPattern: FrequencyPattern[];
  frequencyRepeat?: number;
  frequencyRepeatMode?: string;
};

/* Core function to get the next date based on the frequency, pattern and repeat */
export const getNextNDates = (args: GetNextNDatesArgs) => {
  const { n = 1, date, frequency, frequencyPattern = [], frequencyRepeat = 1, frequencyRepeatMode = defaultFrequencyRepeatMode } = args;
  let dates: any[] = [];
  let currentDate = dayjs(date);

  // sort by month, week, day
  const sortedPattern = frequencyPattern.sort((a: any, b: any) => {
    if (a.month !== b.month) {
      return a.month - b.month;
    } else if (a.week !== b.week) {
      if (a.week === -1) return 1;
      return a.week - b.week;
    } else {
      return a.days?.[0] - b.days?.[0];
    }
  });

  sortedPattern.forEach((obj: any) => {
    obj?.days?.sort((a: any, b: any) => a - b);
    if (obj?.days?.includes(-1)) {
      const index = obj?.days?.indexOf(-1);
      obj?.days?.splice(index, 1);
      obj?.days?.push(-1);
    }
  });

  let getNextDates = null; // function to call

  switch (frequency) {
    case adminRecurringFrequency.Weekly:
      getNextDates = findNextWeeklyDates;
      break;
    case adminRecurringFrequency.Monthly:
      getNextDates = findNextMonthlyDates;
      break;
    case adminRecurringFrequency.Quarterly:
      getNextDates = findNextQuarterlyDates;
      break;
    case adminRecurringFrequency.Biweekly:
      getNextDates = findNextBiweeklyDates;
      break;
    case adminRecurringFrequency.Bimonthly:
      getNextDates = findNextBimonthlyDates;
      break;
    case adminRecurringFrequency.SemiAnnually:
      getNextDates = findNextSemiAnnuallyDates;
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(`Frequency ${frequency} is not supported yet.`);
      break;
  }

  if (!getNextDates) return [];

  const nextDates = getNextDates({
    n,
    date: currentDate,
    frequency,
    frequencyPattern: sortedPattern,
    frequencyRepeat: frequencyRepeat || 1,
    frequencyRepeatMode
  });

  if (!nextDates) return [];

  dates = nextDates.sort((a, b) => a - b)
    .filter((value, index, self) => self.findIndex((d) => dayjs(d).isSame(value, 'day')) === index)
    .slice(0, n);

  return dates;
};

const findWeeklyBookingsPerMonth = (args: any) => {
  const { frequencyPattern = [], frequencyRepeat = 1 } = args;
  const days = frequencyPattern?.[0]?.days || [];

  let daysUsed = days.length || 1;
  daysUsed = daysUsed * WEEK_IN_A_MONTH;

  return daysUsed / frequencyRepeat;
};

const findMonthlyBookingsPerMonth = (args: any) => {
  const { frequencyPattern = [], frequencyRepeat = 1, frequencyRepeatMode = defaultFrequencyRepeatMode } = args;
  let daysUsed = 0;

  if (!frequencyPattern.length) {
    daysUsed = 1;
  }

  if (frequencyRepeatMode === frequencyRepeatModes.on_dates.value) {
    const patternDays = frequencyPattern[0]?.days || [];
    const filteredPattern = patternDays.filter((day: number) => day === -1 || (day > 0 && day < 29));
    daysUsed = filteredPattern.length || 1;
  }

  if (frequencyRepeatMode === frequencyRepeatModes.on_days.value) {
    const patternWeeks = frequencyPattern || [];
    for (const pattern of patternWeeks) {
      const { week, days = [] } = pattern;

      if (!days.length || !week) continue;

      daysUsed += days.length || 1;
    }
    daysUsed = (daysUsed || 1);
    // chances of 5 weeks in a month or overlapping 4th and last week
    daysUsed = daysUsed * WEEK_IN_A_MONTH / 4;
  }

  return daysUsed / frequencyRepeat;
};

const findQuarterlyBookingsPerMonth = (args: any) => {
  const { frequencyPattern = [], frequencyRepeat = 1 } = args;
  let daysUsed = 0;

  if (!frequencyPattern.length) {
    daysUsed = 1;
  }

  for (const pattern of frequencyPattern) {
    const { month, week, days = [] } = pattern;

    if (!month || !week) continue;

    daysUsed += days.length || 1;
  }

  daysUsed = daysUsed || 1;

  return daysUsed / (frequencyRepeat * 3);
};

const findBiWeeklyBookingsPerMonth = (args: any) => {
  const { frequencyRepeat = 1 } = args;
  const daysUsed = 2; // 2 days in a month

  return daysUsed / frequencyRepeat;
};

const findBiMonthlyBookingsPerMonth = (args: any) => {
  const { frequencyRepeat = 1 } = args;
  const daysUsed = 0.5; // 1 day in 2 months

  return daysUsed / frequencyRepeat;
};

const findSemiAnnuallyBookingsPerMonth = (args: any) => {
  const { frequencyRepeat = 1 } = args;
  const daysUsed = 1 / 6; // 1 day in 6 months

  return daysUsed / frequencyRepeat;
};

type AvgBookingsPerMonthArgs = {
  frequency: string;
  frequencyPattern: FrequencyPattern[];
  frequencyRepeat: number;
  frequencyRepeatMode: string;
};

export const getAvgBookingsPerMonth = (args: AvgBookingsPerMonthArgs) => {

  const { frequency, frequencyPattern = [], frequencyRepeat = 1, frequencyRepeatMode = defaultFrequencyRepeatMode } = args;

  // calculate an average number of bookings per month that would be created based on the frequency and pattern and repeat
  let getBookingsPerMonth = null; // function to call

  switch (frequency) {
    case adminRecurringFrequency.Weekly:
      getBookingsPerMonth = findWeeklyBookingsPerMonth;
      break;
    case adminRecurringFrequency.Monthly:
      getBookingsPerMonth = findMonthlyBookingsPerMonth;
      break;
    case adminRecurringFrequency.Quarterly:
      getBookingsPerMonth = findQuarterlyBookingsPerMonth;
      break;
    case adminRecurringFrequency.Biweekly:
      getBookingsPerMonth = findBiWeeklyBookingsPerMonth;
      break;
    case adminRecurringFrequency.Bimonthly:
      getBookingsPerMonth = findBiMonthlyBookingsPerMonth;
      break;
    case adminRecurringFrequency.SemiAnnually:
      getBookingsPerMonth = findSemiAnnuallyBookingsPerMonth;
      break;
    default:
      break;
  }

  if (!getBookingsPerMonth) return 1;

  const approxMonthlyBookings = getBookingsPerMonth({
    frequency,
    frequencyPattern,
    frequencyRepeat: frequencyRepeat || 1,
    frequencyRepeatMode,
  });

  return Math.round(approxMonthlyBookings * 100) / 100;
};
