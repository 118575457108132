import { dumpsterBasePrice, dumpsterYards, dumpster_WP_CP_FormFactor, everydayPickupPrice, Pickup } from "../constants/dumpsters";
import { DumpsterSheetItem } from "../interfaces/dumpster";

export const convertPickup = (pickup: any): Pickup | null => {
  switch (pickup) {
    case Pickup.NextBooking: return Pickup.NextBooking;
    case Pickup.ThreeDays: return Pickup.ThreeDays;
    case Pickup.SevenDays: return Pickup.SevenDays;
    case Pickup.Manual: return Pickup.Manual;
    default: return null;
  }
};

export const constructDumpsterPrice = (basePrices: any, days: number) => {
  // construct current price from base price or dumpsterBasePrice
  const currentPrices = Object.keys(dumpsterBasePrice).reduce((acc: any, key: any) => {
    acc[key] = basePrices?.[key] ?? dumpsterBasePrice[key];
    return acc;
  }, {});

  return dumpsterYards.reduce((acc: any, yard: any) => {
    acc[yard] = {
      base: currentPrices[yard] * dumpster_WP_CP_FormFactor,
      [days]: (currentPrices[yard] * dumpster_WP_CP_FormFactor + (days * everydayPickupPrice)),
    };
    return acc;
  }, {});
};

export const getDynamicMatchingItem = (item: any, dumpsterSheetList: Array<DumpsterSheetItem> = []): DumpsterSheetItem | boolean => {
  for (const sheetItem of dumpsterSheetList) {
    if (sheetItem.dumpsterSize !== item.description) continue; // checking yardage match

    const subItems = item?.items?.map((item: any) => item.description) ?? [];
    const sheetSubItems = sheetItem.items;

    const joinedSet = new Set([...subItems, ...sheetSubItems]); // join two description arrays to check if equal
    if (joinedSet.size === sheetSubItems.length && joinedSet.size === subItems.length) {
      return sheetItem;
    }
  }

  return false;
};
