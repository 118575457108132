const quoteTypes = {
  SET_QUOTES_LOADING: "SET_QUOTES_LOADING",
  RESET_QUOTES_LOADING: "RESET_QUOTES_LOADING",

  FETCH_QUOTES: "FETCH_QUOTES",
  FETCH_QUOTES_ERROR: "FETCH_QUOTES_ERROR",
  CREATE_QUOTE: "CREATE_QUOTE",
  CREATE_QUOTE_ERROR: "CREATE_QUOTE_ERROR",

  ADD_QUOTE: "ADD_QUOTE",
  UPDATE_QUOTE: "UPDATE_QUOTE",
  UPDATE_QUOTE_ERROR: "UPDATE_QUOTE_ERROR",
  DELETE_QUOTE: "DELETE_QUOTE",

  SET_QUOTE: "SET_QUOTE",
  SET_ACTIVE_QUOTE: "SET_ACTIVE_QUOTE",
  FETCH_INVITES: "FETCH_INVITES",
  FETCH_INVITES_ERROR: "FETCH_INVITES_ERROR",
};

export default quoteTypes;
