import bundleActionTypes from "../constants/bundleTypes";

const INITIAL_STATE = {
  isLoading: false,
  bundles: [],
  bundleMap: {},
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case bundleActionTypes.FETCH_BUNDLES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case bundleActionTypes.RESET_BUNDLES_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case bundleActionTypes.FETCH_MY_BOOKING_BUNDLES:
      return {
        ...state,
        bundles: action.payload,
        bundleMap: action.payload.reduce((acc: any, bundle: any) => {
          acc[bundle.id] = bundle;
          return acc;
        }, {}),
      };
    case bundleActionTypes.RESET_MY_BOOKING_BUNDLES:
      return {
        ...state,
        bundles: [],
        bundleMap: {},
      };
    default:
      return state;
  }
};
