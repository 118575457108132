/* eslint-disable */
import { CacheActions } from "../constants/cacheTypes";

const INITIAL_STATE = {
  bookings: {},
  users: {},
  subscriptions: {},
  bundles: {},
  invoices: {}
};

export default (state = INITIAL_STATE, action: any) => {
  // idea is to set cache by key value pair
  switch (action.type) {
    case CacheActions.WRITE_BOOKING_TO_CACHE:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.id]: action.payload
        }
      };
    case CacheActions.WRITE_USER_TO_CACHE:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: action.payload
        }
      };
    case CacheActions.WRITE_SUBSCRIPTION_TO_CACHE:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.id]: action.payload
        }
      };
    case CacheActions.WRITE_BUNDLE_TO_CACHE:
      return {
        ...state,
        bundles: {
          ...state.bundles,
          [action.payload.id]: action.payload
        }
      };
    case CacheActions.WRITE_INVOICE_TO_CACHE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.id]: action.payload
        }
      };
    case CacheActions.WRITE_BOOKING_TO_CACHE_BULK:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          ...action.payload
        }
      };
    case CacheActions.WRITE_USER_TO_CACHE_BULK:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload
        }
      };
    case CacheActions.WRITE_SUBSCRIPTION_TO_CACHE_BULK:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          ...action.payload
        }
      };
    case CacheActions.WRITE_BUNDLE_TO_CACHE_BULK:
      return {
        ...state,
        bundles: {
          ...state.bundles,
          ...action.payload
        }
      };
    case CacheActions.WRITE_INVOICE_TO_CACHE_BULK:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          ...action.payload
        }
      };
    case CacheActions.CLEAR_CACHE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
