import { appCheck } from "../config/firebaseConfig";
import { getToken } from "@firebase/app-check";
import { segmentTrack } from "./segment";

let cachedToken = null;
let tokenExpirationTime = 0;

export const getAppCheckToken = async () => {
  const now = Date.now();
  // If we have a cached token that's still valid, return it
  if (cachedToken && now < tokenExpirationTime) {
    return cachedToken;
  }

  try {
    const appCheckTokenResponse = await getToken(appCheck, /* forceRefresh */ true);
    if (appCheckTokenResponse?.token) {
      cachedToken = appCheckTokenResponse.token;
      // Set expiration time to 5 minutes before actual expiration for safety
      tokenExpirationTime = now + 30 * 60 * 1000;
      return cachedToken;
    } else {
      throw new Error("No token received from App Check");
    }
  } catch (error) {
    segmentTrack("App Check Token Error", { error: error.message, stack: error.stack, code: error.code });
    return;
  }
};

// Set up automatic token refresh
setInterval(async () => {
  if (Date.now() > tokenExpirationTime) {
    await getAppCheckToken();
  }
}, 60 * 1000); // Check every minute
