import documentsTypes from "../constants/documentsTypes";

const INITIAL_STATE = {
    userDocuments: [],
};

const documentsReducer = (state = INITIAL_STATE, action: { type: string, payload: any }) => {
    switch (action.type) {
        case documentsTypes.SET_USER_DOCUMENTS:
            return {
                ...state,
                userDocuments: action.payload.files,
            };
        default:
            return state;
    }
};

export default documentsReducer;
