/* eslint-disable no-console */
import history from "@history";
import authConstants from "@store/constants/authTypes";
import loaderConstants from "@store/constants/loadingTypes";
import tempDataConstants from "@store/constants/tempDataTypes";

import { auth, firebase } from "@config/firebaseConfig";
import { associateBookingsToUser } from "@store/actions/booking";
import { validatePhone, validateMigrationResetUser } from "@store/actions/users";
import { store } from "@index";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

import { toastr } from "react-redux-toastr";
import { detectMobile } from "@helpers/device";
import { validateEmail } from "@helpers/validators";
import { configureAxiosAuth, useAxiosPost } from "../../axios";
import { segmentIdentify, segmentTrack } from "../../helpers/segment";
import { roles } from "../../constants/roles";
import { findExistingCustomer, userLogin } from "./users";
import { isProduction } from "../../constants/environments";
import subscriptionTypes from "../constants/subscriptionTypes";
import { setClaimTask } from "./booking";

firebase.auth().onAuthStateChanged(() => {
  const profile = store.getState().firebase?.profile;
  if (profile.uid) {
    configureAxiosAuth(profile.uid);
  } else {
    configureAxiosAuth(null);
  }
  if (profile?.userType === roles.customer.value) {
    console.log("calling associateBookingsToUser");
    associateBookingsToUser(profile.uid);
  } else {
    return null;
  }
});

export const signInWithGoogle =
  ({ loader = true, phone = "", twilioVerified = false }) =>
  async (dispatch, getState) => {
    try {
      loader &&
        dispatch({
          type: loaderConstants.SET_LOADING,
          isLoading: true,
          message: "Signin In...",
        });
      if (phone) {
        const isValidPhone = await validatePhone(phone);
        if (!isValidPhone) return;
      }
      const provider = new firebase.auth.GoogleAuthProvider();
      const authUser = await firebase.auth().signInWithPopup(provider);
      const gclid = sessionStorage.getItem("gclid-track");

      // if new user
      if (authUser.additionalUserInfo.isNewUser) {
        const profile = {
          uid: authUser.user.uid,
          fullName: authUser.user.displayName,
          firstName: authUser.user.displayName.split(" ")[0],
          lastName: authUser.user.displayName.split(" ")[1] ?? "",
          email: authUser.user.email,
          userType: getState().tempData.userType,
          phoneNumber: phone || authUser.user.phoneNumber,
          photoURL: authUser.user.photoURL,
          gclid: gclid ?? "",
          twilioVerified,
        };

        await useAxiosPost("/users/create-user", profile);
        await userLogin({ ...profile, uid: authUser.user.uid });
      }
      segmentIdentify({
        firstName: authUser?.user?.displayName?.split(" ")[0] ?? "",
        lastName: authUser?.user?.displayName?.split(" ")[1] ?? "",
        email: authUser?.user?.email ?? "",
        phone: phone || authUser?.user?.phoneNumber,
        userType: getState().tempData?.userType ?? "",
        gclid: gclid ?? "",
      });
    } catch (error) {
      console.log("Google Sign In/Sign Up Error: ", error.message);
      toastr.error("Error", "Something went wrong");
    } finally {
      loader && dispatch({ type: loaderConstants.RESET_LOADING });
    }
    return;
  };

export const registerWithEmail =
  ({ newUser, taskId = "", loader = true }) =>
  async (dispatch) => {
    try {
      const gclid = sessionStorage.getItem("gclid-track");

      if (isProduction) {
        const isValidPhone = await validatePhone(newUser.phone);
        if (!isValidPhone) {
          throw new Error("Invalid phone number");
        }
      }

      const authUser = await auth.createUserWithEmailAndPassword(newUser.email, newUser.password);

      loader &&
        dispatch({
          type: loaderConstants.SET_LOADING,
          isLoading: true,
          message: "Creating User...",
        });

      const profile = {
        uid: authUser.user.uid,
        companyName: newUser.companyName,
        fullName: newUser.fullName,
        firstName: newUser.firstName ?? newUser.fullName.split(" ")[0],
        lastName: newUser.lastName ?? newUser.fullName.split(" ")[1] ?? "",
        email: newUser.email,
        userType: newUser.userType,
        phoneNumber: newUser.phone,
        photoURL: null,
        gclid: gclid ?? "",
        twilioVerified: newUser.twilioVerified ?? null,
        businessId: newUser.businessId ?? null,
        isAbbreviated: newUser?.isAbbreviated ?? false,
        smsSettings: newUser?.smsSettings ?? null,
        alerts: newUser?.alerts ?? null,
        isSignupCompleted: newUser?.isSignupCompleted ?? null,
        businessNotifications: newUser?.businessNotifications ?? null,
      };

      segmentIdentify({
        companyName: profile.companyName ?? "",
        firstName: profile.firstName ?? "",
        lastName: profile.lastName ?? "",
        email: profile.email ?? "",
        phone: profile.phoneNumber ?? "",
        userType: profile.userType ?? "",
        gclid: gclid ?? "",
      });

      await useAxiosPost("/users/create-user", profile);
      await userLogin({ ...profile, uid: authUser.user.uid });

      if (taskId && taskId?.trim().length > 0) {
        await dispatch(setClaimTask({ task_id: taskId, user_id: authUser.user.uid }));
      }
    } catch (error) {
      console.log("Sign In Error: ", error.message);
      toastr.error("Error", error.message);
    } finally {
      loader && dispatch({ type: loaderConstants.RESET_LOADING });
    }
    return;
  };

export const signInWithEmail =
  ({ email, password, loader = true, callback }) =>
  async (dispatch) => {
    try {
      loader &&
        dispatch({
          type: loaderConstants.SET_LOADING,
          isLoading: true,
          message: "Signin In...",
        });
      await auth.signInWithEmailAndPassword(email, password);
      if (callback) {
        callback();
      }
      segmentIdentify();
    } catch (error) {
      segmentTrack("Failed Email Signin", { email, error });
      const userId = await validateMigrationResetUser(email);
      if (userId) {
        dispatch({ type: authConstants.MIGRATION_PWD_SET, email });
        localStorage.setItem("resetPwdUser", userId);
      } else {
        console.log("Sign In Error: ", error.message);
        toastr.error("Error", "Invalid credentials");
      }
    } finally {
      loader && dispatch({ type: loaderConstants.RESET_LOADING });
    }
    return;
  };

export const signInWithCustomToken = (token) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loaderConstants.SET_LOADING,
      isLoading: true,
      message: "Signin In...",
    });
    await auth.signInWithCustomToken(token);
    dispatch({ type: authConstants.SET_ADMIN_ENTRY });
  } catch (error) {
    console.log("Sign In Error: ", error.message);
    toastr.error("Error", "Check console for detailed error");
    getState().auth.isAdmin && dispatch({ type: authConstants.RESET_ADMIN_ENTRY });
  } finally {
    dispatch({ type: loaderConstants.RESET_LOADING });
  }
};

export const sendResetEmail = (email, nextUrl) => async (dispatch) => {
  await auth.sendPasswordResetEmail(email);
  dispatch({ type: loaderConstants.SIGN_OUT });
  if (nextUrl) {
    history.push(nextUrl);
  }
};

export const signOut = () => async (dispatch) => {
  dispatch({ type: tempDataConstants.SET_SHOW_SIGN_OUT_MODAL });
};

export const staySignedIn = () => async (dispatch) => {
  dispatch({ type: tempDataConstants.RESET_SHOW_SIGN_OUT_MODAL });
};

export const confirmSignOut = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: loaderConstants.SET_LOADING,
      isLoading: true,
      message: "Signin Out...",
    });
    dispatch({ type: subscriptionTypes.RESET_BOOKINGS_SUBSCRIPTIONS });
    dispatch({ type: subscriptionTypes.RESET_BOOKINGS_BUNDLE });
    history.push("/");
    await auth.signOut();
    dispatch({ type: tempDataConstants.RESET_SHOW_SIGN_OUT_MODAL });
    dispatch({ type: authConstants.SIGN_OUT_SUCCESS });
    getState().auth.isAdmin && dispatch({ type: authConstants.RESET_ADMIN_ENTRY });
    localStorage.removeItem("warrior-bonus-prompt");
  } catch (error) {
    dispatch({
      type: authConstants.SIGN_OUT_ERROR,
      errorMessage: error.message,
    });
  } finally {
    dispatch({ type: loaderConstants.RESET_LOADING });
  }
};

export const connectStripeAccount = () => async (_, getState) => {
  try {
    const profile = getState().firebase.profile;
    const res = await useAxiosPost("/payments/connect-stripe-account", { uid: profile.uid });

    if (res?.data?.error) throw new Error(res.data.error);

    const isMobile = detectMobile();

    if (isMobile) {
      // on mobile popups are blocked `window.open(res.data, "_blank")` doesn't work.
      window.location.href = res.data.redirectURL;
    } else {
      window.open(res.data.redirectURL, "_blank");
    }
  } catch (error) {
    console.log("Error connecting to stripe", error);
  }
};

export const sendContactDetails = async (form, callback) => {
  try {
    await useAxiosPost("/users/send-contact-details", form);
    toastr.success("Done!", "We will reach you shortly!");
    callback?.();
  } catch (error) {
    console.log("!Error sending details", error);
  }
};

export const sendPasswordResetEmail =
  (email, isAdmin = false) =>
  async (dispatch) => {
    try {
      await auth.sendPasswordResetEmail(email);
      if (isAdmin) {
        toastr.success("Success", `Reset password link send to ${email}`);
      } else {
        toastr.success("Thanks", "Please check your email");
        history.push("/auth/signin");
      }
    } catch (error) {
      console.log("Error: ", error);
      toastr.error(error.code || "Error", error.message || "Something went wrong");
    }
  };

export const confirmPasswordReset = (actionCode, newPassword) => async (dispatch) => {
  try {
    const userId = localStorage.getItem("resetPwdUser");
    await auth.confirmPasswordReset(actionCode, newPassword);
    userId && (await useAxiosPost("/users/update-user", { uid: userId, requirePwdReset: false }));
    dispatch({ type: authConstants.CLEAR_MIGRATION_PWD });
    localStorage.removeItem("resetPwdUser");
    toastr.success("Password updated successfully!");
    history.push("/auth/signin");
  } catch (error) {
    console.log("Error: ", error);
    toastr.error(error.code || "Error", error.message || "Something went wrong");
  }
};

export const adminCreateTempUser = (user, callback) => async () => {
  try {
    const { data } = await useAxiosPost("users/admin-create-temp-user", user);
    if (data?.success) {
      toastr.success("User created successfully");
      callback?.(data?.uid);
    } else {
      throw new Error(data?.error?.message || "Error creating user");
    }
  } catch (error) {
    console.log("Temp User Create Error: ", error?.message);
    toastr.error("Error", error?.message);
  }
};

export const adminConvertUser = (user, callback) => async () => {
  try {
    const { data } = await useAxiosPost("users/admin-convert-account", user);
    if (data?.response?.success) {
      toastr.success("User converted successfully");
      callback?.(data?.uid);
    } else {
      throw new Error(data?.error?.message || "Error converting user");
    }
  } catch (error) {
    console.log("Guest User Convert Error: ", error?.message);
    toastr.error("Error", error?.message);
  }
};

export const adminCreateUser = (newUser, callback) => async (dispatch) => {
  try {
    if (isProduction) {
      const isValidPhone = await validatePhone(newUser.phoneNumber);
      if (!isValidPhone) {
        // throw new Error("Phone number already in use.");
        toastr.error("Error", "Phone number already in use.");
      }
    }
    const isValidEmail = validateEmail(newUser.email);
    if (isValidEmail) {
      const user = await findExistingCustomer({ type: "email", value: newUser.email });
      if (user) {
        throw new Error("Email already in use");
      }
    }
    const { data } = await useAxiosPost("users/admin-create-user", newUser);
    if (data?.success) {
      toastr.success("User created successfully");
      callback?.(data?.uid);
    } else {
      throw new Error(data?.error?.message || "Error creating user");
    }
  } catch (error) {
    console.log("User Create Error: ", error?.message);
    toastr.error("Error", error?.message);
  }
};
