import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import history from "./history";
import WrappingProviders from "./containers/WrappingProviders";
import { privileges } from "./constants/privileges";
import { useLoadScript } from "@react-google-maps/api";
import tempDataConstants from "@store/constants/tempDataTypes";
import { renderComponent } from "./helpers/renderPage";
import Loader from "./shared/components/Loader";
import { roles } from "./constants/roles";
import { updateProfile, useActiveUser, userLogin } from "./store/actions/users";
import { configureAxiosAuth } from "./axios";
import dayjs from "dayjs";
import { fetchBusinessAccountData, setSelectedBusinessAccount } from "./store/actions/businessAccounts";

const libraries = ["places", "drawing"];
const googleMapsApiKey = process.env.REACT_APP_GMAPS_API_KEY;
const DefaultLayout = React.lazy(() => import("./containers"));
const Admin = React.lazy(() => import("./views/Admin"));
const UrlShortnerRedirect = React.lazy(() => import("./containers/UrlShortnerRedirect"));
const GuestPortal = React.lazy(() => import("./views/Admin/GuestPortal"));

const routes = [
  { path: "/portal-guest", name: "Portal Guest", component: GuestPortal, privilege: privileges.GUEST_PORTAL_ACCESS.name, title: "Guest | Dimension" },
  { path: "/portal", name: "Portal", component: Admin, privilege: privileges.PORTAL_ACCESS.name, title: "Portal | Dimension" },
  { path: "/l/:id", name: "Redirect", component: UrlShortnerRedirect, title: "Dimension" },
  { path: "/", name: "Landing Page", component: DefaultLayout, bypass: true },
];

function App() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);
  const { authLoaded } = useSelector((state) => state.tempData);
  const sessionPrivileges = useSelector((state) => state.auth.privileges);
  const isLoading = useSelector((state) => state.loader.isLoading || state.page.isLoading || state.booking.isLoading);
  const providerAgreementDate = useSelector((state) => state.aggregates.providerAgreementDate);
  const sessionStarted = useRef(false);
  const isLoggedInProvider = profile.userType === roles.provider.value;
  const isAbbreviated = !!(profile?.isAbbreviated || (isLoggedInProvider && profile?.isSignupCompleted && profile?.businessId));
  const isProvider = profile.userType === roles.provider.value;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
  });

  useEffect(() => {
    if (sessionStarted.current === true) return;
    if (profile?.uid) {
      sessionStarted.current = true;
      configureAxiosAuth(profile.uid);
      userLogin(profile);
    }
  }, [profile]);

  useActiveUser(profile?.uid ?? null);

  const hasSignedLatestAgreement = () => {
    return (
      !!profile.providerAgreement?.date && (!providerAgreementDate || dayjs(profile.providerAgreement.date).isAfter(dayjs(providerAgreementDate)))
    );
  };

  const isAgreementSigned = hasSignedLatestAgreement();

  const isProfileComplete = () => {
    const isDriversLicenseEntered = !!profile.driversLicense;
    const isPhotoAdded = !!profile.photoURL;
    const isSmsSettingsAdded = !!profile.smsSettings;
    const isVehicleInfoAdded = !!profile.vehicleInfo;
    return isDriversLicenseEntered && isPhotoAdded && isSmsSettingsAdded && isVehicleInfoAdded && isAgreementSigned;
  };

  const updateProfileState = async () => {
    await dispatch(updateProfile({ approvalState: "info-filled" }));
  };

  useEffect(() => {
    const { isEmpty, isLoaded, userType, phoneNumber, approvalState } = profile;

    if (!isEmpty) {
      if (userType === roles.provider.value && !phoneNumber) {
        dispatch({ type: tempDataConstants.SET_TEMP_USER, userType: roles.provider.value });
        history.push("auth/signup_with_google?invalidPhoneNumber=true");
      }
    }

    if (isLoaded) {
      dispatch({
        type: tempDataConstants.SET_INITIAL_AUTH_LOAD,
      });

      if (userType === roles.provider.value) {
        if (isProfileComplete()) {
          const isProfileProcessed = ["approved", "complete", "info-filled"].includes(approvalState);
          if (isProfileProcessed) {
            if (!["/task_details", "/bid_details", "/l", "/eta"].some((i) => history.location.pathname.includes(i))) {
              history.push("/get_tasks");
            }
          } else {
            updateProfileState();
          }
        } else {
          if (isAbbreviated && !isAgreementSigned) {
            history.push(`/complete_profile`);
          }
        }
      }
    }
  }, [profile.isEmpty, profile.isLoaded, profile.userType, profile.phoneNumber, profile.approvalState, isAbbreviated, isAgreementSigned]);

  const FEINExists = (response) => (response?.F_EIN ? true : false);

  useEffect(() => {
    const isSmsSettingsAdded = !!profile.smsSettings;
    if (profile?.isLoaded && !profile?.isEmpty) {
      if (isProvider && profile?.businessId) {
        fetchBusinessAccountData(profile.businessId).then((response) => {
          dispatch(setSelectedBusinessAccount(response));
          if (!FEINExists(response) || !isSmsSettingsAdded) {
            history.push("/auth/register/bprovider");
          } else if (isSmsSettingsAdded && profile.approvalState === "pending" && isAbbreviated && !isAgreementSigned) {
            history.push(`/complete_profile`);
          }
        });
      }
    }
  }, [isProvider, profile?.businessId, profile?.smsSettings]);

  if (loadError || (!isLoaded && isLoading) || (!profile?.isLoaded && !authLoaded)) {
    return <Loader />;
  }

  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <WrappingProviders>
          {isLoaded && !loadError && (
            <Switch>
              {routes.map((route, idx) => (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={renderComponent(route, sessionPrivileges, profile)}
                />
              ))}
            </Switch>
          )}
        </WrappingProviders>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
