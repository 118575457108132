import { createBrowserHistory } from "history";
export default createBrowserHistory();

export const getLinkWithGclid = (path) => {
  return path;
  // const gclid = sessionStorage.getItem("gclid-track");
  // if (gclid) {
  //   if (path.includes("?")) {
  //     return `${path}&gclid=${gclid}`;
  //   } else {
  //     return `${path}?gclid=${gclid}`;
  //   }
  // } else {
  //   return path;
  // }
};
