/* eslint-disable no-console */
import { toastr } from "react-redux-toastr";
import { useAxiosPost } from "../../axios";
import { groupSubscriptions } from "../../helpers/subscriptions";
import subscriptionTypes from "../constants/subscriptionTypes";

export const fetchStripeSubscription = async (id: string, source = "stripe") => {
  try {
    const response = await useAxiosPost(`/subscriptions/fetch-subscription`, { source, id });
    if (!response?.data?.success) throw response?.data?.error ?? "Something went wrong";

    return response?.data?.payload ?? null;
  } catch (error) {
    console.log("Error in fetchStripeSubscription: ", error);
    return null;
  }
};

export const fetchStripeInvoices = async (subscriptionId: string, startAfter: string | null) => {
  try {
    const response = await useAxiosPost("/subscriptions/fetch-invoices", { id: subscriptionId, starting_after: startAfter });
    if (!response?.data?.success) throw response?.data?.error ?? "Something went wrong";

    return response?.data?.payload ?? {};
  } catch (error) {
    console.log("Error in fetchStripeSubscription: ", error);
    return {};
  }
};

export const fetchSubscriptionBookings = async (subscriptionId: string) => {
  try {
    const response = await useAxiosPost("/subscriptions/fetch-bookings", { id: subscriptionId });
    if (!response?.data?.success) throw response?.data?.error ?? "Something went wrong";

    return response?.data?.payload ?? [];
  } catch (error) {
    console.log("Error in fetchSubscriptionBookings: ", error);
    return [];
  }
};

export const fetchStripeSubscriptions = (customerId: string, bypassLoad?: boolean) => async (dispatch: any) => {
  try {
    !bypassLoad && dispatch({ type: subscriptionTypes.SET_SUBSCRIPTION_LOADING });
    const response = await useAxiosPost(`/subscriptions/list-subscriptions`, { customer: customerId });
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    const group = await groupSubscriptions(response?.data?.payload?.data || {});
    dispatch({ type: subscriptionTypes.FETCH_BOOKINGS_SUBSCRIPTIONS, payload: group });
  } catch (error: any) {
    console.log("Error in fetchStripeSubscriptions: ", error);
  } finally {
    dispatch({ type: subscriptionTypes.RESET_SUBSCRIPTION_LOADING });
  }
};

export const fetchSubscriptionItem = async (id: string) => {
  try {
    const response = await useAxiosPost(`/subscriptions/subscription-item`, { id });
    if (!response?.data?.success) {
      throw response?.data?.error?.message ?? "Something went wrong";
    }
    return response?.data?.payload;
  } catch (error: any) {
    console.log("Error in fetchFBSubscriptionItem: ", error);
    return null;
  }
};

export const fetchActiveBookingBundle = async (customerId: string) => {
  try {
    const response = await useAxiosPost(`/subscriptions/get-active-booking-bundle`, { customerId });
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    return response.data.payload;
  } catch (error) {
    console.log("Error in fetchActiveBookingBundle: ", error);
  }
};

export const fetchBookingBundle = (customerId: string, bypassLoad?: boolean) => async (dispatch: any) => {
  try {
    !bypassLoad && dispatch({ type: subscriptionTypes.SET_BUNDLE_LOADING });
    const bundle = await fetchActiveBookingBundle(customerId);
    dispatch({ type: subscriptionTypes.FETCH_BOOKINGS_BUNDLE, payload: bundle });
  } catch (error: any) {
    console.log("Error in fetchBookingBundle: ", error);
  } finally {
    dispatch({ type: subscriptionTypes.RESET_BUNDLE_LOADING });
  }
};

export const updateBookingBundle = async (bundleId: string, form: any) => {
  try {
    const response = await useAxiosPost(`/subscriptions/update-booking-bundle`, { bundleId, form });
    const success = response?.data?.success;
    if (!success) throw response?.data?.error?.message ?? "Something went wrong!";
    toastr.success("Success!", "Bundle status updated");
    return success;
  } catch (error: any) {
    toastr.error("Error!", error);
    console.log("Error updating Booking Bundle: ", error);
  }
};
