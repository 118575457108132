import configConstants from "@store/constants/configTypes";
import { bookingConfig } from "@constants/booking";
import deepClone from "@methods/deepClone";

const INITIAL_STATE = {
  isLoading: false,
  ...bookingConfig,
};

const allowConfigTypes = ["booking", "quote", "cron", "notifications", "blockNotification", "holidaySchedule", "conditionals"];

const configReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case configConstants.FETCH_CONFIG:
      return {
        ...state,
        isLoading: true,
      };
    case configConstants.SET_CONFIG: {
      let newState = INITIAL_STATE;
      action.config.forEach((config) => {
        if (allowConfigTypes.includes(config.type)) {
          newState = {
            ...newState,
            ...config,
          };
          delete newState.type;
        }
      });
      return {
        ...newState,
        cache: deepClone(newState),
      };
    }
    case configConstants.UPDATE_BUSINESS_HOURS:
      return {
        ...state,
        businessHours: action.businessHours,
      };
    case configConstants.SET_CONFIG_CACHED:
      return {
        ...deepClone(state.cache),
        cache: state.cache,
      };
    case configConstants.HIDE_STRIPE_DUE_TRIGGER:
      return {
        ...state,
        showStripeDueTrigger: false,
      };
    case configConstants.SET_PENALTY_POINT_REASONS:
      return {
        ...state,
        penaltyPointReasons: action.payload,
      };
    case configConstants.RESET_CONFIG:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default configReducer;
