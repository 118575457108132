import aggregateTypes from "@store/constants/aggregateTypes";
import dayjs from "dayjs";

const initReviews = [
  {
    name: "Jessica - Fort Collins, CO",
    comment: "Very easy to schedule. They removed all items completely and on time. We would use them again.",
  },
  {
    name: "Joanne - Tampa, FL",
    comment: "Everything was great, prompt, efficient and professional.",
  },
  {
    name: "Marshall - Los Angeles, CA",
    comment: "Easy to set up, nice price and great service from the trash removal workers! Very pleased and will use this service again.",
  },
];

const initBookings = [
  {
    id: "x123",
    name: "Lily",
    date: dayjs().subtract(1, "day"),
    task: "cardboard-removal",
    address: { city: "Sacramento", coordinates: { lat: 38.5815719, lng: -121.4943996 } },
    payout: 12000,
  },
  {
    id: "x153",
    name: "Martin",
    date: dayjs().subtract(36, "hours"),
    task: "dumpster-rental",
    address: { city: "San José", coordinates: { lat: 37.3382082, lng: -121.8863286 } },
    payout: 33200,
  },
  {
    id: "x133",
    name: "Gisella",
    date: dayjs().subtract(3, "day"),
    task: "junk-removal",
    address: { city: "San Francisco", coordinates: { lat: 37.7749295, lng: -122.4194155 } },
    payout: 16500,
  },
  {
    id: "x134",
    name: "Angelica",
    date: dayjs().subtract(4, "day"),
    task: "junk-removal",
    address: { city: "New York", coordinates: { lat: 40.7127753, lng: -74.0059728 } },
    payout: 12300,
  },
  {
    id: "x136",
    name: "Mark",
    date: dayjs().subtract(5, "day"),
    task: "cardboard-removal",
    address: { city: "Los Angeles", coordinates: { lat: 34.0522342, lng: -118.2436849 } },
    payout: 1600,
  },
];

const INITIAL_STATE = {
  reviews: [],
  bookings: [],
  counts: {},
  providerAgreementDate: null,
  isLoading: true,
};

const aggregateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case aggregateTypes.FETCH_AGGREGATES:
      return {
        ...state,
        reviews: [...action.reviews, ...initReviews].slice(0, 3),
        bookings: [...action.bookings, ...initBookings].slice(0, 5),
        counts: action.counts,
        providerAgreementDate: action.providerAgreementDate,
        isLoading: false,
      };
    case aggregateTypes.RESET_AGGREGATES:
      return {
        ...state,
        reviews: initReviews.slice(0, 3),
        bookings: initBookings.slice(0, 5),
        counts: {},
        isLoading: false,
      };
    default:
      return state;
  }
};

export default aggregateReducer;
