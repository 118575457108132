import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { reducer as toastrReducer } from "react-redux-toastr";
import abTestReducer from "./abTestReducer";
import addressReducer from "./addressReducer";
import adminReducer from "./adminReducer";
import aggregateReducer from "./aggregateReducer";
import analyticsReducer from "./analytics";
import attributesReducer from "./attributesReducer";
import authReducer from "./authReducer";
import bookingBidsReducer from "./bookingBidsReducer";
import bookingBundlesReducer from "./bundleReducer";
import bookingPaymentReducer from "./bookingPaymentReducer";
import bookingQuotesReducer from "./bookingQuotesReducer";
import bookingReducer from "./bookingReducer";
import businessAccountsReducer from "./businessAccountsReducer";
import cacheReducer from "./cacheReducer";
import configReducer from "./configReducer";
import couponReducer from "./couponReducer";
import documentsReducer from "./documentsReducer";
import dumpSitesReducer from "./dumpSitesReducer";
import flagsReducer from "./flagsReducer";
import formDraftReducer from "./formDraftReducer";
import invoicesReducer from "./invoicesReducer";
import itemsReducer from "./itemsReducer";
import keywordReducer from "./keywordReducer";
import loadingReducer from "./loadingReducer";
import locationReducer from "./locationReducer";
import notificationReducer from "./notificationReducer";
import pageReducer from "./pageReducer";
import penaltyPointsReducer from "./penaltyPointsReducer";
import pricingReducer from "./pricingReducer";
import quoteReducer from "./quoteReducer";
import reviewReducer from "./reviewReducer";
import salesGoalsReducer from "./salesGoalsReducer";
import subscriptionReducer from "./subscriptionReducer";
import tempDataReducer from "./tempDataReducer";
import trackersReducer from "./trackersReducer";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";
import variablesReducer from "./variablesReducer";
import vipReducer from "./vipReducer";
import wordpressReducer from "./wordpressReducer";
import faqReducer from "./faqReducer";

// keeping the reducers alphabetically sorted
const reducerMap = {
  abTest: abTestReducer,
  address: addressReducer,
  admin: adminReducer,
  aggregates: aggregateReducer,
  analytics: analyticsReducer,
  attributes: attributesReducer,
  auth: authReducer,
  booking: bookingReducer,
  bookingBid: bookingBidsReducer,
  bookingBundle: bookingBundlesReducer,
  bookingPayment: bookingPaymentReducer,
  bookingQuote: bookingQuotesReducer,
  businessAccount: businessAccountsReducer,
  cache: cacheReducer,
  config: configReducer,
  coupon: couponReducer,
  documents: documentsReducer,
  dropDownItems: itemsReducer,
  dumpSite: dumpSitesReducer,
  faq: faqReducer,
  firebase: firebaseReducer,
  flags: flagsReducer,
  formDraft: formDraftReducer,
  invoice: invoicesReducer,
  keyword: keywordReducer,
  loader: loadingReducer,
  notificationSettings: notificationReducer,
  page: pageReducer,
  penaltyPoints: penaltyPointsReducer,
  pricing: pricingReducer,
  quote: quoteReducer,
  review: reviewReducer,
  sales: salesGoalsReducer,
  serviceLocations: locationReducer,
  subscriptions: subscriptionReducer,
  tempData: tempDataReducer,
  toastr: toastrReducer,
  tracking: trackersReducer,
  ui: uiReducer,
  user: userReducer,
  variables: variablesReducer,
  vip: vipReducer,
  wordpress: wordpressReducer,
};

const rootReducer = combineReducers(reducerMap);

export default rootReducer;
