import bookingQuotesTypes from "../constants/bookingQuoteTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  bookingQuotes: [],
  selectedBookingQuote: null,
  isLoading: false,
};

const bookingQuotesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bookingQuotesTypes.FETCH_BOOKING_QUOTES:
      return {
        ...state,
        bookingQuotes: [...action.payload],
      };
    case bookingQuotesTypes.FETCH_CUST_BOOKING_QUOTES:
      return {
        ...state,
        userBookingQuotes: [...action.payload],
      };
    case bookingQuotesTypes.ADD_BOOKING_QUOTE:
      return {
        ...state,
        bookingQuotes: addItem(state.bookingQuotes, action.payload),
      };
    case bookingQuotesTypes.UPDATE_BOOKING_QUOTE: {
      return {
        ...state,
        bookingQuotes: updateItem(state.bookingQuotes, action.payload),
      };
    }
    case bookingQuotesTypes.DELETE_BOOKING_QUOTE: {
      return {
        ...state,
        bookingQuotes: deleteItem(state.bookingQuotes, action.payload),
      };
    }
    case bookingQuotesTypes.SET_SELECTED_BOOKING_QUOTE:
      return {
        ...state,
        selectedBookingQuote: action.payload,
      };
    case bookingQuotesTypes.RESET_SELECTED_BOOKING_QUOTE:
      return {
        ...state,
        selectedBookingQuote: null,
      };
    case bookingQuotesTypes.SET_BOOKING_QUOTES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case bookingQuotesTypes.RESET_BOOKING_QUOTES_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default bookingQuotesReducer;
