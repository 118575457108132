import { useEffect } from "react";
import resetOrigin from "../shared/utils/resetOrigin";
import { segmentTrack } from "./segment";
import "../css/kustomer.css";

const getKustomer = () => {
  const appWindow: any = window;
  return appWindow.Kustomer || null;
};

const start = () => {
  const kustomer = getKustomer();
  const kustomerBrandId = process.env.REACT_APP_KUSTOMER_BRAND_ID;
  kustomer?.start({
    brandId: kustomerBrandId,
  });
};

const stop = () => {
  const kustomer = getKustomer();
  kustomer?.stop();
};

const open = () => {
  const kustomer = getKustomer();
  kustomer?.open();
};

const close = () => {
  const kustomer = getKustomer();
  kustomer?.close();
};

const describe = (attr: { attributes: any, customAttributes: any }, callback: any) => {
  const kustomer = getKustomer();
  kustomer?.describeCustomer(attr, callback ? () => callback() : "");
};

const createConversation = () => {
  const kustomer = getKustomer();
  kustomer?.createConversation();
};


const assistants = {
  provider: "6363f1aae2599028163ead77",
};
/* Provider Assistant - 6363f1aae2599028163ead77 */
const assistant = (assistantId: string) => {
  const kustomer = getKustomer();
  kustomer?.createConversation({
    assistantId: "6363f1aae2599028163ead77",
  }, (_: any, error: any) => {
    if (error) {
      segmentTrack("Kustomer Assistant Error", {
        id: assistantId,
      });
    }
  });
};

const article = (id: string) => {
  const kustomer = getKustomer();
  kustomer?.openKbArticle(id, (_: any, error: any) => {
    if (error) {
      segmentTrack("Kustomer Article Error", {
        article: id
      });
    }
  });
};

/* customer-faq for customer */
/* provider-faq for customer */
const category = (category: string) => {
  const kustomer = getKustomer();
  kustomer?.openKbCategory(category, (_: any, error: any) => {
    if (error) {
      segmentTrack("Kustomer Category Error", {
        category: category
      });
    }
  });
};

const action = (action: "category" | "article" | "assistant", value: string) => {
  switch (action) {
    case "category": {
      category(value);
      break;
    }
    case "article": {
      article(value);
      break;
    }
    case "assistant": {
      assistant(value);
      break;
    };
    default:
      break;
  }
};

const mobileClosedStyle = `
  display: block; border: 0px; width: 80px; height: 80px; 
  position: fixed; bottom: 20px; right: 20px; z-index: 2147483000; 
  transition: width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s; 
  min-height: 80px; max-height: 90%; max-width: 90%;`;

const mobileOpenStyle = `
  display: block; border: 0px; width: 90%; height: 90%; 
  position: fixed; bottom: 5%; right: 5%; z-index: 2147483000; 
  transition: width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s;
  max-height: 100%; max-width: 100%; box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

const getIframe = () => {
  const iframe = document.getElementById("kustomer-ui-sdk-iframe");
  return iframe;
};

const isMobile = () => {
  return window.innerWidth <= 560;
};

const applyMobileOpenStyles = () => {
  const iframe = getIframe();
  iframe?.setAttribute("style", mobileOpenStyle);

  document.body.setAttribute("style", "position: relative !important");
};

const applyMobileClosedStyles = () => {
  const iframe = getIframe();
  iframe?.setAttribute("style", mobileClosedStyle);

  document.body.setAttribute("style", "");
};

const addMobileListeners = () => {
  if (!isMobile()) return;
  const kustomer = getKustomer();
  kustomer?.addListener("onOpen", applyMobileOpenStyles);
  kustomer?.addListener("onClose", applyMobileClosedStyles);
};

const removeMobileListeners = () => {
  if (!isMobile()) return;
  const kustomer = getKustomer();
  kustomer?.removeListener("onOpen", applyMobileOpenStyles);
  kustomer?.removeListener("onClose", applyMobileClosedStyles);
};


// This is meant to trigger Kustomer after 8 seconds //
const useKustomer = (getParams: any, isLoggedIn: boolean) => {
  let timer: number;
  useEffect(() => {
    const chat = getParams("chat") === "1";
    if (chat) {
      open();
      resetOrigin();
    };

    timer = window.setTimeout(() => {
      addMobileListeners();
    }, 4000);

    if (isLoggedIn) return;

    // timer = window.setTimeout(() => {
    //   createConversation();
    // }, 8000);
    return () => {
      removeMobileListeners();
      window.clearTimeout(timer);
    };
  }, []);
};

export const Kustomer = {
  start,
  stop,
  open,
  close,
  describe,
  action,
  article,
  category,
  assistant,
  assistants,
  useKustomer,
  createConversation,
  kustomer: getKustomer(),
};
