import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export const defaultDayjsFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const adminTimeZone = "America/Los_Angeles";
export const csvDateFormat = "MM/DD/YYYY";
export const csvDateTimeFormat = "MM/DD/YYYY hh:mm A";

export const getTimestamp = () => dayjs().format(defaultDayjsFormat);

export const getUTCTimestamp = () => dayjs().utc().format(defaultDayjsFormat);

export const formattedDate = (date, timeZone = adminTimeZone, format = defaultDayjsFormat) => {
  if (!date) return "-";

  const isValidDate = dayjs(date).isValid();
  if (!isValidDate) return "-";

  return dayjs(date).tz(timeZone).format(format);
};

export const unixToDayjs = (dateInUnix, format = csvDateFormat) => (dateInUnix ? dayjs.unix(dateInUnix).format(format) : null);

export default dayjs;
