/* eslint-disable no-console */
import { toastr } from "react-redux-toastr";
import { useAxiosPost, useAxiosGet } from "../../axios";
import businessAccountTypes from "../constants/businessAccountTypes";
import { useDispatch } from "react-redux";
import useFirestoreListener from "@shared/hooks/useFirestoreListener";
import { registerWithEmail, adminCreateUser, adminConvertUser } from "./auth";

export const addBusinessAccountState = (businessAccount) => (dispatch) => {
  dispatch({ type: businessAccountTypes.ADD_BUSINESS_ACCOUNT, payload: businessAccount });
};

export const updateBusinessAccountState = (businessAccount) => (dispatch) => {
  dispatch({ type: businessAccountTypes.UPDATE_BUSINESS_ACCOUNT, payload: businessAccount });
};

export const setSelectedBusinessAccount = (businessAccount) => (dispatch) => {
  dispatch({
    type: businessAccountTypes.SET_SELECTED_BUSINESS_ACCOUNT,
    payload: businessAccount,
  });
};

export const resetSelectedBusinessAccount = () => (dispatch) => {
  dispatch({ type: businessAccountTypes.RESET_SELECTED_BUSINESS_ACCOUNT });
};

export const fetchBusinessAccounts = () => async (dispatch) => {
  try {
    dispatch({ type: businessAccountTypes.SET_BUSINESS_ACCOUNTS_LOADING });
    const { data } = await useAxiosGet("/business_client/all");
    dispatch({ type: businessAccountTypes.FETCH_BUSINESS_ACCOUNTS, payload: data.response });
  } catch (error) {
    console.error("Error fetching business accounts: ", error);
  } finally {
    dispatch({ type: businessAccountTypes.RESET_BUSINESS_ACCOUNTS_LOADING });
  }
};

export const fetchBusinessAccountData = async (businessId) => {
  try {
    const { data } = await useAxiosGet(`/business_client/${businessId}`);
    return data.response;
  } catch (error) {
    console.error("Error fetching booking account: ", error);
  }
};

export const fetchEBusinessAccounts = async (query, envType) => {
  try {
    const response = await useAxiosPost("/business_client/e/get-business-accounts", { query, envType });
    return response.data.payload;
  } catch (error) {
    console.error("Error in fetchEBusinessAccounts", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
    return;
  }
};

export const setBusinessAccounts = (businesses) => (dispatch) => {
  dispatch({ type: businessAccountTypes.FETCH_E_BUSINESS_ACCOUNTS, payload: businesses || [] });
};

export const fetchBusinessAccount = (businessId) => async (dispatch) => {
  try {
    dispatch({ type: businessAccountTypes.SET_BUSINESS_ACCOUNTS_LOADING });
    const { data } = await useAxiosGet(`/business_client/${businessId}`);
    dispatch({ type: businessAccountTypes.SET_SELECTED_BUSINESS_ACCOUNT, payload: data.response });
  } catch (error) {
    console.error("Error fetching booking account: ", error);
  } finally {
    dispatch({ type: businessAccountTypes.RESET_BUSINESS_ACCOUNTS_LOADING });
  }
};

export const createBusinessAccount = (businessAccount, callback) => async (dispatch) => {
  try {
    const { data } = await useAxiosPost("/business_client/create", businessAccount);
    if (data?.success) {
      toastr.success("Success", data.message);
      callback?.();
      return data;
    } else {
      toastr.error("Error", data?.error || "Something went wrong");
    }
  } catch (error) {
    console.error("Error creating business account: ", error);
    toastr.error("Error", "Error creating business account");
  }
};

export const createBusinessAccountFromClient = (businessAccountInfo, callback) => async (dispatch) => {
  try {
    const { businessAccountData, businessUserData, taskId = "" } = businessAccountInfo;

    if (!(await validateCompanyName(businessAccountData.name))) {
      throw new Error("This Company name already exists, please try another one");
    }

    const { data } = await useAxiosPost("/business_client/create", {
      ...businessAccountData,
      isAdminCreated: false,
      isRegisterComplete: false,
    });

    if (data?.success) {
      await dispatch(
        registerWithEmail({
          newUser: {
            ...businessUserData,
            businessId: data.payload.id,
          },
          taskId: taskId,
        })
      );
      toastr.success("Success", data.message);
      callback?.(true);
    } else {
      throw new Error(data?.error || "Error creating business account");
    }
  } catch (error) {
    console.error("Error creating business account: ", error);
    toastr.error("Error", error.message);
    callback?.(false);
  }
};

export const updateBusinessAccount = (id, businessAccount, callback) => async (dispatch) => {
  try {
    const { data } = await useAxiosPost(`/business_client/update/${id}`, businessAccount);
    if (data?.success) {
      toastr.success("Success", data.message);
      callback?.();
    } else {
      toastr.error("Error", data?.error || "Something went wrong");
    }
  } catch (error) {
    console.error("Error updating business account: ", error);
    toastr.error("Error", "Error updating business account");
  }
};

// hooks
export const useSubscribeToBusinessAccounts = () => {
  const dispatch = useDispatch();
  useFirestoreListener("business_accounts", {
    onAdd: (newDoc) => {
      dispatch(addBusinessAccountState(newDoc));
    },
    onUpdate: (updatedDoc) => {
      dispatch(updateBusinessAccountState(updatedDoc));
    },
  });
};

export const validateCompanyName = async (companyName) => {
  try {
    const { data } = await useAxiosPost("/business_client/check-unique-company", { companyName });
    return data.success || false;
  } catch (error) {
    console.error("Validate Company Name Error: ", error);
    return false;
  }
};

export const createBusinessUserAccountFromClient = (businessAccountInfo, isAccountDuplicate, isBusinessDuplicate, callback) => async (dispatch) => {
  const createAccount = async (user) => {
    if (isAccountDuplicate) {
      await dispatch(adminConvertUser(user, callback));
    } else {
      await dispatch(adminCreateUser(user, callback));
    }
  };

  try {
    const { businessAccountData, businessUserData } = businessAccountInfo;

    const { data } = isBusinessDuplicate
      ? await useAxiosGet(`/business_client/client_details/${businessAccountData.name}`)
      : await useAxiosPost("/business_client/create", businessAccountData);

    if (data?.success) {
      await createAccount({ ...businessUserData, businessId: data.payload.id });
    } else {
      throw new Error(data?.error || "Error creating business account");
    }
  } catch (error) {
    console.error("Error creating business account: ", error);
    toastr.error("Error", error.message);
  }
};

export const createBusinessFileUploads = async (form) => {
  try {
    const { data } = await useAxiosPost("/business_client/business-file-uploads", { form });
    return data.success || false;
  } catch (error) {
    console.error("Error in File Uploads: ", error);
    return false;
  }
};
