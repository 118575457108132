import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkFeatureFlag } from "../../store/actions/featureFlags";
import flagTypes from "../../store/constants/flagTypes";
import { genCustomUID } from "../utils/generateUID";

interface LDChecker {
  flag: string;
  dependencies: Array<any>;
  profile: any;
  callback: (value?: any) => void;
  run: boolean;
  privilege?: any;
  sessionPrivileges?: any;
  defaultValue?: string | boolean;
}

export const useLDChecker = ({
  flag,
  dependencies,
  profile,
  callback,
  run,
  privilege = null,
  sessionPrivileges = null,
  defaultValue = ""
}: LDChecker) => {
  const check = async () => {
    let isValid = true;
    if (privilege) {
      isValid = sessionPrivileges.includes(privilege);
    }
    if (!isValid) {
      callback(false);
      return;
    }
    const session = sessionStorage.getItem(`ld-flag-${flag}`);
    if (session) {
      callback(session === "true" ? true : false);
      return;
    }
    let uid = null;
    if (profile?.uid) {
      uid = profile.uid;
    } else {
      uid = sessionStorage.getItem("tw-session-id");
      if (!uid) {
        uid = genCustomUID();
        sessionStorage.setItem("tw-session-id", uid);
      }
    }
    const query = {
      key: uid,
      custom: {
        market: profile?.smsSettings?.address?.city ?? "",
        email: profile?.email ?? "",
        userType: profile?.userType ?? ""
      }
    };
    isValid = await checkFeatureFlag(flag, query, defaultValue ?? false);
    sessionStorage.setItem(`ld-flag-${flag}`, JSON.stringify(isValid));
    callback(isValid);
  };
  useEffect(() => {
    if (run) {
      check();
    }
  }, [...dependencies, run]);
};

const getFlagUserId = (profile: any) => {
  let uid = null;
  if (profile?.uid) {
    uid = profile.uid;
  } else {
    uid = sessionStorage.getItem("tw-session-id");
    if (!uid) {
      uid = genCustomUID();
      sessionStorage.setItem("tw-session-id", uid);
    }
  }
  return uid;
};

interface Flag {
  flag: string;
  dependencies: Array<any>;
  profile: any;
  run: boolean;
  privilege?: any;
  useSessionStorage?: boolean;
  defaultValue?: string | boolean;
  cb?: (value: any) => void;
}

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const useFlag = ({
  flag,
  dependencies = [],
  profile = null,
  run = false,
  defaultValue = false,
  useSessionStorage = true,
  cb = () => {},
}: Flag) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue);
  const check = async () => {
    const session = sessionStorage.getItem(`ld-flag-${flag}`);
    if (session && useSessionStorage) {
      const cleanValue = isJsonString(session) ? JSON.parse(session) : session;
      setValue(cleanValue);
      cb?.(cleanValue);
      return;
    }
    const uid = getFlagUserId(profile);
    const query = {
      key: uid,
      custom: {
        market: profile?.smsSettings?.address?.city ?? "",
        email: profile?.email ?? "",
        userType: profile?.userType ?? "",
        isBundleCustomer: profile?.isBundleCustomer ?? false
      }
    };
    const isValid = await checkFeatureFlag(flag, query, defaultValue ?? false);
    sessionStorage.setItem(`ld-flag-${flag}`, JSON.stringify(isValid));
    setValue(isValid);
    cb?.(isValid);
  };

  useEffect(() => {
    if (!run) cb?.(value);
    if (run) {
      check();
    }
  }, [...dependencies, run]);

  const dispatchValue = (val: any) => {
    dispatch({
      type: flagTypes.SET_FLAG,
      value: val,
      flag
    });
  };

  useEffect(() => {
    dispatchValue(value);
  }, [value]);

  return value;
};
