const couponTypes = {
  SET_COUPONS_LOADING: "SET_COUPONS_LOADING",
  RESET_COUPONS_LOADING: "RESET_COUPONS_LOADING",

  FETCH_COUPONS: "FETCH_COUPONS",
  FETCH_COUPONS_ERROR: "FETCH_COUPONS_ERROR",
  CREATE_COUPON: "CREATE_COUPON",
  CREATE_COUPON_ERROR: "CREATE_COUPON_ERROR",

  ADD_COUPON: "ADD_COUPON",
  UPDATE_COUPON: "UPDATE_COUPON",
  DELETE_COUPON: "DELETE_COUPON",

  UPDATE_COUPON_ERROR: "UPDATE_COUPON_ERROR",
  SET_ACTIVE_COUPON: "SET_ACTIVE_COUPON",
  SET_SELECTED_COUPON: "SET_SELECTED_COUPON",
  RESET_SELECTED_COUPON: "RESET_SELECTED_COUPON",
  SET_COUPON: "SET_COUPON",
};

export default couponTypes;
