const bookingQuotesTypes = {
  ADD_BOOKING_QUOTE: "ADD_BOOKING_QUOTE",
  DELETE_BOOKING_QUOTE: "DELETE_BOOKING_QUOTE",
  UPDATE_BOOKING_QUOTE: "UPDATE_BOOKING_QUOTE",

  FETCH_BOOKING_QUOTES: "FETCH_BOOKING_QUOTES",
  FETCH_CUST_BOOKING_QUOTES: "FETCH_CUST_BOOKING_QUOTES",

  SET_SELECTED_BOOKING_QUOTE: "SET_SELECTED_BOOKING_QUOTE",
  RESET_SELECTED_BOOKING_QUOTE: "RESET_SELECTED_BOOKING_QUOTE",
  SET_BOOKING_QUOTES_LOADING: "SET_BOOKING_QUOTES_LOADING",
  RESET_BOOKING_QUOTES_LOADING: "RESET_BOOKING_QUOTES_LOADING",
};

export const bookingBidTypes = {
  SET_LOADING: "SET_LOADING",
  ADD_BID: "ADD_BID",
  DELETE_BID: "DELETE_BID",
  UPDATE_BID: "UPDATE_BID",
  SELECT_BID: "SELECT_BID",
  ADD_WARRIOR_BID: "ADD_WARRIOR_BID",
  UPDATE_WARRIOR_BID: "UPDATE_WARRIOR_BID",
  DELETE_WARRIOR_BID: "DELETE_WARRIOR_BID",
  SELECT_WARRIOR_BID: "SELECT_WARRIOR_BID",
  SET_BIDS: "SET_BIDS",
  SET_SELECTED_BID: "SET_SELECTED_BID",
  SET_WARRIOR_BIDS: "SET_WARRIOR_BIDS",
};

export default bookingQuotesTypes;
