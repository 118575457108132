import paymentConstants from "@store/constants/paymentTypes";

const INITIAL_STATE = {
  paymentMethod: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case paymentConstants.FETCH_PAYMENT_INTENT:
      return {
        ...state,
        ...action.payload,
      };
    case paymentConstants.FETCH_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case paymentConstants.SET_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case paymentConstants.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case paymentConstants.FETCH_SETUP_INTENT:
      return {
        ...state,
        setupIntent: action.payload,
      };
    default:
      return state;
  }
};
