export default {
  SET_PRICING_LOADING: "SET_PRICING_LOADING",
  RESET_PRICING_LOADING: "RESET_PRICING_LOADING",
  FETCH_ITEM_PRICING: "FETCH_ITEM_PRICING",
  FETCH_ITEM_PRICING_ERROR: "FETCH_ITEM_PRICING_ERROR",

  ADD_ITEM_PRICING: "ADD_ITEM_PRICING",
  UPDATE_ITEM_PRICING: "UPDATE_ITEM_PRICING",
  DELETE_ITEM_PRICING: "DELETE_ITEM_PRICING",
};
