import tempDataConstants from "@store/constants/tempDataTypes";
import { dumpsterBasePrice } from "../../constants/dumpsters";
import { roles } from "../../constants/roles";

const INITIAL_STATE = {
  userType: roles.customer.value,
  user: null,
  lowestPrices: {},
  showSignOutModal: false,
  bookings: [],
  selectedIds: [],
  dumpsterMarketPricing: [],
  dumpsterBasePrices: dumpsterBasePrice,
  authLoaded: false,
};

const tempDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case tempDataConstants.SET_INITIAL_AUTH_LOAD:
      return {
        ...state,
        authLoaded: true,
      };
    case tempDataConstants.SET_TEMP_USER:
      return {
        ...state,
        userType: action.userType,
        user: action.user,
      };
    case tempDataConstants.RESET_LOADING:
      return {
        ...INITIAL_STATE,
      };
    case tempDataConstants.RESET: {
      return {
        userType: state.userType,
        ...INITIAL_STATE,
      };
    }
    case tempDataConstants.SET_LOWEST_PRICES: {
      return {
        ...state,
        lowestPrices: action.lowestPrices,
      };
    }
    case tempDataConstants.SET_SHOW_SIGN_OUT_MODAL: {
      return {
        ...state,
        showSignOutModal: true,
      };
    }
    case tempDataConstants.RESET_SHOW_SIGN_OUT_MODAL: {
      return {
        ...state,
        showSignOutModal: false,
      };
    }
    case tempDataConstants.SET_BOOKINGS_CONSOLIDATED_INVOICES: {
      return {
        ...state,
        bookings: action.payload,
      };
    }
    case tempDataConstants.SET_BOOKING_IDS_CONSOLIDATED_INVOICES: {
      return {
        ...state,
        selectedIds: action.payload,
      };
    }
    case tempDataConstants.RESET_BOOKINGS_CONSOLIDATED_INVOICES: {
      return {
        ...state,
        selectedIds: [],
      };
    }
    case tempDataConstants.SET_DUMPSTER_MARKET_PRICING: {
      return {
        ...state,
        dumpsterMarketPricing: action.payload,
      };
    }
    case tempDataConstants.RESET_DUMPSTER_MARKET_PRICING: {
      return {
        ...state,
        dumpsterMarketPricing: [],
      };
    }
    case tempDataConstants.SET_DUMPSTER_BASE_PRICING: {
      return {
        ...state,
        dumpsterBasePrices: {
          ...dumpsterBasePrice,
          ...action.payload,
        },
      };
    }
    case tempDataConstants.RESET_DUMPSTER_BASE_PRICING: {
      return {
        ...state,
        dumpsterBasePrices: dumpsterBasePrice,
      };
    }
    default:
      return state;
  }
};

export default tempDataReducer;
