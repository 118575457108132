const pointInPolygon = require("point-in-polygon");

const asin = Math.asin;
const cos = Math.cos;
const sin = Math.sin;
const sqrt = Math.sqrt;
const PI = Math.PI;

// equatorial mean radius of Earth (in miles)
const R = 3963.190592;

const squared = (x) => x * x;
const toRad = (x) => (x * PI) / 180.0;
const hav = (x) => squared(sin(x / 2));

export const haversineDistance = (a = {}, b = {}) => {
  const aLat = toRad(Array.isArray(a) ? a[1] : a.lat);
  const bLat = toRad(Array.isArray(b) ? b[1] : b.lat);
  const aLng = toRad(Array.isArray(a) ? a[0] : a.lng);
  const bLng = toRad(Array.isArray(b) ? b[0] : b.lng);

  const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng);
  const distance = 2 * R * asin(sqrt(ht));
  return distance || Infinity;
};

export const getDestinationDirection = (startCoordinates = {}, endCoordinates = {}) => {
  const radianAngle = Math.atan2(endCoordinates.lng - startCoordinates.lng, endCoordinates.lat - startCoordinates.lat);
  let degreeAngle = (radianAngle * 100) / Math.PI;

  if (degreeAngle < 0) {
    degreeAngle += 360;
  }
  return degreeAngle || 0;
};

export const sortByDistance = (location = {}, options = [], limit) => {
  const sortedList = options
    .filter((option) => {
      if (!option.lat || !option.lng) return false;
      if (!limit) {
        return true;
      } else {
        return haversineDistance(location, option) < limit;
      }
    })
    .sort((a, b) => {
      if (haversineDistance(location, { lat: a.lat, lng: a.lng }) > haversineDistance(location, { lat: b.lat, lng: b.lng })) {
        return 1;
      } else {
        return -1;
      }
    });
  return sortedList;
};

export const getNearestLocations = (location = {}, options = [], length = 1) => {
  let result = options.map((site) => {
    const distance = haversineDistance(location, site).toFixed(1);
    return { ...site, distance };
  });
  result = result.sort((a, b) => a.distance - b.distance).slice(0, length);
  return result;
};

// not working at the point and returning 0.
export const getSquareMiles = (coordinatesArray = []) => {
  const area = coordinatesArray
    .map((loc) => loc)
    .reduce((acc, coord = {}, index, arr) => {
      // console.log(acc, coord, index, arr);
      if (index === 0) return acc;
      return acc + toRad(coord.lat - arr[index - 1].lat) * (2 + sin(toRad(arr[index - 1].lng)) + sin(toRad(coord.lng)));
    }, 0);

  return Math.abs((area * 6378137.0 * 6378137.0) / 2.0);
};

export const isPointInPolygon = (polygon = [], coordinates = {}) => {
  return (
    pointInPolygon(
      [coordinates.lat, coordinates.lng],
      polygon.map((point = {}) => [point.lat, point.lng])
    ) || false
  );
};
export const isPointInPolygonArray = (polygons = [], coordinates = {}) => {
  return polygons?.some((polygon = []) =>
    pointInPolygon(
      [coordinates.lat, coordinates.lng],
      polygon.map((point = {}) => [point.lat, point.lng])
    )
  );
};
