import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";

import DayjsUtils from "@date-io/dayjs";

import theme from "@config/appTheme";

const WrappingProviders = ({ children }) => {
  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <MuiThemeProvider theme={theme}>
        <StylesProvider injectFirst>{children}</StylesProvider>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default WrappingProviders;
