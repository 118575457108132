import flagTypes from "../constants/flagTypes";

const INITIAL_STATE = {};

const flagsReducer = (state = INITIAL_STATE, action: { type: string, flag: string, value: any }) => {
    switch (action.type) {
        case flagTypes.SET_FLAG:
            return {
                ...state,
                [action.flag]: action.value,
                [`${action.flag}-checked`]: true,
            };
        default:
            return state;
    }
};

export default flagsReducer;
