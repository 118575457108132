import { getTasksAnalyticsTypes } from "../../constants/analyticsTypes";

const INITIAL_STATE = {
  totalTasks: 0,
  initialVisibility: 0,
  isMobileView: false,
  viewedTasks: [],
  timeOnPage: 0,
};

const getTasksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getTasksAnalyticsTypes.SET_GET_TASKS_ANALYTICS:
      return {
        ...state,
        ...action.payload,
      };
    case getTasksAnalyticsTypes.RESET_GET_TASKS_ANALYTICS:
      return INITIAL_STATE;
    case getTasksAnalyticsTypes.SET_TOTAL_TASKS:
      return {
        ...state,
        totalTasks: action.payload,
      };
    case getTasksAnalyticsTypes.SET_INITIAL_VISIBILITY:
      return {
        ...state,
        initialVisibility: action.payload,
      };
    case getTasksAnalyticsTypes.IS_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.payload,
      };
    case getTasksAnalyticsTypes.VIEWED_TASKS:
      return {
        ...state,
        viewedTasks: action.payload,
      };
    case getTasksAnalyticsTypes.TIME_ON_PAGE:
      return {
        ...state,
        timeOnPage: action.payload,
      };
    default:
      return state;
  }
};

export default getTasksReducer;
