import uiConstants from "@store/constants/uiTypes";
import { sameDayBookingPrice } from "../../constants/booking";

const INITIAL_STATE = {
  bookingTableTab: "active",
  adminPage: "all-tasks",
  adminTableState: {
    sortDirection: "desc",
    sortBy: "",
    page: {},
  },
  forceQueryTrigger: false,
  taskCompleted: false,
  modals: [],
  modalsOpen: false,
  modalsLoading: false,
  modalsInitialized: false,
  currentModal: null,
  sameDaySurge: {
    value: sameDayBookingPrice,
    fetchedValue: sameDayBookingPrice,
  },
};

const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case uiConstants.SET_BOOKINGS_TAB:
      return {
        ...state,
        bookingTableTab: action.payload,
      };
    case uiConstants.SET_ADMIN_PAGE:
      return {
        ...state,
        adminPage: action.payload,
      };
    case uiConstants.SET_ADMIN_TABLE_STATE:
      return {
        ...state,
        adminTableState: action.payload,
      };
    case uiConstants.SET_FORCE_QUERY_TRIGGER:
      return {
        ...state,
        forceQueryTrigger: action.payload,
      };
    case uiConstants.SET_TASK_COMPLETED:
      return {
        ...state,
        taskCompleted: action.payload,
      };
    case uiConstants.SET_MODALS_OPEN:
      return {
        ...state,
        modalsOpen: action.payload,
      };
    case uiConstants.SET_MODALS:
      return {
        ...state,
        modals: action.payload,
        modalsInitialized: true,
      };
    case uiConstants.SET_MODALS_LOADING:
      return {
        ...state,
        modalsLoading: action.payload,
      };
    case uiConstants.SET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: action.payload,
      };
    case uiConstants.SET_SURGE_VAL:
      return {
        ...state,
        sameDaySurge: {
          ...state.sameDaySurge,
          value: action.payload,
        },
      };
    case uiConstants.SET_FETCH_SURGE_VAL:
      return {
        ...state,
        sameDaySurge: {
          ...state.sameDaySurge,
          fetchedValue: action.payload,
        },
      };
    default:
      return state;
  }
};

export default uiReducer;
