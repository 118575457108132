import { useDispatch } from "react-redux";
import { sortDate } from "@methods/sort";
import useBatchFetch from "./useBatchFetch";
import { INDEXED_DB_NAME, INDEXED_DB_MAX_LIMIT } from "@constants/indexedDB";

const useIndexedDBWithBatchFetch = (dbStoreName, batchFetchProps) => {
  const db = window[INDEXED_DB_NAME];
  const dispatch = useDispatch();
  const { orderBy = "updatedAt" } = batchFetchProps.settings || {};

  const fetch = useBatchFetch(batchFetchProps, async (records) => {
    if (!records.length || records.length > INDEXED_DB_MAX_LIMIT) {
      return;
    }

    db(dbStoreName, async (store) => {
      await store.upsertAll(records);
      const count = await store.count();

      if (count > INDEXED_DB_MAX_LIMIT) {
        const dates = await store.getValues(orderBy); // keyDoc.key => [createdAt | updatedAt | date] must be a date field;
        const oldDocs = sortDate(dates, orderBy).slice(INDEXED_DB_MAX_LIMIT); // sort records latest-oldest and get old docs
        const docKeys = await Promise.all(oldDocs.map(async (date) => await store.searchKey(orderBy, date)));
        // delete old documents
        store.removeAll(docKeys);
      }

      console.log(" %c The local database is seeded.", "color:#459F65"); // eslint-disable-line
    });
  });

  const loadDocuments = async () => {
    // get saved documents
    db(
      dbStoreName,
      async (store) => {
        const docs = await store.getAll();
        docs.length && dispatch({ type: batchFetchProps.SET_DATA, payload: sortDate(docs, orderBy) });
        fetch(undefined, !docs.length);
      },
      [orderBy]
    );
  };

  return loadDocuments;
};

export default useIndexedDBWithBatchFetch;
