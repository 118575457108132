import { dumpsterCapacity } from "../constants/dumpsters";
import { AVERAGE_DROPOFF_DISTANCE_MILES, CO2_OFFSET_AMOUNT_PER_TON, ESTIMATED_DROPOFF_EMISSIONS_PER_MILE, emissionsByMaterial } from "../constants/emissions";
import { LBS_PER_TON } from "../constants/metric";

// lowercase everything, remove spaces replace with dashes
const getNormalisedMaterialMap = (material: string) => {
  const normalisedMaterial = material.toLowerCase().replace(/\s+/g, "-");
  return normalisedMaterial;
};

// for junk removal / cardboard removal
export const calculateTotalItemsEmissions = (items: any) => {
  let emissions = 0;
  items.forEach((picker_item: any) => {
    const { item, quantity } = picker_item;
    const { wasteStream, weight, material, isCustom } = item;

    const normalisedMaterialMap = isCustom ? getNormalisedMaterialMap(material) : getNormalisedMaterialMap(wasteStream);

    const itemWeight = Number(weight);
    const materialEmissions = emissionsByMaterial[normalisedMaterialMap] ?? emissionsByMaterial["other"];
    const buriedEmissions = materialEmissions.buried ?? 0;

    if (isCustom) {
      emissions += buriedEmissions * quantity * itemWeight;
    } else {
      emissions += buriedEmissions * quantity * itemWeight;
    }
  });

  return emissions;
};

// for dumpster rentals
export const calculateTotalDumpsterEmissions = (dumpsters: any) => {
  let emissions = 0;
  dumpsters.forEach((dumpsterItem: any) => {
    const { item: dumpster } = dumpsterItem;
    const { description: dumpsterDescription, items = [] } = dumpster;
    const normalisedYard = getNormalisedMaterialMap(dumpsterDescription); // 5-yard, 10-yard, 20-yard, 30-yard, 40-yard

    const dumpsterWeight = dumpsterCapacity[normalisedYard] ?? dumpsterCapacity["10-yard"]; // approximate weight the dumpster can hold (in lbs)

    items?.forEach((picker_item: any) => {
      const { description: itemDescription } = picker_item;
      const normalisedMaterialMap = getNormalisedMaterialMap(itemDescription);
      const materialEmissions = emissionsByMaterial[normalisedMaterialMap] ?? emissionsByMaterial["other"];
      const buriedEmissions = materialEmissions.buried ?? 0;

      emissions += buriedEmissions * dumpsterWeight;
    });
  });

  return emissions;
};

export const getPickupDropoffEmissions = () => ESTIMATED_DROPOFF_EMISSIONS_PER_MILE * AVERAGE_DROPOFF_DISTANCE_MILES * 2;

export const calculateEstimateDropoffEmissions = (itemEmissions = 0) => {
  const vehicleEmissions = getPickupDropoffEmissions();
  const totalEmissions = vehicleEmissions + itemEmissions;
  return +totalEmissions.toFixed(0); // in lbs
};

/* return carbon neutral cost in cents without using optin conditions */
export const getCarbonNeutralCost = ({ impacts }: any) => {
  const carbon_emitted_weight = impacts?.emissions_offset_lbs || 0; // captured value at any point
  const carbon_weight_tons = carbon_emitted_weight / LBS_PER_TON; // convert to tons
  const amount_per_ton = impacts?.emission_price_per_ton ?? CO2_OFFSET_AMOUNT_PER_TON;
  const carbonNeutralCost = carbon_weight_tons * amount_per_ton;

  return +carbonNeutralCost.toFixed(0);
};

/* return current carbon neutral cost in cents using optin conditions and any paid amount */
export const calculateCarbonNeutralCost = ({ impacts }: any) => {
  const carbon_neutral_optin = Boolean(impacts?.carbon_neutral_optin);

  // if user did not opt in, return 0
  if (!carbon_neutral_optin) return 0;

  const carbonNeutralCost = getCarbonNeutralCost({ impacts });

  return +carbonNeutralCost.toFixed(0);
};

/* return paid carbon neutral cost in cents */
export const getPaidCarbonNeutralCost = ({ impacts }: any) => {
  const paid_amount = impacts?.emissions_offset_fee_paid || 0; // captured value at any point
  return paid_amount;
};
