export default function debounce(callback, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const later = () => {
      timeout = null;
      if (!immediate) callback.apply(this, arguments);
    };
    // invoke callback immediately
    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) callback.apply(this, arguments);
  };
}

export const cancellableDebounce = (func, wait, immediate) => {
  let timeout, args, context, timestamp, result;

  const later = function () {
    const last = Date.now() - timestamp;

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function () {
    context = this;
    args = arguments;
    timestamp = Date.now();
    const callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    // Return timeout so debounced function can be cancelled
    result = result || {};
    result.timeout = timeout;

    return result;
  };
};
