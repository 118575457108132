const vipTypes = {
  FETCH_VIP_LIST: "FETCH_VIP_LIST",
  FETCH_VIP_LIST_ERROR: "FETCH_VIP_LIST_ERROR",

  CREATE_VIP: "CREATE_VIP",
  CREATE_VIP_ERROR: "CREATE_VIP_ERROR",

  SET_ACTIVE_VIP: "SET_ACTIVE_VIP",
  SET_ACTIVE_VIP_ERROR: "SET_ACTIVE_VIP_ERROR",

  UPDATE_VIP: "UPDATE_VIP",
  UPDATE_VIP_ERROR: "UPDATE_VIP_ERROR",

  SET_VIP_LOADING: "SET_VIP_LOADING",
  RESET_VIP_LOADING: "RESET_VIP_LOADING",
};

export default vipTypes;
