import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import debounce from "@methods/debounce";
import { useAxiosGet } from "@axios";

const batchFetchLimits = {
  base: 20,
  max: 500,
};

function useBatchFetch({ settings, URL, SET_DATA = null, SET_LOADER = null, RESET_LOADER = null, FETCHING = null }, updateCallback = () => {}) {
  const { repeat = false, timer = 600000, limit = Infinity, orderBy = "updatedAt" } = settings || {};

  const [records, setRecords] = useState([]);
  const [time, setTime] = useState(dayjs());

  const AscOrder = "asc";
  const DescOrder = "desc";

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);
  const firstDocRef = useRef();
  const countRef = useRef(0);

  const runRecursiveFetch = useCallback(
    debounce(async (fetch) => {
      const now = dayjs();
      await fetch(firstDocRef.current, AscOrder); // fetch new records after first saved record
      setTime(now);
    }, timer),
    []
  );

  const debouncedUpdateCallback = useCallback(debounce(updateCallback, 1000), []);

  /**
   * repeat recursive fetch after 10 mints by default
   * only update records after first saved record
   */

  useEffect(() => {
    if (repeat) runRecursiveFetch(recursiveFetch);
  }, [time]);

  /** update records on redux state once batch records fetched */

  useEffect(() => {
    if (records.length) {
      dispatch({ type: SET_DATA, payload: records });
      debouncedUpdateCallback(records);
    }
  }, [records]);

  /** recursive fetch to fetch records in batches */

  const recursiveFetch = async (lastDoc, order) => {
    dispatch({ type: FETCHING, payload: true });

    try {
      const response = await useAxiosGet(`${URL}?batch=true&&last_doc=${lastDoc}&&order=${order}&&orderBy=${orderBy}`, {
        Authorization: profile?.uid,
      });
      const { data } = response;

      switch (order) {
        case DescOrder:
          setRecords((rec) => rec.concat(data.response));
          break;
        case AscOrder:
          if (!dayjs(data.firstDoc).isSame(firstDocRef.current)) {
            const newRecords = data.response.slice(1, data.response.length).reverse();
            setRecords((rec) => newRecords.concat(rec));
          }
          break;
        default:
          break;
      }

      countRef.current += data.response.length;

      if (data.firstDoc) {
        firstDocRef.current = data.firstDoc;
      }

      if (countRef.current <= limit) {
        data.lastDoc && recursiveFetch(data.lastDoc, order);
      }

      if (
        countRef.current >= limit ||
        // if responseArray<records> results with uneven record counts. meaning the last fetch
        !Object.values(batchFetchLimits).includes(data.response.length)
      ) {
        dispatch({ type: FETCHING, payload: false });
      }

      return;
    } catch (error) {
      console.log("Error on batch fetch:", error); // eslint-disable-line
    } finally {
      if (!lastDoc) dispatch({ type: RESET_LOADER });
    }
  };

  /** initial fetch on mount */
  const fetchRecords = (lastDoc, loading) => {
    loading && dispatch({ type: SET_LOADER });
    recursiveFetch(lastDoc, DescOrder);
  };

  return fetchRecords;
}

export default useBatchFetch;
