import React, { useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import classes from "@css/components/website/Page404.module.css";
import Icon404 from "../../assets/landing_page/404.svg";
import history from "../../history";
import { segmentTrack } from "../../helpers/segment";

function Page404() {
  useEffect(() => {
    window.onerror = (_e1, _e2, _e3, _e4, { message, stack }: any) => {
      segmentTrack("Global App Error", { message, stack });
    };
  }, []);

  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <img src={Icon404} alt="404 Icon" />
        <Typography className={classes.header}>Whoops, This page does not exist!</Typography>
        <Typography className={classes.description}>Unfortunately, we can&apos;t seem to find the page you&apos;re looking for.</Typography>
        <Button onClick={() => history.push("/")} className={classes.primaryButton}>
          Go Back home
        </Button>
      </div>
    </section>
  );
}

export default Page404;
