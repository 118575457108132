import quoteConstants from "@store/constants/quoteTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  quotes: [],
  activeQuote: null,
  invites: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case quoteConstants.FETCH_QUOTES:
      return {
        ...state,
        quotes: [...action.payload],
      };
    case quoteConstants.CREATE_QUOTE:
      return {
        ...state,
        newQuote: { ...action.payload },
      };
    case quoteConstants.CREATE_QUOTE_ERROR:
      return {
        ...state,
        newQuote: { ...action.payload },
      };
    case quoteConstants.SET_ACTIVE_QUOTE:
      return {
        ...state,
        activeQuote: { ...action.payload },
      };
    case quoteConstants.ADD_QUOTE:
      return {
        ...state,
        quotes: addItem(state.quotes, action.payload),
      };
    case quoteConstants.UPDATE_QUOTE:
      return {
        ...state,
        quotes: updateItem(state.quotes, action.payload),
      };
    case quoteConstants.DELETE_QUOTE:
      return {
        ...state,
        quotes: deleteItem(state.quotes, action.payload),
      };
    case quoteConstants.FETCH_INVITES:
      return {
        ...state,
        invites: [...action.payload],
      };
    case quoteConstants.SET_QUOTES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case quoteConstants.RESET_QUOTES_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
