const dumpSitesTypes = {
  ADD_DUMPSITE: "ADD_DUMPSITE",
  DELETE_DUMPSITE: "DELETE_DUMPSITE",
  UPDATE_DUMPSITE: "UPDATE_DUMPSITE",

  FETCH_DUMPSITES: "FETCH_DUMPSITES",

  SET_SELECTED_DUMPSITE: "SET_SELECTED_DUMPSITE",
  RESET_SELECTED_DUMPSITE: "RESET_SELECTED_DUMPSITE",
  SET_DUMPSITES_LOADING: "SET_DUMPSITES_LOADING",
  RESET_DUMPSITES_LOADING: "RESET_DUMPSITES_LOADING",
};

export default dumpSitesTypes;
