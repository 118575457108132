import TagManager from "react-gtm-module";
import { isProduction } from "@constants/environments";
import { isDevelopment } from "./constants/environments";
import { useEffect } from "react";

const gSegmentId = process.env.REACT_APP_GOOGLE_SEGMENT_ID;
const useProofId = process.env.REACT_APP_USE_PROOF_ID;

const headTag = document.head;
const bodyTag = document.body;

const createElement = (element, attributes = {}) => {
  const el = document.createElement(element);
  for (const attr in attributes) {
    el[attr] = attributes[attr];
  }
  return el;
};

const loadFacebook = () => {
  const metascript = createElement("meta");
  metascript.name = "facebook-domain-verification";
  metascript.content = "asveoqxrfjc8kzkgklpbflfuoajjx6";
  headTag.append(metascript);
};

const loadScript = (attributes, name) => {
  const script = createElement("script", attributes);
  headTag.append(script);
};

const loadScriptToBody = (attributes) => {
  const script = createElement("script", attributes);
  bodyTag.append(script);
};
const loadNoScriptToBody = (attributes) => {
  const script = createElement("noscript", attributes);
  bodyTag.append(script);
};

const loadUseProof = () => {
  loadScript({
    src: `https://cdn.useproof.com/proof.js?acc=${useProofId}`,
    defer: true,
  });
};

const loadTrustPilot = () => {
  loadScript({
    src: "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
    async: true,
  });
};

const loadSegment = () => {
  if (!gSegmentId) {
    return;
  }
  loadScript({
    text: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${gSegmentId}";analytics.SNIPPET_VERSION="4.13.2";
    analytics.load("${gSegmentId}");
    }}();`,
  });
};

const loadGTag = () => {
  loadScript({
    text: `window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search});`,
  });
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  TagManager.initialize({ gtmId });
};

const loadZoomInfo = () => {
  loadScript({
    src: "https://ws.zoominfo.com/pixel/621d1640695c29001c4fdef6",
    async: true,
  });
  loadNoScriptToBody({
    text: `<img src="https://ws.zoominfo.com/pixel/621d1640695c29001c4fdef6"
    width="1" height="1" style="display: none;"
    alt="websights" />`,
  });
};

export const loadKustomerChat = () => {
  const kustomerKey = process.env.REACT_APP_KUSTOMER_API_KEY;
  const kustomerBrandId = process.env.REACT_APP_KUSTOMER_BRAND_ID;
  loadScriptToBody({
    text: `window.addEventListener('kustomerLoaded', function() {
      Kustomer.start({
        brandId: '${kustomerBrandId}'
      });
    });
    var script = document.createElement('script');
    script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
    script.setAttribute('data-kustomer-api-key', '${kustomerKey}');
    window.document.body.appendChild(script);`,
  });
};

/* Call this function to activate Hotjar on specific pages */
export const useHotJar = () => {
  useEffect(() => {
    if (!isProduction) return;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerText = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3671085,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    // Append the script to the head of the document
    document.head.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export const loadAllScripts = () => {
  if (isProduction) {
    loadGTag();
    loadSegment();
    loadKustomerChat();
    loadTrustPilot();
    loadZoomInfo();
    loadFacebook();
    // loadUseProof();
  }

  if (isDevelopment) {
    loadKustomerChat();
  }
};
