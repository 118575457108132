/* eslint-disable */
import notificationConstants from "@store/constants/notificationsTypes";
import _cloneDeep from "lodash/cloneDeep";

const INITIAL_STATE = {
  alerts: [],
  triggers: [],
  channels: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case notificationConstants.SET_NOTIFICATION_TRIGGERS:
      return {
        ...state,
        alerts: action.payload?.alerts ?? [],
        triggers: action.payload?.triggers ?? [],
        channels: action.payload?.channels ?? [],
      };
    case notificationConstants.RESET_NOTIFICATION_TRIGGERS:
      return INITIAL_STATE;
    case notificationConstants.UPSERT_TRIGGERS: {
      const trigger = action.payload;
      const objIndex = state.triggers.findIndex((t) => t.id == trigger.id);
      const newState = _cloneDeep(state);
      if (objIndex > -1) {
        newState.triggers[objIndex] = {
          ...state.triggers[objIndex],
          ...trigger,
        };
      } else {
        newState.triggers.push(trigger);
      }
      return newState;
    }
    case notificationConstants.DELETE_TRIGGER: {
      const triggerId = action.payload;
      let newState = _cloneDeep(state);
      newState = {
        ...newState,
        triggers: state.triggers.filter((trigger) => trigger.id !== triggerId),
        alerts: state.alerts.filter((alert) => alert.trigger !== triggerId),
      };
      return newState;
    }
    case notificationConstants.UPSERT_ALERTS: {
      const objIndex = state.triggers.findIndex((t) => t.id === action.triggerId);
      const newState = _cloneDeep(state);
      const ids = action.alerts.map((a) => a.id);
      newState.alerts = [...state.alerts.filter((a) => !ids.includes(a.id)), ...action.alerts];
      newState.triggers[objIndex] = {
        ...state.triggers[objIndex],
        ...state.channels.reduce((acc, channel) => {
          return {
            ...acc,
            [channel.name]: newState.alerts.find((alert) => alert.channel === channel.name && alert.trigger === action.triggerId),
          };
        }, {}),
      };
      return newState;
    }
    default:
      return state;
  }
};
