import { Help } from "@material-ui/icons";
import React from "react";
import { ReactComponent as BuyerIcon } from "../assets/icons/buyer.svg";
import { ReactComponent as DonationIcon } from "../assets/icons/donation.svg";
import { ReactComponent as LandfillIcon } from "../assets/icons/landfill.svg";
import { ReactComponent as RecycleIcon } from "../assets/icons/recycle.svg";

export const dumpSiteTypes = {
  ["direct"]: {
    value: "direct",
    label: "Direct Buyer",
    applyTreatments: [],
    customerLabel: "Direct Buyer (paper mill, store, etc)",
    icon: <BuyerIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["donation"]: {
    value: "donation",
    label: "Donation Center",
    applyTreatments: ["donated"],
    customerLabel: "Donation Center",
    icon: <DonationIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["recycle"]: {
    value: "recycle",
    label: "Recycle Center",
    applyTreatments: ["recycled"],
    customerLabel: "Recycle Center",
    icon: <RecycleIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["landfill"]: {
    value: "landfill",
    label: "Landfill",
    applyTreatments: ["buried"],
    customerLabel: "Landfill",
    icon: <LandfillIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["other"]: {
    value: "other",
    label: "Other",
    applyTreatments: [],
    customerLabel: "Other",
    icon: <Help style={{ color: "#ccc" }} />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
};
export const dumpSiteTreatments = {
  ["buried"]: {
    value: "buried",
    label: "Bury",
    customerLabel: "Buried",
    process: "buried",
    color: "#523012",
  },
  ["donated"]: {
    value: "donated",
    label: "Donate",
    customerLabel: "Donated",
    process: "donated",
    color: "#3d6ba8",
  },
  ["recycled"]: {
    value: "recycled",
    label: "Recycle",
    customerLabel: "Recycled",
    process: "recycled",
    color: "#2b8222",
  },
  ["sold"]: {
    value: "sold",
    label: "Sell",
    customerLabel: "Sold",
    process: "sold",
    color: "#4B0082",
  },
  ["other"]: {
    value: "other",
    label: "Other",
    customerLabel: "Not sure",
    process: "dropped off",
    color: "#374c78",
  },
};

export const wasteStreams = {
  ["Bulky furniture"]: {
    value: "Bulky furniture",
    label: "Bulky furniture",
    color: "#522825",
  },
  ["Cardboard"]: {
    value: "Cardboard",
    label: "Cardboard",
    color: "#917d69",
  },
  ["Compost"]: {
    value: "Compost",
    label: "Compost",
    color: "#4c6322",
  },
  ["Concrete and Drywall"]: {
    value: "Concrete and Drywall",
    label: "Concrete and Drywall",
    color: "#545452",
  },
  ["E-Waste"]: {
    value: "E-Waste",
    label: "E-Waste",
    color: "#02a30c",
  },
  ["Glass"]: {
    value: "Glass",
    label: "Glass",
    color: "#84a6ad",
  },
  ["Metal"]: {
    value: "Metal",
    label: "Metal",
    color: "#495457",
  },
  ["Plastics"]: {
    value: "Plastics",
    label: "Plastics",
    color: "#3d516e",
  },
  ["Solid waste"]: {
    value: "Solid waste",
    label: "Solid waste",
    color: "#381e19",
  },
  ["Textile"]: {
    value: "Textile",
    label: "Textile",
    color: "#251082",
  },
  ["Wood"]: {
    value: "Wood",
    label: "Wood",
    color: "#99432b",
  },
  ["Other"]: {
    value: "Other",
    label: "Other",
    color: "#374c78",
  },
};
