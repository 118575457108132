const tempDataTypes = {
  SET_INITIAL_AUTH_LOAD: "SET_INITIAL_AUTH_LOAD",
  SET_TEMP_USER: "SET_TEMP_USER",
  RESET_TEMP_USER: "RESET_TEMP_USER",
  RESET: "RESET",
  SET_LOWEST_PRICES: "SET_LOWEST_PRICES",
  SET_SHOW_SIGN_OUT_MODAL: "SET_SHOW_SIGN_OUT_MODAL",
  RESET_SHOW_SIGN_OUT_MODAL: "RESET_SHOW_SIGN_OUT_MODAL",

  SET_DUMPSTER_MARKET_PRICING: "SET_DUMPSTER_MARKET_PRICING",
  RESET_DUMPSTER_MARKET_PRICING: "RESET_DUMPSTER_MARKET_PRICING",

  SET_DUMPSTER_BASE_PRICING: "SET_DUMPSTER_BASE_PRICING",
  RESET_DUMPSTER_BASE_PRICING: "RESET_DUMPSTER_BASE_PRICING",

  SET_BOOKING_IDS_CONSOLIDATED_INVOICES: "SET_BOOKING_IDS_CONSOLIDATED_INVOICES",
  SET_BOOKINGS_CONSOLIDATED_INVOICES: "SET_BOOKINGS_CONSOLIDATED_INVOICES",
  RESET_BOOKINGS_CONSOLIDATED_INVOICES: "RESET_BOOKINGS_CONSOLIDATED_INVOICES",
};

export default tempDataTypes;
