import { WordPressActions } from "../constants/wordpress";

const INITIAL_STATE = {
  isLoading: false,
  posts: [],
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case WordPressActions.LOADING_POSTS:
      return {
        ...state,
        isLoading: true,
      };
    case WordPressActions.SET_POSTS:
      return {
        ...state,
        isLoading: false,
        posts: action.payload,
      };
    case WordPressActions.RESET_POSTS:
      return {
        ...state,
        isLoading: false,
        posts: [],
      };
    default:
      return state;
  }
};
