const uiConstants = {
  SET_BOOKINGS_TAB: "SET_BOOKINGS_TAB",
  SET_ADMIN_PAGE: "SET_ADMIN_PAGE",
  SET_ADMIN_TABLE_STATE: "SET_ADMIN_TABLE_STATE",
  SET_FORCE_QUERY_TRIGGER: "SET_FORCE_QUERY_TRIGGER",
  SET_TASK_COMPLETED: "SET_TASK_COMPLETED",
  SET_MODALS: "SET_MODALS",
  SET_MODALS_LOADING: "SET_MODALS_LOADING",
  SET_MODALS_OPEN: "SET_MODALS_OPEN",
  SET_CURRENT_MODAL: "SET_CURRENT_MODAL",
  SET_SURGE_VAL: "SET_SURGE_VAL", // same day surge value
  SET_FETCH_SURGE_VAL: "SET_FETCH_SURGE_VAL",
};

export default uiConstants;
