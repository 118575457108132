export enum SubscriptionFrequency {
  Monthly = "monthly",
  Bundle = "bundle"
}

export enum BundleStatus {
  Active = "active",
  Expired = "expired",
  Cancelled = "cancelled"
}
