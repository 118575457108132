const DaysOfWeek = [
  { label: "Sun", value: 0 },
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
];

const DatesOfMonth = Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({ label: day.toString(), value: day }));
DatesOfMonth.push({ label: "Last Day", value: -1 });

const getDateChunks = (arr: any[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const MonthDateChunks = getDateChunks(DatesOfMonth, 7);

const WeeksOfMonth = [
  { label: "First", value: 1 },
  { label: "Second", value: 2 },
  { label: "Third", value: 3 },
  { label: "Fourth", value: 4 },
  { label: "Last", value: -1 },
];

const MonthsOfQuarter = [
  { label: "First", value: 1 },
  { label: "Second", value: 2 },
  { label: "Third", value: 3 },
];

const frequencyRepeatModes = {
  // default
  on_dates: {
    label: "On Exact Dates",
    value: "on_dates",
  },
  // specify with weekdays
  on_days: {
    label: "On Certain Days",
    value: "on_days",
  },
};

const defaultFrequencyRepeatMode = frequencyRepeatModes.on_dates.value;

export {
  DaysOfWeek,
  DatesOfMonth,
  MonthDateChunks,
  WeeksOfMonth,
  MonthsOfQuarter,
  frequencyRepeatModes,
  defaultFrequencyRepeatMode
};
