import addressConstants from "@store/constants/addressTypes";
import tempDataConstants from "@store/constants/tempDataTypes";

import { setActivePricing, resetActivePricing } from "@store/actions/locations";
import isEmpty from "@methods/isEmpty";
import { useAxiosPost } from "../../axios";
import { dumpsterBasePrice } from "../../constants/dumpsters";
import { segmentTrack } from "../../helpers/segment";
import { setLocationOpts } from "./locations";

export const setAddress = (address) => (dispatch) => {
  dispatch({ type: addressConstants.SET_ADDRESS, address });
};

export const resetAddress = () => (dispatch) => {
  dispatch({ type: addressConstants.RESET_ADDRESS });
};

export const setDumpsterMarketPricing = (dumpsterPrices) => (dispatch) => {
  dispatch({ type: tempDataConstants.SET_DUMPSTER_MARKET_PRICING, payload: dumpsterPrices });
};

export const setDumpsterBasePricing = (basePrices) => (dispatch) => {
  const payload = basePrices ?? dumpsterBasePrice;
  dispatch({ type: tempDataConstants.SET_DUMPSTER_BASE_PRICING, payload });
};

export const assignLocationValues = (data) => async (dispatch) => {
  if (data.valid) {
    const dumpsterPrices = data?.dumpsterPrices;
    const dumpsterBasePrices = data?.dumpsterBasePrices;
    dispatch(setDumpsterMarketPricing(dumpsterPrices));
    dispatch(setDumpsterBasePricing(dumpsterBasePrices));
    dispatch(setAddress(data?.address));
    dispatch(setActivePricing(data?.pricing));
  } else {
    // eslint-disable-next-line
    console.error("Error validateServiceLocation: ", error);
    dispatch(resetAddress());
    dispatch(resetActivePricing());
    dispatch({ type: tempDataConstants.RESET_DUMPSTER_MARKET_PRICING });
    dispatch({ type: tempDataConstants.RESET_DUMPSTER_BASE_PRICING });
  }
};

export const validateServiceLocation =
  (address, task, allowUnserviceable = false) =>
  async (dispatch, getState) => {
    if (isEmpty(address)) {
      dispatch(resetActivePricing());
      return false;
    }
    try {
      const results = await useAxiosPost("/locations/is-valid-location", { coordinates: address.coordinates, task, allowUnserviceable });
      if (results?.data?.valid) {
        const dumpsterPrices = results?.data?.dumpsterPrices;
        const dumpsterBasePrices = results?.data?.dumpsterBasePrices;
        dispatch(setDumpsterMarketPricing(dumpsterPrices));
        dispatch(setDumpsterBasePricing(dumpsterBasePrices));
        dispatch(setAddress(address));
        dispatch(setActivePricing(results.data.pricing));
        dispatch(setLocationOpts(results.data.opts));
        if (results.data.opts?.isNonMarket) {
          segmentTrack("Location Non Market Applied", {
            location: address?.location ?? "",
            from: "Booking Widget",
            ...(results.data.opts ?? {}),
          });
        } else {
          segmentTrack("Location Market Applied", {
            location: address?.location ?? "",
            from: "Booking Widget",
            ...(results.data.opts ?? {}),
          });
        }
        return true;
      } else {
        throw "This is not a valid location";
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error validateServiceLocation: ", error);
      dispatch(resetAddress());
      dispatch(resetActivePricing());
      dispatch({ type: tempDataConstants.RESET_DUMPSTER_MARKET_PRICING });
      dispatch({ type: tempDataConstants.RESET_DUMPSTER_BASE_PRICING });
      return false;
    }
  };
