export const envs = {
  local: {
    label: "Local",
    value: "local",
    envLevel: 0,
  },
  development: {
    label: "Development",
    value: "development",
    envLevel: 10,
  },
  staging: {
    label: "Staging",
    value: "staging",
    envLevel: 20,
  },
  production: {
    label: "Production",
    value: "production",
    envLevel: 30,
  },
};

export const LOCAL_DEV_ENVS = [envs.local.value, envs.development.value];
