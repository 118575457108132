import addressConstants from "@store/constants/addressTypes";

const INITIAL_STATE = {
  location: "",
  coordinates: {},
  placeId: "",
  zipCode: "",
  street: "",
  city: "",
  state: "",
  timeZone: "",
  county: "",
};

const addressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case addressConstants.SET_ADDRESS:
      return action.address;
    case addressConstants.RESET_ADDRESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default addressReducer;
