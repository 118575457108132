import businessAccountTypes from "../constants/businessAccountTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  businessAccounts: [],
  eBusinessAccounts: {
    page: {},
    data: [],
  },
  selectedBusinessAccount: null,
  isLoading: false,
};

const businessAccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case businessAccountTypes.FETCH_BUSINESS_ACCOUNTS:
      return {
        ...state,
        businessAccounts: [...action.payload],
      };
    case businessAccountTypes.FETCH_E_BUSINESS_ACCOUNTS:
      return {
        ...state,
        eBusinessAccounts: action.payload,
      };
    case businessAccountTypes.ADD_BUSINESS_ACCOUNT:
      return {
        ...state,
        businessAccounts: addItem(state.businessAccounts, action.payload),
      };
    case businessAccountTypes.UPDATE_BUSINESS_ACCOUNT: {
      return {
        ...state,
        businessAccounts: updateItem(state.businessAccounts, action.payload),
      };
    }
    case businessAccountTypes.DELETE_BUSINESS_ACCOUNT: {
      return {
        ...state,
        businessAccounts: deleteItem(state.businessAccounts, action.payload),
      };
    }
    case businessAccountTypes.SET_SELECTED_BUSINESS_ACCOUNT:
      return {
        ...state,
        selectedBusinessAccount: action.payload,
      };
    case businessAccountTypes.RESET_SELECTED_BUSINESS_ACCOUNT:
      return {
        ...state,
        selectedBusinessAccount: null,
      };
    case businessAccountTypes.SET_BUSINESS_ACCOUNTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case businessAccountTypes.RESET_BUSINESS_ACCOUNTS_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default businessAccountReducer;
