export enum AdminActions {
  LOADING_ADMIN_STUFF = "LOADING_ADMIN_STUFF",

  // Admin Users
  SET_ADMIN_USERS = "SET_ADMIN_USERS",
  UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER",
  RESET_ADMIN_USERS = "RESET_ADMIN_USERS",

  // Admin Teams
  SET_ADMIN_TEAMS = "SET_ADMIN_TEAMS",
  RESET_ADMIN_TEAMS = "RESET_ADMIN_TEAMS",

  ADD_NEW_TEAM = "ADD_NEW_TEAM",
  UPDATE_TEAM = "UPDATE_TEAM",
  DELETE_TEAM = "DELETE_TEAM",

  // Admin Notification Settings
  SET_ADMIN_NOTIFICATION_SETTINGS = "SET_ADMIN_NOTIFICATION_SETTINGS",
  RESET_ADMIN_NOTIFICATION_SETTINGS = "RESET_ADMIN_NOTIFICATION_SETTINGS",

  ADD_NEW_NOTIFICATION_SETTING = "ADD_NEW_NOTIFICATION_SETTING",
  UPDATE_NOTIFICATION_SETTING = "UPDATE_NOTIFICATION_SETTING",
  DELETE_NOTIFICATION_SETTING = "DELETE_NOTIFICATION_SETTING",
}
