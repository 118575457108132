import { bookingBidTypes } from "../constants/bookingQuoteTypes";
import { addItem, updateItem, deleteItem } from "../utils";

const INITIAL_STATE = {
  bids: [],
  selectedBid: null,
  warriorBids: [],
  warriorBidsLoaded: false,
  selectedWarriorBid: null,
  isLoading: false,
};

const bookingBidsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bookingBidTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case bookingBidTypes.SELECT_BID:
      return {
        ...state,
        selectedBid: action.payload,
      };
    case bookingBidTypes.SELECT_WARRIOR_BID:
      return {
        ...state,
        selectedWarriorBid: action.payload,
      };
    case bookingBidTypes.SET_BIDS:
      return {
        ...state,
        bids: [...action.payload],
        isLoading: false,
      };
    case bookingBidTypes.SET_SELECTED_BID:
      return {
        ...state,
        selectedBid: action.payload,
      };
    case bookingBidTypes.ADD_BID:
      return {
        ...state,
        bids: addItem(state.bids, action.payload),
        selectedBid: action.payload,
      };
    case bookingBidTypes.UPDATE_BID: {
      return {
        ...state,
        bids: updateItem(state.bids, action.payload),
        selectedBid: action.payload,
      };
    }
    case bookingBidTypes.DELETE_BID: {
      return {
        ...state,
        bids: deleteItem(state.bids, action.payload),
      };
    }
    case bookingBidTypes.SET_WARRIOR_BIDS:
      return {
        ...state,
        warriorBids: [...action.payload],
        warriorBidsLoaded: true,
      };
    case bookingBidTypes.ADD_WARRIOR_BID:
      return {
        ...state,
        warriorBids: addItem(state.warriorBids, action.payload),
        selectedWarriorBid: action.payload,
      };
    case bookingBidTypes.UPDATE_WARRIOR_BID: {
      return {
        ...state,
        warriorBids: updateItem(state.warriorBids, action.payload),
        selectedWarriorBid: action.payload,
      };
    }
    case bookingBidTypes.DELETE_WARRIOR_BID:
      return {
        ...state,
        warriorBids: deleteItem(state.warriorBids, action.payload),
      };
    default:
      return state;
  }
};

export default bookingBidsReducer;
