const businessAccountTypes = {
  ADD_BUSINESS_ACCOUNT: "ADD_BUSINESS_ACCOUNT",
  DELETE_BUSINESS_ACCOUNT: "DELETE_BUSINESS_ACCOUNT",
  UPDATE_BUSINESS_ACCOUNT: "UPDATE_BUSINESS_ACCOUNT",

  FETCH_BUSINESS_ACCOUNTS: "FETCH_BUSINESS_ACCOUNTS",
  FETCH_E_BUSINESS_ACCOUNTS: "FETCH_E_BUSINESS_ACCOUNTS",

  SET_SELECTED_BUSINESS_ACCOUNT: "SET_SELECTED_BUSINESS_ACCOUNT",
  RESET_SELECTED_BUSINESS_ACCOUNT: "RESET_SELECTED_BUSINESS_ACCOUNT",
  SET_BUSINESS_ACCOUNTS_LOADING: "SET_BUSINESS_ACCOUNTS_LOADING",
  RESET_BUSINESS_ACCOUNTS_LOADING: "RESET_BUSINESS_ACCOUNTS_LOADING",
};

export default businessAccountTypes;
