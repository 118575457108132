export const mergeItem = (state, payload) => {
  const payloadArray = Array.isArray(payload) ? payload : Array.isArray(payload.data) ? payload.data : [];
  return state?.length
    ? payloadArray.reduce((acc, newItem) => {
        if (!newItem) return acc;
        const index = acc.findIndex((item) => item.id === newItem.id);
        if (index !== -1) acc[index] = newItem;
        else acc.push(newItem);
        return acc;
      }, state)
    : payloadArray;
};

export const addItem = (state, payload) => [payload, ...state];

export const updateItem = (state, payload) =>
  state.map((item) => ((item.id || item.uid) === (payload.id || payload.uid) ? { ...item, ...payload } : item));

export const deleteItem = (state, payload) => state.filter((item) => (item.id || item.uid) !== (payload.id || payload.uid));

export const getUserFieldName = (userType) =>
  ({
    customer: "customers",
    provider: "providers",
  }[userType] || "users");
